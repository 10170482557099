import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/pages-style/Login.css';
import arivuFR from '../assets/logo/ArivuFR.png';
import arivuEN from '../assets/logo/ArivuEN.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../authentication/AuthProvider';
import { usePendingUser } from '../authentication/PendingUserContext';
import config from '../config';
import { translations } from '../app_components/Translation';

function Login({ selectedLanguage }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const auth = useAuth();
    const navigate = useNavigate();
    const { setPendingUser } = usePendingUser();
    const selectedText = translations[selectedLanguage]?.login || {};

    // Dynamically set the logo based on the selected language
    const logo = selectedLanguage === 'EN' ? arivuEN : arivuFR;

    const handleSubmit = async (event) => {
        event.preventDefault();

        const logins = {
            user_id: username,
            password: password,
        };

        try {
            const response = await fetch(`${config.apiUrl}/authenticate_user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(logins),
            });

            const data = await response.json();

            if (response.ok) {
                setIsInvalid(false);
                setPendingUser(username);
                navigate('/terms_and_conditions');
            } else {
                setIsInvalid(true);
                console.error('Login failed:', data.message);
            }
        } catch (error) {
            console.error('Network error:', error);
            setIsInvalid(true);
        }
    };

    const toggleVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleRegisterRedirect = () => {
        navigate('/register');
    };

    return (
        <div className="login-page">
            <div className="logo-side">
                <div className="logo-container">
                    <img src={logo} alt="logo" />
                </div>
            </div>
            <div className="login-container">
                <h2 className="login-header">{selectedText.welcome || 'Welcome'}</h2>
                <div className="divider"></div>
                {isInvalid && (
                    <div className="error-message">
                        {selectedText.invalidCredentials || 'Invalid username or password'}
                    </div>
                )}
                <form onSubmit={handleSubmit} className="form">
                    <label htmlFor="username-input">{selectedText.username || 'Username'}</label>
                    <input
                        type="text"
                        id="username-input"
                        placeholder={selectedText.enterUsername || 'Enter your username'}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <label htmlFor="password-input">{selectedText.password || 'Password'}</label>
                    <div className="password-input-group">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            id="password-input"
                            placeholder={selectedText.enterPassword || 'Enter your password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FontAwesomeIcon
                            icon={passwordVisible ? faEyeSlash : faEye}
                            onClick={toggleVisibility}
                            className="toggle-password-visibility-button"
                        />
                    </div>

                    <button type="submit">{selectedText.login || 'Login'}</button>

                    {/* <div className="register-redirect">
                        {selectedText.notRegistered || 'Not registered yet?'}{' '}
                        <span className="register-link" onClick={handleRegisterRedirect}>
                            {selectedText.createAccount || 'Create an account'}
                        </span>
                    </div> */}
                </form>
            </div>

        </div>
    );
}

export default Login;
