function formatDate(date) {
  const d = new Date(date);
  const day = String(d.getUTCDate()).padStart(2, "0");
  const month = String(d.getUTCMonth() + 1).padStart(2, "0");
  const year = d.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

const formatMonthYear = (dateString) => {
  if (!dateString) return "N/A";

  const [year, month] = dateString.split("-");
  const date = new Date(Date.UTC(year, month - 1));
  const options = { year: "numeric", month: "short" };

  return date.toLocaleDateString("en-US", options);
};

export { formatDate, formatMonthYear };
