/*import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// Function to generate PDF selectively based on selectedSections
export const generatePDF = async (tableDataHeaders, tableData, priorityTable, checkedRows, averages1, isReefer, selectedSections) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "a4",
  });

  const pdfWidth = doc.internal.pageSize.getWidth();
  const pdfHeight = doc.internal.pageSize.getHeight();
  let yOffset = 20; // Initial Y-offset with padding at the top
  let xOffset = 10; // Adjust this value to move the content right

  const tableColumn = tableDataHeaders;
  const tableRows = [];

  tableData.forEach((item, rowIndex) => {
    const rowData = tableDataHeaders.map((header) => item[header]);
    const isChecked = checkedRows[rowIndex] || false;
    tableRows.push({ data: rowData, isChecked });
  });

  const footer = ["Average"].concat(averages1.slice(1, -1)).concat(["N/A"]);
  tableRows.push({ data: footer });

  // Helper function to add any div with an ID to the PDF
  const addElementToPDF = (elementId) => {
    const element = document.getElementById(elementId);
    if (!element) {
      console.error(`Element with ID "${elementId}" not found.`);
      return Promise.resolve(); // Skip if the element does not exist
    }
    return html2canvas(element)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = imgWidth / imgHeight;
        const height = (pdfWidth - xOffset * 2) / ratio;

        if (yOffset + height > pdfHeight) {
          doc.addPage();
          yOffset = 0;
        }

        doc.addImage(imgData, "PNG", xOffset + 5, yOffset, pdfWidth - xOffset * 2 - 5, height);
        yOffset += height + 10; // Space after the added content
      })
      .catch((error) => {
        console.error(`Error capturing element with ID "${elementId}":`, error);
      });
  };

  // Add Fleet Summary content
  const addFleetSummaryContent = () => {
    return addElementToPDF("fleetSummary");
  };

  // Add Table Content
  const addTableContent = () => {
    doc.autoTable({
      head: [tableColumn],
      body: tableRows.map((row) => row.data),
      startY: yOffset + 0,
      styles: { fontSize: 5, cellPadding: 3 },
      headStyles: { fillColor: "#f2f2f2", textColor: "black" },
      columnStyles: {
        0: { cellWidth: "auto" },
      },
      margin: { horizontal: doc.internal.pageSize.getWidth() * 0.025 },
      tableWidth: "auto",
      didParseCell: (data) => {
        if (data.row.index < tableRows.length - 1 && data.section === "body") {
          const isChecked = tableRows[data.row.index].isChecked;
          if (isChecked) {
            data.cell.styles.fillColor = [227, 227, 227];
            data.cell.styles.textColor = [0, 0, 255];
          }
        } else if (data.row.index === tableRows.length - 1) {
          data.cell.styles.fillColor = [146, 160, 173];
          data.cell.styles.textColor = [255, 255, 255];
        }
      },
      didDrawPage: (data) => {
        yOffset = data.cursor.y;
        yOffset = yOffset + 10;
      },
    });
  };

  // Add Priority Table Content
  const addPriorityTableContent = () => {
    const tableColumn = priorityTable[0];
    const tableRows = priorityTable.slice(1);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: yOffset + 0,
      styles: {
        fontSize: 5,
        cellPadding: 3,
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: "#f2f2f2", // Change header color if needed
        textColor: "black",
        fontSize: 5,
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fillColor: [246, 246, 246],
        textColor: [0, 0, 0],
      },
      columnStyles: {
        0: { cellWidth: "auto" }, // Adjust the column width as per the data table
      },
      margin: { horizontal: doc.internal.pageSize.getWidth() * 0.025 }, // Same margin as tableData
      tableWidth: "auto", // Ensure the table adjusts to content width
      didParseCell: (data) => {
        const cellValue = data.cell.raw;
        if (data.section === "body") {
          if (cellValue === "High") {
            data.cell.styles.textColor = [255, 0, 0]; // Red
          } else if (cellValue === "Medium") {
            data.cell.styles.textColor = [255, 165, 0]; // Orange
          } else if (cellValue === "Medium-Low") {
            data.cell.styles.textColor = [0, 255, 255]; // Cyan
            data.cell.text = ["Medium"]; // Change 'Medium-Low' to 'Medium'
          } else if (cellValue === "Low") {
            data.cell.styles.textColor = [0, 128, 0]; // Green
          } else {
            data.cell.styles.textColor = [0, 0, 0];
          }

          if (cellValue === "Yes") {
            data.cell.text = `Yes`; // Use Unicode for tick mark
          }
        }
      },
      didDrawPage: (data) => {
        yOffset = data.cursor.y;
        yOffset += 10; // Space after the table
      },
    });
  };

  // Add Reefer Analysis Content
  const addReeferContent = () => {
    return addElementToPDF("reefer-fuel-dist-container");
  };

  // Add General Metrics Section
  const addGeneralMetrics = () => {
    return addElementToPDF("general-metrics");
  };

  // Add Utilization Priority Section
  const addUtilizationPriority = () => {
    return addElementToPDF("utilization-priority");
  };

  // Add Image at the top
  const addImageToTop = () => {
    const img = new Image();
    img.src = "/vrtta.png";
    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;

      const height = pdfHeight * 0.05;
      const ratio = imgWidth / imgHeight;
      const width = height * ratio;
      const xPos = (pdfWidth - width) / 2;

      doc.addImage(img, "PNG", xPos, yOffset, width, height);
      yOffset += height + 10;

      if (selectedSections.showReefers) {
        addReeferContent().then(() => {
          if (selectedSections.generalMetrics) addGeneralMetrics();
          if (selectedSections.fleetAssetsTable) addTableContent();
          if (selectedSections.fleetSummary) addFleetSummaryContent();
          if (selectedSections.utilizationAnalysis) addUtilizationPriority();
          doc.save("fleet_report.pdf");
        });
      } else {
        if (selectedSections.generalMetrics) addGeneralMetrics();
        if (selectedSections.fleetAssetsTable) addTableContent();
        if (selectedSections.fleetSummary) addFleetSummaryContent();
        if (selectedSections.utilizationAnalysis) addUtilizationPriority();
        doc.save("fleet_report.pdf");
      }
    };
  };

  addImageToTop();
};
*/

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { formatDate } from "./formatDate";

export const generatePDF = async (selectedSections) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "a4",
  });

  const pdfWidth = doc.internal.pageSize.getWidth();
  const pdfHeight = doc.internal.pageSize.getHeight();
  let yOffset = 20;
  const xOffset = 10;

  // Function to add an image to the top of the PDF
  const addImageToTop = () => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = "/vrtta.png";
      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const height = pdfHeight * 0.05;
        const ratio = imgWidth / imgHeight;
        const width = height * ratio;
        const xPos = (pdfWidth - width) / 2;

        doc.addImage(img, "PNG", xPos, yOffset, width, height);
        yOffset += height + 10;
        resolve();
      };
    });
  };

  // Helper function to add content by element ID
  const addContentById = async (id) => {
    const element = document.getElementById(id);
    if (!element) {
      console.error(`Element with ID "${id}" not found.`);
      return;
    }

    // Temporarily hide buttons or interactive elements
    const buttons = element.querySelectorAll("button, .btn");
    buttons.forEach((button) => (button.style.display = "none"));

    const canvas = await html2canvas(element, { useCORS: true });
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    const ratio = imgWidth / imgHeight;
    const height = (pdfWidth - xOffset * 2) / ratio;

    if (yOffset + height > pdfHeight) {
      doc.addPage();
      yOffset = 20;
    }

    doc.addImage(imgData, "PNG", xOffset, yOffset, pdfWidth - xOffset * 2, height);
    yOffset += height + 10; // Space after the content

    // Restore the display of buttons
    buttons.forEach((button) => (button.style.display = ""));
  };

  // Add image at the top
  await addImageToTop();

  // Capture and add sections conditionally based on selectedSections state
  if (selectedSections.generalMetrics) {
    await addContentById("general-metrics");
  }

  if (selectedSections.fleetSummary) {
    await addContentById("fleet-summary");
  }

  if (selectedSections.fleetAssetsTable) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("II. Fleet Assets Table", xOffset + 10, yOffset);
    yOffset += 20;

    await addContentById("fleet-table");
  }

  if (selectedSections.utilizationAnalysis) {
    await addContentById("graph-container1");

    doc.setFont("helvetica", "bold");
    doc.setFontSize(8);
    doc.text("Priority Table", xOffset + 10, yOffset);
    yOffset += 20;
    await addContentById("priority-table");

    await addContentById("graph-container2");
  }

  // Save the PDF after all content is added
  doc.save(`fleet_report-${formatDate(new Date())}.pdf`);
};
