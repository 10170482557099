const roundMax = (val) => {
  val = Math.ceil(val / 10) * 10;
  return val;
};

const roundMin = (val) => {
  val = Math.floor(val / 10) * 10;
  if (val < 0) {
    return 0;
  } else {
    return val;
  }
};

export { roundMax, roundMin };
