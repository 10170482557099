import React, { useRef, useState, useEffect } from "react";
import styles from "./UtilizationComponents.module.css";
import { Scatter } from "react-chartjs-2";
import dragDataPlugin from "chartjs-plugin-dragdata";

import { Chart as ChartJS, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { FaDownload } from "react-icons/fa6";

ChartJS.register(...registerables, annotationPlugin, ChartDataLabels);

function UtilizationAnalysis({ chartDataArray, fuelConsumptionGallons, chartRefs }) {
  const [chartData, setChartData] = useState(chartDataArray);
  const initialYValuesRef = useRef({});
  const [fuelReductions, setFuelReductions] = useState({});
  const [idlingReductions, setIdlingReductions] = useState({});
  const [totalFuelReductions, setTotalFuelReductions] = useState(0);
  const [totalIdlingReductions, setTotalIdlingReductions] = useState(0);
  const [showDataLabels, setShowDataLabels] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const pdfContentRef = useRef(null);

  const downloadChart = (index) => {
    const chart = chartRefs.current[index];
    const originalCanvas = chart.canvas;

    const tempCanvas = document.createElement("canvas");
    const ctx = tempCanvas.getContext("2d");

    tempCanvas.width = originalCanvas.width;
    tempCanvas.height = originalCanvas.height;

    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

    ctx.drawImage(originalCanvas, 0, 0);

    const link = document.createElement("a");
    link.download = `chart_${index + 1}.png`;
    link.href = tempCanvas.toDataURL("image/png", 1);
    link.click();
  };

  useEffect(() => {
    const initialYValues = {};
    chartDataArray.forEach((dataset, datasetIndex) => {
      initialYValues[datasetIndex] = dataset.datasets.datasets[0].data.map((point) => point.y);
    });
    initialYValuesRef.current = initialYValues;

    setChartData(chartDataArray);
  }, [chartDataArray]);

  const updateTotalReductions = () => {
    const totalFuel = Object.values(fuelReductions).reduce((total, val) => total + val, 0);
    setTotalFuelReductions(totalFuel.toFixed(0));

    const totalIdling = Object.values(idlingReductions).reduce((total, val) => total + val, 0);
    setTotalIdlingReductions(totalIdling.toFixed(0));
  };

  useEffect(() => {
    updateTotalReductions();
  }, [fuelReductions, idlingReductions]);

  const createStripedPattern = (color1, color2, stripeWidth = 2.3) => {
    const canvas = document.createElement("canvas");
    canvas.width = stripeWidth * 2;
    canvas.height = stripeWidth * 2;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = color1;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.strokeStyle = color2;
    ctx.lineWidth = stripeWidth;
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(canvas.width, canvas.height);
    ctx.stroke();

    return ctx.createPattern(canvas, "repeat");
  };

  const handleFuelDragEnd = (e, datasetIndex, index, newPosition) => {
    const updatedDataPoint = chartData[datasetIndex].datasets.datasets[0].data[index];
    const newY = newPosition.y;
    const xValue = updatedDataPoint.x;

    const initialY = initialYValuesRef.current[datasetIndex][index];
    const pointId = `${datasetIndex}-${index}`;

    // Calculate Potential Fuel Reductions
    const reduction = (initialY - newY) * (xValue / 100);
    setFuelReductions((prev) => ({
      ...prev,
      [pointId]: reduction,
    }));

    if (newY < initialY) {
      chartData[datasetIndex].datasets.datasets[0].backgroundColor[index] = createStripedPattern("white", "green");
    } else {
      chartData[datasetIndex].datasets.datasets[0].backgroundColor[index] = createStripedPattern("yellow", "red");
    }

    setChartData([...chartData]);
  };

  const handleIdlingDragEnd = (e, datasetIndex, index, newPosition) => {
    const newY = newPosition.y;

    const initialY = initialYValuesRef.current[datasetIndex + 1][index];
    const pointId = `${datasetIndex}-${index}`;

    // Check for fuel consumption value
    const fuelConsumptionData = fuelConsumptionGallons[index];
    const fuelConsumptionValue = fuelConsumptionData ? parseFloat(fuelConsumptionData.FuelConsumptionGallons) : undefined;

    // Validate values before calculation
    if (fuelConsumptionValue != null && initialY != null) {
      const idlingReduction = (fuelConsumptionValue * (initialY - newY)) / 100;
      setIdlingReductions((prev) => ({
        ...prev,
        [pointId]: idlingReduction,
      }));
    }

    if (newY < initialY) {
      chartData[datasetIndex + 1].datasets.datasets[0].backgroundColor[index] = createStripedPattern("white", "green");
    } else {
      chartData[datasetIndex + 1].datasets.datasets[0].backgroundColor[index] = createStripedPattern("yellow", "red");
    }

    setChartData([...chartData]);
  };
  const handleDrag = (datasetIndex, index, newPosition) => {
    handleFuelDragEnd(null, datasetIndex, index, newPosition);
  };

  const handleIdlingDrag = (datasetIndex, index, newPosition) => {
    handleIdlingDragEnd(null, datasetIndex, index, newPosition);
  };

  const handleCheckboxChange = (event) => {
    setShowDataLabels(event.target.checked);
  };

  const handleReset = () => {
    setFuelReductions({});
    setTotalFuelReductions(0);
    setIdlingReductions({});
    setTotalIdlingReductions(0);
  };

  return (
    <div className="process-env-container">
      <div className="process-table">
        <header className={styles["table-header"]}>
          <div className={styles.sectionTitle} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
            Utilization Analysis
          </div>
          <div className={styles.rightSection}>
            <div className={` ${styles["show_data_label_names"]}`}>
              {isAccordionOpen && (
                <div className={` ${styles["show_data_label_names"]}`} style={{ marginTop: "-10px" }} onClick={(e) => e.stopPropagation()}>
                  <input type="checkbox" id="data_label_check_box" onChange={(event) => handleCheckboxChange(event)} />
                  <label htmlFor="data_label_check_box">Show Asset Name</label>
                  <input className={styles.resetButton} type="button" value="Reset" onClick={handleReset} />
                </div>
              )}
            </div>
          </div>
        </header>
        <div className={`${styles.content1} ${styles.content} ${isAccordionOpen ? styles.open : ""}`}>
          {chartData.slice(0, 1).map((data, index) => (
            <div id={`pft-content${index}`} className={styles["graph-pair-container"]} key={index}>
              {/* First chart of the pair */}
              <div ref={pdfContentRef} className={`${styles["chart-options"]}`}>
                <div className={styles["graphs_block"]}>
                  <button type="button" style={{ cursor: "pointer" }} onClick={() => downloadChart(index)} className={`${styles["download-button"]}`}>
                    <FaDownload size="20px" />
                  </button>
                  <Scatter
                    id="fuel-utilization"
                    ref={(el) => (chartRefs.current[index] = el)}
                    data={chartDataArray[index].datasets}
                    options={{
                      ...chartData[index].options,
                      plugins: {
                        ...chartData[index].options.plugins,
                        datalabels: {
                          ...chartData[index + 1].options.plugins.datalabels,
                          display: showDataLabels,
                        },
                        dragData: {
                          onDrag: (e, datasetIndex, index, newPosition) => {
                            handleDrag(datasetIndex, index, newPosition);
                          },
                          onDragEnd: (e, datasetIndex, index, newPosition) => {
                            handleFuelDragEnd(e, datasetIndex, index, newPosition);
                          },
                        },
                      },
                    }}
                    className={`${styles["scatter-chart"]}`}
                  />
                </div>
              </div>

              {/* Second chart of the pair, if it exists */}
              {index + 1 < chartData.length && (
                <div className={` ${styles["chart-options"]}`}>
                  <div className={styles["graphs_block"]}>
                    <button type="button" style={{ cursor: "pointer" }} onClick={() => downloadChart(index)} className={`${styles["download-button"]}`}>
                      <FaDownload size="20px" />
                    </button>
                    <Scatter
                      id="idling-utilization"
                      ref={(el) => (chartRefs.current[index + 1] = el)}
                      data={chartData[index + 1].datasets}
                      options={{
                        ...chartData[index + 1].options,
                        plugins: {
                          ...chartData[index + 1].options.plugins,
                          datalabels: {
                            ...chartData[index + 1].options.plugins.datalabels,
                            display: showDataLabels,
                          },
                          dragData: {
                            onDrag: (e, datasetIndex, index, newPosition) => {
                              handleIdlingDrag(datasetIndex, index, newPosition);
                            },
                            onDragEnd: (e, datasetIndex, index, newPosition) => {
                              handleIdlingDragEnd(e, datasetIndex, index, newPosition);
                            },
                          },
                        },
                      }}
                      className={`${styles["scatter-chart"]} ${styles["modern-chart"]}`}
                    />
                  </div>
                </div>
              )}

              {/* Legend Section */}
              <div className={styles["chart-legend-box"]}>
                <div className={`${styles["point-container"]} `}>
                  <div className={styles.point} style={{ backgroundColor: "rgba(93, 173, 226, 1)" }}></div>
                  <div className={styles.text}>Over utilized</div>
                </div>
                <div className={`${styles["point-container"]} `}>
                  <div className={styles.point} style={{ backgroundColor: "rgba(236, 112, 99, 1)" }}></div>
                  <div className={styles.text}>High</div>
                </div>
                <div className={`${styles["point-container"]} `}>
                  <div className={styles.point} style={{ backgroundColor: "rgba(247, 220, 111, 1)" }}></div>
                  <div className={styles.text}>Medium</div>
                </div>
                <div className={`${styles["point-container"]} `}>
                  <div className={styles.point} style={{ backgroundColor: "rgba(88, 214, 141, 1)" }}></div>
                  <div className={styles.text}>Under utilized</div>
                </div>
              </div>
            </div>
          ))}
          <div className={styles.potentialReductionContainer}>
            <div className={styles["potential-reductions"]}>
              <div className={`${styles["point-container"]}`}>
                <div className={styles.point} style={{ backgroundColor: "rgb(0, 0, 0)" }}></div>
                <div className={styles.potentialReductionTitle}>Potential Fuel Reductions:</div>
              </div>

              <div className={`${styles["potential-reductions-value"]} ${styles[`potential-reductions-value-${totalFuelReductions < 0 ? "negative" : "positive"}`]}`}>{Number(totalFuelReductions).toFixed(0)} L</div>
              <div className={`${styles["potential-reductions-value"]} ${styles[`potential-reductions-value-${totalFuelReductions < 0 ? "negative" : "positive"}`]}`}>
                {(Number(totalFuelReductions) * 2.7).toFixed(0)} kg CO<sub>2</sub>e
              </div>
            </div>
            <div className={styles["potential-reductions"]}>
              <div className={`${styles["point-container"]} d-flex flex-row`}>
                <div className={styles.point} style={{ backgroundColor: "rgb(0, 0, 0)" }}></div>
                <div className={styles.potentialReductionTitle}>Potential Idling Reductions:</div>
              </div>

              <div className={`${styles["potential-reductions-value"]} ${styles[`potential-reductions-value-${totalIdlingReductions < 0 ? "negative" : "positive"}`]}`}>{Number(totalIdlingReductions).toFixed(0)} L</div>
              <div className={`${styles["potential-reductions-value"]} ${styles[`potential-reductions-value-${totalIdlingReductions < 0 ? "negative" : "positive"}`]}`}>
                {(Number(totalIdlingReductions) * 2.7).toFixed(0)} kg CO<sub>2</sub>e
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default UtilizationAnalysis;
