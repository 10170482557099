import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import Select from 'react-select';
import Header from "../app_components/Header";
import PhaseCard from "../comparison-components/PhaseCard";
import "../style/pages-style/Comparison.css";
import { Bar } from "react-chartjs-2";
import { useTable } from "react-table";
import config from '../config';
import { useAuth } from "../authentication/AuthProvider";
import { translations } from "../app_components/Translation";
import { Icon } from '@iconify/react'; // Import Iconify for icons
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, ImageRun, TextRun, Table, TableRow, TableCell, Footer } from 'docx';
import { FiDownload } from 'react-icons/fi';


function Comparison({ selectedLanguage }) {
    const [comparisonType, setComparisonType] = useState("");
    const [comparisonBasedOn, setComparisonBasedOn] = useState("");
    const [selectedValues, setSelectedValues] = useState([]);
    const [comparisonBasedOnOptions, setComparisonBasedOnOptions] = useState([]);
    const [footprintSummaryData, setFootprintSummaryData] = useState([]);
    const [selectedComparisonData, setSelectedComparisonData] = useState({});
    const [percentageDifferences, setPercentageDifferences] = useState({});
    const { user } = useAuth();
    const userId = user ? user.username : null;
    const barChartRef = useRef(null);
    const footprintChartRef = useRef(null); // New ref for footprint graph

    const selectedText = translations[selectedLanguage]?.comparison || {};

    const comparisonTypeOptions = [
        { value: 'Supplier', label: selectedText.supplier || 'Supplier' },
        { value: 'Utilizer', label: selectedText.utilizer || 'Utilizer' }
    ];

    const phaseKeys = {
        [selectedText.productPhase || 'Product Phase']: 'productPhase',
        [selectedText.constructionPhase || 'Construction Phase']: 'constructionPhase',
        [selectedText.usePhase || 'Use Phase']: 'usePhase',
        [selectedText.endOfLifePhase || 'End-of-life Phase']: 'endOfLifePhase',
        [selectedText.benefitsPhase || 'Benefits Phase']: 'benefitsPhase'
    };


    const fetchData = useCallback(() => {
        if (!userId || !comparisonType) return;
    
        console.log("Fetching data with", { comparisonType, userId });
    
        fetch(`${config.apiUrl}/get_product_or_design_name?comparison_type=${comparisonType}&user_id=${userId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                console.log("Fetched data:", data);
    
                if (data.error) {
                    throw new Error(data.error);
                }
    
                if (data.results && data.results.length > 0) {
                    const options = data.results
                        .map(item => ({
                            value: item.id,
                            label: item.name
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label
                    
                    setComparisonBasedOnOptions(options);
                    console.log("Options set:", options);
                } else {
                    setComparisonBasedOnOptions([]);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [comparisonType, userId]);
    

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleApply = () => {
        console.log('Applying selections:', {
            comparisonType,
            comparisonBasedOn,
            selectedValues
        });
    
        const allSelectedValues = [...selectedValues];
        if (!allSelectedValues.includes(comparisonBasedOn)) {
            allSelectedValues.push(comparisonBasedOn);
        }
    
        const queryParams = new URLSearchParams({
            comparison_type: comparisonType,
            selected_values: allSelectedValues.join(',')
        });
        console.log('Query Params:', queryParams.toString());
    
        fetch(`${config.apiUrl}/get_gwp_per_lca_phase?${queryParams.toString()}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                console.log('GWP per LCA phase data:', data);
    
                const formattedData = Object.entries(data).map(([rowId, phases]) => {
                    console.log('Row ID:', rowId);
                    console.log('Phases:', phases);
                    const productOption = comparisonBasedOnOptions.find(option => option.value === rowId);
                    const productName = productOption ? productOption.label : rowId;
    
                    return {
                        product: productName,
                        productPhase: parseFloat(phases['Product Phase'] || 0).toFixed(2),
                        constructionPhase: parseFloat(phases['Construction Phase'] || 0).toFixed(2),
                        usePhase: parseFloat(phases['Use Phase'] || 0).toFixed(2),
                        endOfLifePhase: parseFloat(phases['End-of-life Phase'] || 0).toFixed(2),
                        benefitsPhase: parseFloat(phases['Benefits Phase'] || 0).toFixed(2),
                        total: parseFloat(
                            (phases['Product Phase'] || 0) +
                            (phases['Construction Phase'] || 0) +
                            (phases['Use Phase'] || 0) +
                            (phases['End-of-life Phase'] || 0) +
                            (phases['Benefits Phase'] || 0)
                        ).toFixed(2)
                    };
                });
    
                // Sort footprint summary data alphabetically by the product name
                const sortedData = formattedData.sort((a, b) => a.product.localeCompare(b.product));
    
                console.log('Sorted Formatted Data:', sortedData);
                setFootprintSummaryData(sortedData);
    
                const selectedData = sortedData.find(item => item.product === comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label);
                console.log('Selected Comparison Data:', selectedData);
                setSelectedComparisonData(selectedData || {});
    
                const percentageDifferences = {};
                sortedData.forEach(item => {
                    if (item.product !== selectedData.product) {
                        percentageDifferences[item.product] = percentageDifference(parseFloat(item.total), parseFloat(selectedData.total));
                    }
                });
    
                console.log('Percentage Differences:', percentageDifferences);
                setPercentageDifferences(percentageDifferences);
            })
            .catch(error => {
                console.error('Error fetching GWP data:', error);
            });
    };
    

    const productLabel = useMemo(() => {
        if (comparisonType === 'Supplier') {
            return selectedText.product || 'Product';
        } else if (comparisonType === 'Utilizer') {
            return selectedText.designInstallation || 'Design Installation';
        }
        return selectedText.products || 'Products'; // Default value
    }, [comparisonType, selectedText]);


    const footprintSummaryColumns = useMemo(() => [
        {
            Header: productLabel,
            accessor: 'product'
        },
        {
            Header: selectedText.productPhase,
            accessor: 'productPhase',
            Cell: ({ value }) => `${value}`,
            style: { backgroundColor: '#79AC78' }
        },
        {
            Header: selectedText.constructionPhase || 'Construction ',
            accessor: 'constructionPhase',
            Cell: ({ value }) => `${value}`,
            style: { backgroundColor: '#B0D9B1' }
        },
        {
            Header: selectedText.usePhase || '',
            accessor: 'usePhase',
            Cell: ({ value }) => `${value}`,
            style: { backgroundColor: '#d2eed8' }
        },
        {
            Header: selectedText.endOfLifePhase || 'End-of-life Phase',
            accessor: 'endOfLifePhase',
            Cell: ({ value }) => `${value}`,
            style: { backgroundColor: '#effbf1' }
        },
        {
            Header: selectedText.benefitsPhase || 'Benefits Phase',
            accessor: 'benefitsPhase',
            Cell: ({ value }) => `${value}`,
            style: { backgroundColor: '#f5f5f5' }
        },
        {
            Header: selectedText.total || 'Total',
            accessor: 'total',
            Cell: ({ value }) => `${value}`
        }
    ], [productLabel, selectedText]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: footprintSummaryColumns, data: footprintSummaryData });

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(selectedLanguage === 'FR' ? 'fr-FR' : 'en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    const colors = [
        'rgba(255, 99, 132, 0.5)',
        'rgba(53, 162, 235, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(255, 159, 64, 0.5)'
    ];

    const barData = {
        labels: [
            selectedText.productPhase || 'Product Phase',
            selectedText.constructionPhase || 'Construction Phase',
            selectedText.usePhase || 'Use Phase',
            selectedText.endOfLifePhase || 'End-of-life Phase',
            selectedText.benefitsPhase || 'Benefits Phase'
        ],
        datasets: footprintSummaryData.map((product, index) => ({
            label: product.product,
            data: [
                parseFloat(product.productPhase),
                parseFloat(product.constructionPhase),
                parseFloat(product.usePhase),
                parseFloat(product.endOfLifePhase),
                parseFloat(product.benefitsPhase)
            ],
            backgroundColor: colors[index % colors.length],
            borderColor: colors[index % colors.length].replace('0.5', '1'),
            borderWidth: 1,
        })),
    };

    console.log('Bar Data:', barData);

    const barOptions = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
            },
        },
        maintainAspectRatio: true,
        scales: {
            y: {
                title: {
                    display: true,
                    text: selectedText.gwp || 'GWP [kg CO₂e]'
                }
            },
            x: {
                title: {
                    display: true,
                    text: selectedText.phases || 'Phases'
                }
            }
        }
    };

    const calculateAverages = (data) => {
        const totals = {
            productPhase: 0,
            constructionPhase: 0,
            usePhase: 0,
            endOfLifePhase: 0,
            benefitsPhase: 0,
            count: data.length
        };

        data.forEach(item => {
            totals.productPhase += parseFloat(item.productPhase);
            totals.constructionPhase += parseFloat(item.constructionPhase);
            totals.usePhase += parseFloat(item.usePhase);
            totals.endOfLifePhase += parseFloat(item.endOfLifePhase);
            totals.benefitsPhase += parseFloat(item.benefitsPhase);
        });

        return {
            productPhase: (totals.productPhase / totals.count).toFixed(2) || 0,
            constructionPhase: (totals.constructionPhase / totals.count).toFixed(2) || 0,
            usePhase: (totals.usePhase / totals.count).toFixed(2) || 0,
            endOfLifePhase: (totals.endOfLifePhase / totals.count).toFixed(2) || 0,
            benefitsPhase: (totals.benefitsPhase / totals.count).toFixed(2) || 0
        };
    };

    const averages = calculateAverages(footprintSummaryData);
    console.log('Averages:', averages);

    const percentageDifference = (value1, value2) => {
        if (value1 === 0 || value2 === 0) {
            return 0;
        }
        const denominator = (value1 + value2) / 2;
        return denominator !== 0 ? (((value1 - value2) / denominator) * 100).toFixed(2) : 0;
    };

    const percentageDifferenceBarData = {
        labels: Object.keys(percentageDifferences),
        datasets: [{
            label: selectedText.percentageDifference || 'Percentage Difference',
            data: Object.values(percentageDifferences).map(value => parseFloat(value)),
            backgroundColor: Object.values(percentageDifferences).map(value => value < 0 ? 'rgba(75, 192, 192, 0.5)' : 'rgba(255, 99, 132, 0.5)'),
            borderColor: Object.values(percentageDifferences).map(value => value < 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)'),
            borderWidth: 1
        }]
    };

    const percentageDifferenceBarOptions = {
        indexAxis: 'y',
        scales: {
            x: {
                title: {
                    display: true,
                    text: selectedText.percentageDifferenceChart || 'Percentage Difference (%)'
                }
            },
            y: {
                title: {
                    display: true,
                    text: productLabel
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    generateLabels: function (chart) {
                        const comparisonLabel = comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label || (selectedText.value || 'Value'); // Dynamic value

                        return [
                            {
                                text: `${selectedText.lessThan || '% less than'} ${comparisonLabel}`,
                                fillStyle: 'rgba(75, 192, 192, 0.5)',
                                strokeStyle: 'rgba(75, 192, 192, 1)',
                                hidden: false,
                            },
                            {
                                text: `${selectedText.moreThan || '% more than'} ${comparisonLabel}`,
                                fillStyle: 'rgba(255, 99, 132, 0.5)',
                                strokeStyle: 'rgba(255, 99, 132, 1)',
                                hidden: false,
                            }
                        ];
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.raw}%`;
                    }
                }
            }
        }
    };

    const downloadChartAsImage = (ref, filename) => {
        const chart = ref.current;
        if (chart) {
            const url = chart.toBase64Image();
            const link = document.createElement('a');
            link.href = url;
            link.download = `${filename}.png`;
            link.click();
        }
    };

    const generateReport = async () => {
        // Convert the first chart (barChartRef) to Base64 PNG
        const chartCanvas1 = barChartRef.current;
        const chartImageBase64_1 = chartCanvas1.toBase64Image(); // Base64 PNG string for the first chart

        // Convert the second chart (footprintChartRef) to Base64 PNG
        const chartCanvas2 = footprintChartRef.current;
        const chartImageBase64_2 = chartCanvas2.toBase64Image(); // Base64 PNG string for the second chart

        // Convert Base64 to an ArrayBuffer for DOCX
        const base64ToArrayBuffer = (base64) => {
            const binaryString = window.atob(base64.split(',')[1]);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        };

        const chartImageArrayBuffer_1 = base64ToArrayBuffer(chartImageBase64_1); // ArrayBuffer for the first chart
        const chartImageArrayBuffer_2 = base64ToArrayBuffer(chartImageBase64_2); // ArrayBuffer for the second chart

        // Extract headers from footprintSummaryColumns (assuming footprintSummaryColumns is your column config)
        const tableHeaders = [
            { text: productLabel },
            { text: "Product Phase (kg CO₂e)" },
            { text: "Construction Phase (kg CO₂e)" },
            { text: "Use Phase (kg CO₂e)" },
            { text: "End-of-Life Phase (kg CO₂e)" },
            { text: "Benefits Phase (kg CO₂e)" },
            { text: "Total (kg CO₂e)" },
        ];

        // Extract data from footprintSummaryData
        const tableRows = footprintSummaryData.map(item => [
            { text: item.product },
            { text: item.productPhase ? `${item.productPhase} kg CO₂e` : '0 kg CO₂e' },
            { text: item.constructionPhase ? `${item.constructionPhase} kg CO₂e` : '0 kg CO₂e' },
            { text: item.usePhase ? `${item.usePhase} kg CO₂e` : '0 kg CO₂e' },
            { text: item.endOfLifePhase ? `${item.endOfLifePhase} kg CO₂e` : '0 kg CO₂e' },
            { text: item.benefitsPhase ? `${item.benefitsPhase} kg CO₂e` : '0 kg CO₂e' },
            { text: item.total ? `${item.total} kg CO₂e` : '0 kg CO₂e' },
        ]);

        const doc = new Document({
            sections: [
                {
                    children: [
                        // Heading 1: "Comparison Report"
                        new Paragraph({
                            text: "Comparison Report",
                            heading: "Heading1"
                        }),
                        // Existing table and phase data
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph('Comparison Type')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Comparison Based On')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Selected Values')],
                                        }),
                                    ],
                                }),
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph(comparisonType)],
                                        }),
                                        new TableCell({
                                            children: [
                                                new Paragraph(comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)?.label || comparisonBasedOn),
                                            ],
                                        }),
                                        new TableCell({
                                            children: selectedValues.length > 0 ? selectedValues.map(value =>
                                                new Paragraph(comparisonBasedOnOptions.find(option => option.value === value)?.label || value)
                                            ) : [new Paragraph('-')],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                        // Adding a new line before the first Heading2
                        new Paragraph(""),

                        // Adding the phase comparison data
                        new Paragraph({
                            text: "Phase Comparison",
                            heading: "Heading2"
                        }),
                        new Table({
                            rows: [
                                // Header row
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph('Phase')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Current (kg CO₂e)')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Average (kg CO₂e)')],
                                        }),
                                        new TableCell({
                                            children: [new Paragraph('Difference (kg CO₂e)')],
                                        }),
                                    ],
                                }),
                                ...Object.keys(phaseKeys).map(phase => {
                                    const phaseKey = phaseKeys[phase];
                                    const currentRaw = parseFloat(selectedComparisonData[phaseKey]);
                                    const averageRaw = parseFloat(averages[phaseKey]);

                                    // Ensure we handle NaN properly by defaulting to 0
                                    const current = isNaN(currentRaw) ? 0 : currentRaw.toFixed(2);
                                    const average = isNaN(averageRaw) ? 0 : averageRaw.toFixed(2);
                                    const difference = Math.abs(current - average).toFixed(2);

                                    return new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph(phase)],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${current} kg CO₂e`)],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${average} kg CO₂e`)],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${difference} kg CO₂e`)],
                                            }),
                                        ],
                                    });
                                })
                            ],
                        }),
                        // Adding a new line before the next Heading2
                        new Paragraph(""),

                        // Adding the first chart image to the Word document
                        new Paragraph({
                            text: "Environmental Footprint Chart",
                            heading: "Heading2"
                        }),
                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: chartImageArrayBuffer_1,
                                    transformation: {
                                        width: 600,  // Adjust the width as needed
                                        height: 300,  // Adjust the height as needed
                                    },
                                })
                            ]
                        }),
                        // Adding a new line before the next Heading2
                        new Paragraph(""),

                        // Adding the table from footprint data
                        new Paragraph({
                            text: "Footprint Summary",
                            heading: "Heading2"
                        }),
                        new Table({
                            rows: [
                                // Table headers
                                new TableRow({
                                    children: tableHeaders.map(header => new TableCell({
                                        children: [new Paragraph(header.text)],
                                    }))
                                }),
                                // Table rows with footprint data
                                ...tableRows.map(row => new TableRow({
                                    children: row.map(cell => new TableCell({
                                        children: [new Paragraph(cell.text)],
                                    })),
                                })),
                            ],
                        }),
                        // Adding a new line before the next Heading2
                        new Paragraph(""),

                        // Adding the second chart image (footprint chart) to the Word document
                        new Paragraph({
                            text: "Percentage Difference Chart",
                            heading: "Heading2"
                        }),
                        new Paragraph({
                            children: [
                                new ImageRun({
                                    data: chartImageArrayBuffer_2,
                                    transformation: {
                                        width: 200,  // Adjust the width as needed
                                        height: 100,  // Adjust the height as needed
                                    },
                                })
                            ]
                        }),
                    ],
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: 'Arivu.2024 | Copyright © 2024 VRTTA Green Solutions. All rights reserved.',
                                            font: 'Arial',
                                            size: 20, // font size
                                            italics: true,
                                        })
                                    ],
                                    alignment: 'center', // Center-align the footer text
                                })
                            ]
                        })
                    }
                },
            ],
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `Comparison_Report_${formattedDate}.docx`);
        });
    };


    return (
        <div className="comparison-page">
            <Header selectedLanguage={selectedLanguage} />
            <div className="comparison-container">
                <div className="dropdown-container">
                    <Select
                        options={comparisonTypeOptions}
                        value={comparisonTypeOptions.find(option => option.value === comparisonType)}
                        onChange={selectedOption => setComparisonType(selectedOption.value)}
                        className="dropdown"
                        classNamePrefix="custom-selecttype"
                        placeholder={selectedText.selectComparisonType || 'Select Comparison Type'}
                    />
                    <Select
                        options={comparisonBasedOnOptions}
                        value={comparisonBasedOnOptions.find(option => option.value === comparisonBasedOn)}
                        onChange={selectedOption => setComparisonBasedOn(selectedOption.value)}
                        className="dropdown"
                        classNamePrefix="custom-select"
                        placeholder={selectedText.comparisonBasedOn || 'Comparison Based On'}
                        isSearchable
                    />
                    <Select
                        isMulti
                        options={comparisonBasedOnOptions.filter(option => option.value !== comparisonBasedOn)} // Filter out the selected comparisonBasedOn
                        value={comparisonBasedOnOptions.filter(option => selectedValues.includes(option.value))}
                        onChange={selectedOptions => setSelectedValues(selectedOptions.map(item => item.value))}
                        className="dropdown"
                        classNamePrefix="custom-select"
                        placeholder={selectedText.selectValuesToCompare || 'Select Values to Compare'}
                    />
                    <button onClick={handleApply} className="apply-button">
                        {selectedText.apply || 'Apply'}
                    </button>
                </div>
                <div className="phase-cards-container">
                    {Object.keys(phaseKeys).map(phase => {
                        const phaseKey = phaseKeys[phase];
                        return (
                            <PhaseCard
                                key={phase}
                                phase={phase}
                                current={parseFloat(selectedComparisonData[phaseKey]).toFixed(2) || 0}
                                average={parseFloat(averages[phaseKey]).toFixed(2) || 0}
                                isLess={(parseFloat(selectedComparisonData[phaseKey]).toFixed(2) || 0) < (parseFloat(averages[phaseKey]).toFixed(2) || 0)}
                                selectedLanguage={selectedLanguage}
                            />
                        );
                    })}
                </div>
                <div className="comparison-bar-chart">
                    <h2 className="h2-comparison">{selectedText.environmentalFootprint || 'Environmental Footprint'}</h2>
                    <Bar ref={barChartRef} data={barData} options={barOptions} />
                    <div className="download-icon" onClick={() => downloadChartAsImage(barChartRef, 'environmental_footprint')}>
                        <Icon icon="fe:download" style={{ color: 'grey', cursor: 'pointer' }} />
                    </div>
                </div>
                <div className="footprint-section">
                    <div className="footprint_table-container">
                        <h2 className="h2-comparison">{selectedText.footprintSummary || 'Footprint Summary'}</h2>
                        <div className="footprint-table-wrapper"> {/* Added wrapper */}
                            <table {...getTableProps()} className="footprint-table">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th key={column.id} {...column.getHeaderProps()} style={column.style}>{column.render('Header')}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr key={row.id} {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td key={cell.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="units-info-comparison">
                            {selectedText.allUnits || 'All units are calculated in kg CO₂e'}
                        </div>
                    </div>
                    <div className="footprint-graph-container">
                        <h2 className="h2-comparison" >{selectedText.percentageDifference || 'Percentage Difference'}</h2>
                        <Bar ref={footprintChartRef} data={percentageDifferenceBarData} options={percentageDifferenceBarOptions} />
                        <div className="download-icon" onClick={() => downloadChartAsImage(footprintChartRef, 'percentage_difference')}>
                            <Icon icon="fe:download" style={{ color: 'grey', cursor: 'pointer' }} />
                        </div>
                    </div>
                </div>
                {/* Inventory Date and Export as DOCX Button Container */}
                <div className="inventory-date-container-comparison">
                    <button onClick={generateReport} className="generate-report-button-comparison">
                        <FiDownload size={18} style={{ marginRight: '8px' }} />
                        {selectedText.exportDocx || 'Generate Report'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Comparison;
