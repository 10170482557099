import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../style/pages-style/NetZero.css';
import Header from '../app_components/Header';
import {translations} from "../app_components/Translation";
import TableComponent from "../environment-page-components/Table";
import Grid from "../environment-page-components/Grid";
import ScopeEmissionGraph from "../net-zero-page-components/ScopeEmissionGraph";
import config from "../config";
import netZeroTableHeaderComponent from "../net-zero-page-components/netZeroTableHeaderComponent";

function NetZero({selectedLanguage}) {
    const selectedText = translations[selectedLanguage].netZero;

    const fixedScopes = [
        "Scope 1",
        "Scope 2 (location-based)",
        "Scope 2 (market-based)",
        "Scope 3, category 1: Purchased goods and services",
        "Scope 3, category 2: Capital goods",
        "Scope 3, category 3: Fuel- and energy-related activities",
        "Scope 3, category 4: Upstream transportation and distribution",
        "Scope 3, category 5: Waste generated in operations",
        "Scope 3, category 6: Business travel",
        "Scope 3, category 7: Employee commuting",
        "Scope 3, category 8: Upstream leased assets",
        "Scope 3, category 9: Downstream transportation and distribution",
        "Scope 3, category 10: Processing of sold products",
        "Scope 3, category 11: Use of sold products",
        "Scope 3, category 12: End-of-life treatment of sold products",
        "Scope 3, category 13: Downstream leased assets",
        "Scope 3, category 14: Franchises",
        "Scope 3, category 15: Investments",
    ];

    const initialColumnDefs = [
        {
            headerName: "Scope",
            field: "scope",
            sortable: true,
            filter: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: "Based Year Emissions(tCO2e)",
            field: "column1",
            sortable: true,
            filter: true,
            valueFormatter: (params) => formatFloatValue(params.value, 2),
            headerComponent: netZeroTableHeaderComponent,
            headerComponentParams: {
                onButtonClick: () => handleHeaderButtonClick('column1'),
            },
        },
    ];

    const initialRowData = fixedScopes.map((scope) => ({
        scope,
        column1: 0, // Initialize the first year to 0
    }));

    const [columnDefs, setColumnDefs] = useState(initialColumnDefs);
    const columnDefsRef = useRef(initialColumnDefs);
    const [rowData, setRowData] = useState(initialRowData);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const selectedRowDataRef = useRef(selectedRowData);
    const [mainTableGridApi, setMainTableGridApi] = useState(null);
    const [trackViewState, setTrackViewState] = useState(false);
    const [allTableRows, setAllTableRows] = useState([]);
    const [pageType, setPageType] = useState('valueChain');
    const [scopeEmissions, setScopeEmissions] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const netZeroPage = true;
    const [selectedScope, setSelectedScope] = useState([]);

    const updateColumnDefs = (newColumnDefs) => {
        setColumnDefs(newColumnDefs);
        columnDefsRef.current = newColumnDefs;
    };

    useEffect(() => {
        selectedRowDataRef.current = selectedRowData;
    }, [selectedRowData]);

    const handleHeaderButtonClick = async (field) => {
        try {
            const currentSelectedRowData = selectedRowDataRef.current;
            console.log(currentSelectedRowData[0])
            const response = await fetch(`${config.apiUrl}/api/get-yearly-based-scope-emissions?row_id=${currentSelectedRowData[0].id}`);
            const data = await response.json();

            // Check if the fetched data contains the correct structure
            if (!Array.isArray(data)) {
                console.error('Invalid data format from API');
                return;
            }

            // Update rowData with the fetched values
            const updatedRowData = rowData.map((row, index) => ({
                ...row,
                [field]: index < data.length ? data[index].value : 0, // Use fetched value or fallback to 0
            }));
            setRowData(updatedRowData);

            // Access the latest columnDefs using the ref
            const updatedColumnDefs = columnDefsRef.current.map((colDef) => {
                if (colDef.field === field) {
                    return {
                        ...colDef,
                        headerName: `Based Year Emissions(tCO2e) - ${currentSelectedRowData[0].Year}`,
                    };
                }
                return colDef;
            });

            updateColumnDefs(updatedColumnDefs); // Update columnDefs and ref
        } catch (error) {
            console.error('Error fetching data from backend:', error);
        }
    };

    const toggleContainer = () => setIsOpen(!isOpen);

    function formatFloatValue(value, decimalPlaces) {
        return parseFloat(value).toFixed(decimalPlaces);
    }

    const addColumn = () => {
        const newFieldName = `column${columnDefs.length}`;
        const newColumn = {
            headerName: `Yearly Emissions(tCO2e)`,
            field: newFieldName,
            sortable: true,
            filter: true,
            valueFormatter: (params) => formatFloatValue(params.value, 2),
            headerComponent: netZeroTableHeaderComponent,
            headerComponentParams: {
                onButtonClick: () => handleHeaderButtonClick(newFieldName),
            },
        };

        // Update column definitions
        const updatedColumnDefs = [...columnDefsRef.current, newColumn];
        updateColumnDefs(updatedColumnDefs);

        // Update row data to include the new column with value 0
        const updatedRowData = rowData.map((row) => ({
            ...row,
            [newFieldName]: 0, // Initialize to 0
        }));
        setRowData(updatedRowData);
    }

    return (
        <div className="supplier-utilizer">
            <div className="netZero-page">
                <Header selectedLanguage={selectedLanguage}/>
                <div className={`main-table-container-circularity ${netZeroPage ? 'circularity-active' : ''}`}>
                    <TableComponent
                        setSelectedRowData={setSelectedRowData}
                        setMainTableGridApi={setMainTableGridApi}
                        setTrackViewState={setTrackViewState}
                        selectedLanguage={selectedLanguage}
                        setAllTableRows={setAllTableRows}
                        childTableRows={[]}
                        pageType={pageType}
                        setPageType={setPageType}
                        netZeroPage={netZeroPage}
                    />

                    <div className="material-container">
                        <div className="container-header-material">
                            <h2
                                onClick={toggleContainer}
                                onMouseEnter={() => document.querySelector('.container-header-material h2').style.color = 'grey'}  // Hover color
                                onMouseLeave={() => document.querySelector('.container-header-material h2').style.color = 'black'} // Default color
                                style={{cursor: 'pointer'}}
                            >
                                {selectedText.ghgTitle}
                            </h2>
                        </div>

                        {isOpen && (
                            <div>
                                <button onClick={addColumn}>Add Column</button>
                                <Grid
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    rowSelection="multiple"
                                    onSelectionChanged={(event) => setSelectedScope(event.api.getSelectedRows())}
                                />
                            </div>
                        )}
                    </div>

                </div>
                <ScopeEmissionGraph selectedText={selectedText} scopeEmissionData={scopeEmissions}/>
            </div>
        </div>
    );
}

export default NetZero;
