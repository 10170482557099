import React, {useState} from "react";
import {Line} from 'react-chartjs-2';
import {Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip} from 'chart.js';
import '../style/components-style/ScopeEmissionGraph.css'

// Register required Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);


function ScopeEmissionGraph({selectedText, scopeEmissionData}) {
    const [isOpen, setIsOpen] = useState(true);
    const toggleContainer = () => setIsOpen(!isOpen);

    const labels = ['Base Year Emissions, FY2024(tCO2e)', 'FY2025(tCO2e)', 'FY2026(tCO2e)', 'FY2027(tCO2e)'];

    const graphData = {
        labels: labels,
        datasets: scopeEmissionData.map((entry) => ({
            label: entry.scope,
            data: [entry.FY2024, entry.FY2025, entry.FY2026, entry.FY2027],
            borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                Math.random() * 255
            )}, 1)`, // Random color
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                Math.random() * 255
            )}, 0.2)`,
            fill: false,
        })),
    };

    // Graph options
    const graphOptions = {
        responsive: true,
        maintainAspectRatio: true, // Disable aspect ratio to customize the size
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Years',
                    font: {
                        size: 16 // Adjust the font size
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Scope Emissions (tCO₂e)',
                    font: {
                        size: 16 // Adjust the font size
                    }
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 14 // Increase legend font size
                    }
                }
            },
            tooltip: {
                enabled: true,
                bodyFont: {
                    size: 14 // Adjust tooltip font size
                }
            }
        }
    };


    return (
        <div className="scope-emission-graph">
            <div className="scope-emission-graph-header">
                <h2
                    onClick={toggleContainer}
                    onMouseEnter={() => document.querySelector('.scope-emission-graph-header h2').style.color = 'grey'}
                    onMouseLeave={() => document.querySelector('.scope-emission-graph-header h2').style.color = 'black'}
                    style={{cursor: 'pointer'}}
                >
                    {selectedText.ghgGraphTitle || 'Emission Graph'}
                </h2>
            </div>
            {isOpen && (
                <Line data={graphData} options={graphOptions}/>

            )}
        </div>
    )
}

export default ScopeEmissionGraph;