import { jsPDF } from "jspdf";
import { formatDate } from "./formatDate";

export const generateNRCanPDF = async (formFields) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "a4",
  });

  const pdfWidth = doc.internal.pageSize.getWidth();
  const pdfHeight = doc.internal.pageSize.getHeight();
  const leftMargin = pdfWidth * 0.1;
  const labelWidth = 250;
  const valueWidth = pdfWidth - leftMargin - labelWidth - 50;
  const rowHeight = 20;
  const pageBottomMargin = 50;

  let yOffset = 20;
  const xOffset = 10;

  const addImageToTop = () => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = "/vrtta.png";
      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const height = pdfHeight * 0.05;
        const ratio = imgWidth / imgHeight;
        const width = height * ratio;
        const xPos = (pdfWidth - width) / 2;

        doc.addImage(img, "PNG", xPos, yOffset, width, height);
        yOffset += height + 10;
        resolve();
      };
    });
  };

  await addImageToTop();

  doc.setFontSize(16);
  doc.text("NRCan Fleet Report", xOffset, yOffset);
  yOffset += 20;
  doc.setFontSize(8);
  doc.text(`Report by: VRTTA Green Solution Inc.`, xOffset, yOffset);
  yOffset += 15;
  doc.text(`Date: ${new Date().toLocaleDateString()}`, xOffset, yOffset);
  yOffset += 15;
  doc.text(`In accordance with Natural Resources Canada Fleet Energy Assessment Criteria and Guidelines`, xOffset, yOffset);
  yOffset += 30;

  const addVerticalTableSection = (title, data, columns) => {
    doc.setFontSize(10);
    doc.text(title, xOffset + 10, yOffset);
    yOffset += 15;

    if (!Array.isArray(data) || data.length === 0) {
      doc.setFontSize(8);
      doc.text("No data available", xOffset, yOffset);
      yOffset += 15;
      return;
    }

    doc.setFontSize(8);
    doc.setDrawColor(0, 0, 0);

    const keys = Object.keys(data[0]);

    columns.forEach((col, index) => {
      const labelX = leftMargin;
      const valueX = leftMargin + labelWidth;
      const labelY = yOffset + index * rowHeight;

      doc.rect(labelX, labelY, labelWidth, rowHeight);
      doc.text(col, labelX + 2, labelY + 15);

      const value = index >= 0 ? data[0][keys[index]] || "" : "";

      doc.rect(valueX, labelY, valueWidth, rowHeight);
      doc.text(String(value), valueX + 2, labelY + 15);
    });

    yOffset += columns.length * rowHeight + 20;
  };

  const addHorizontalTableSection = (title, data, rows) => {
    doc.setFontSize(10);
    doc.text(title, 10, yOffset);
    yOffset += 15;

    if (!Array.isArray(data) || data.length === 0) {
      doc.setFontSize(8);
      doc.text("No data available", 10, yOffset);
      yOffset += 15;
      return;
    }

    doc.setFontSize(8);
    doc.setDrawColor(0, 0, 0);

    const margin = pdfWidth * 0.03;
    const headerWidth = (pdfWidth - 2 * margin) / (data.length + 1);
    const textPadding = 2;
    const cellPadding = 4;
    const baseCellHeight = 15;

    // Add headers with text wrapping
    const headerHeight = baseCellHeight;
    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");

    // Draw headers
    doc.rect(margin, yOffset, headerWidth, headerHeight);
    doc.text("Month", margin + textPadding, yOffset + 12);

    data.forEach((item, index) => {
      const xOffsetHeader = margin + (index + 1) * headerWidth;
      doc.rect(xOffsetHeader, yOffset, headerWidth, headerHeight);
      const monthText = String(item.month || "");
      const monthLines = doc.splitTextToSize(monthText, headerWidth - 4);
      monthLines.forEach((line, lineIndex) => {
        doc.text(line, xOffsetHeader + textPadding, yOffset + 12 + lineIndex * 10);
      });
    });

    yOffset += headerHeight;

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");

    // Add rows with text wrapping
    rows.forEach((row) => {
      // Calculate the max height required for the row
      let maxRowHeight = baseCellHeight;

      // Calculate the row height for the label
      const labelLines = doc.splitTextToSize(row.label, headerWidth - 4);
      maxRowHeight = Math.max(maxRowHeight, labelLines.length * 10 + cellPadding);

      // Calculate the row height for each cell
      data.forEach((item, index) => {
        const cellValue = item[row.key] || "";
        const cellLines = doc.splitTextToSize(String(cellValue), headerWidth - 4);
        maxRowHeight = Math.max(maxRowHeight, cellLines.length * 10 + cellPadding);
      });

      // Draw the row
      doc.rect(margin, yOffset, headerWidth, maxRowHeight);
      labelLines.forEach((line, lineIndex) => {
        doc.text(line, margin + textPadding, yOffset + 12 + lineIndex * 10);
      });

      data.forEach((item, index) => {
        const xOffsetCell = margin + (index + 1) * headerWidth;
        doc.rect(xOffsetCell, yOffset, headerWidth, maxRowHeight);
        const cellValue = item[row.key] || "";
        const cellLines = doc.splitTextToSize(String(cellValue), headerWidth - 4);

        cellLines.forEach((line, lineIndex) => {
          doc.text(line, xOffsetCell + textPadding, yOffset + 12 + lineIndex * 10);
        });

        if (cellLines.length > 1) {
          const lastLineX = xOffsetCell + headerWidth - 10;
          doc.text("...", lastLineX, yOffset + 12 + (cellLines.length - 1) * 10);
        }
      });

      yOffset += maxRowHeight;
    });

    yOffset += 20;
  };

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(`Section 1 – General Fleet Information`, xOffset, yOffset);
  doc.setFont("helvetica", "normal");
  yOffset += 25;

  addVerticalTableSection("A) Fleet Profile", [formFields.fleetProfile || {}], ["Fleet Name", "Headquarters Address", "Operations Address (if different from Headquarters)", "Lead Contact Name/Title", "Contact Email", "Contact Phone", "Age of Company (in years)", "Operation (TL, Mixed, Expedited)", "Ownership (Private/Dedicated/For-hire)"]);
  addVerticalTableSection("B) Description of Fleet - Trucks", formFields.fleetDescription.trucks || [], ["Gross Vehicle Weight (GVW) Class", "Vehicle Type", "Fuel Type", "Duty Cycle", "Number of Trucks/Tractors in that Fleet", "Average Age (Years)", "Percent of Units Equipped with Telematics"]);
  addVerticalTableSection("B) Description of Fleet - Trailers", formFields.fleetDescription.trailers || [], ["Gross Vehicle Weight (GVW) Class", "Trailer Type", "Number of Units", "Average Age (Years)", "Percent of Units Equipped with Telematics"]);

  const spaceLeftOnPage = pdfHeight - yOffset - pageBottomMargin;

  if (spaceLeftOnPage < 7 * rowHeight + 40) {
    doc.addPage();
    yOffset = 20;
  }
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(`Section 2 – Technology Investment Details`, xOffset, yOffset);
  doc.setFont("helvetica", "normal");
  yOffset += 25;

  addVerticalTableSection("A) Previous Fuel Saving/GHG Reduction Investments", formFields.technologyInvestments.previousInvestments || [], ["Technology", "Number of Units on which the Technology is Installed", "Year of Investment", "Estimated Fuel Savings (%)", "Estimated Annual Savings ($)", "Investment ($)", "Notes (optional)"]);
  addVerticalTableSection("B) Technologies Being Considered", formFields.technologyInvestments.technologiesConsidered || [], ["Type", "Technology", "Number of Devices", "Resources Consulted"]);

  if (spaceLeftOnPage < 8 * rowHeight + 40) {
    doc.addPage();
    yOffset = 20;
  }
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(`Section 3 – Data Analysis`, xOffset, yOffset);
  doc.setFont("helvetica", "normal");
  yOffset += 25;

  addVerticalTableSection("A) Retrofit Summary", [formFields.dataAnalysis.retrofitSummary || {}], ["Vehicle Type", "Number of Vehicles", "Technology", "New or Continuing Investment", "Data Source(s)", "Total Investment", "Funding Sources (with amount by source)", "Reason GFP Funding is Needed"]);
  addVerticalTableSection("B) Fuel and GHG Reducing Potential", [formFields.dataAnalysis.fuelAndGHGReduction || {}], ["Forecasted Annual Kilometres Travelled", "Forecasted Annual Fuel Used", "Forecast KPL", "Forecast GHG Reductions"]);
  addVerticalTableSection("C) Implementation Plan", [formFields.dataAnalysis.implementationPlan || {}], ["Technology", "Total Number of Units", "Delivery or Installation", "Training (if applicable)", "Number of Units Delivered/Installed in Calendar Year ", "Total Number of Units Delivered/Installed Prior to Start of This Project"]);

  if (spaceLeftOnPage < 8 * rowHeight + 40) {
    doc.addPage();
    yOffset = 20;
  }
  addHorizontalTableSection("D1) Retrofit Projects", formFields.dataAnalysis.retrofitProjects || [], [
    { label: "Number of trucks/ trailers", key: "noTrucksTrailers" },
    { label: "Distance travelled (km)", key: "distanceTravelled" },
    { label: "Fuel consumed (litres)", key: "fuelConsumed" },
    { label: "KPL", key: "KPL" },
    { label: "Estimated fuel reduction (litres)", key: "estimatedReduction" },
  ]);
  addHorizontalTableSection("D2) Forecasted Fuel Saving Reductions", formFields.dataAnalysis.forecastedReductions || [], [
    { label: "Number of units", key: "noUnits" },
    { label: "Distance travelled (km)", key: "totalDistance" },
    { label: "Total traditional fuel (litres)", key: "totalFuel" },
    { label: "L/100 km or MPG", key: "mpg" },
  ]);

  doc.save(`NRCan_fleet_report-${formatDate(new Date())}.pdf`);
};
