import React, { useEffect, useState } from 'react';
import '../style/sidemenu-pages-style/Profile.css';
import Header from '../app_components/Header';
import config from '../config';
import { useAuth } from "../authentication/AuthProvider";
import { translations } from '../app_components/Translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Profile({ selectedLanguage }) {
    const selectedText = translations[selectedLanguage]?.profile || {};
    const [profileData, setProfileData] = useState({
        email: '',
        company_name: '',
        address: '',
        phone_number: '',
        password: '' // Add password to the profile data state
    });

    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

    const { user } = useAuth();
    const userId = user ? user.username : null;

    useEffect(() => {
        if (!userId) return;

        // Fetch user data for the current user
        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                table_name: 'users',
                attribute: '*',
                condition: `id = "${userId}"`  // Fetch data for the current user ID
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.length > 0) {
                const userData = data[0]; // Assuming only one user data is returned
                setProfileData({
                    email: userData.email,
                    company_name: userData.company_name,
                    address: userData.address,
                    phone_number: userData.phone_number,
                    password: userData.password // Set the password in the state
                });
            }
        })
        .catch(error => {
            console.error('Error fetching profile data:', error);
        });
    }, [userId]);

    return (
        <div className="profile">
            <Header selectedLanguage={selectedLanguage}/>
            <div className="profile-container">
                <div className="profile-left">
                    {/* Login Info Section */}
                    {/* <div className="profile-section">
                        <h2>{selectedText.loginInfo}</h2>
                        <div className="input-group">
                            <label>{selectedText.email}</label>
                            <input type="email" value={profileData.email} readOnly />
                        </div>
                        <div className="input-group">
                            <label>{selectedText.password}</label>
                            <input 
                                type={showPassword ? "text" : "password"} 
                                value={profileData.password} 
                                readOnly 
                            />
                            <FontAwesomeIcon 
                                icon={showPassword ? faEyeSlash : faEye} 
                                className="toggle-password-icon" 
                                onClick={() => setShowPassword(!showPassword)} 
                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                            />
                        </div>
                    </div> */}

                    {/* User Profile Section */}
                    <div className="profile-section">
                        <h2>{selectedText.userProfile}</h2>
                        <div className="input-group">
                            <label>{selectedText.company}</label>
                            <input type="text" value={profileData.company_name} readOnly />
                        </div>
                        <div className="input-group">
                            <label>{selectedText.address}</label>
                            <input type="text" value={profileData.address} readOnly />
                        </div>
                        <div className="input-group">
                            <label>{selectedText.phoneNumber}</label>
                            <input type="text" value={profileData.phone_number} readOnly />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
