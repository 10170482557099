import { useState } from "react";
import styles from "./GenerateReportButton.module.css";

import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import NRCanReport from "./NRCanReport";
import { generateNRCanPDF } from "../../utils/fleetUtils/NRCanReport";
import { FleetSustainabilityReport } from "../../utils/fleetUtils/FleetSustainabilityReport";
import { FaCanadianMapleLeaf, FaLeaf } from "react-icons/fa";
import arivuLogo from "../../assets/logo/ArivuHeader.png";

Modal.setAppElement("#root");

const GenerateReportButton = ({ tableData, setIsGenerating, generatePdf }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [formFields, setFormFields] = useState(null);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  const baseSummary = tableData.reduce(
    (acc, item) => {
      // Convert values to numbers and ensure they are valid before performing calculations
      const distanceTravelled = parseFloat(item["Distance travelled (km)"]) || 0;
      const fuelConsumption = parseFloat(item["Fuel consumption (L)"]) || 0;
      const engineRunTime = parseFloat(item["Engine run time (hr)"]) || 0;
      const idlingTimePercent = parseFloat(item["Idling time (%)"]) || 0;

      // Add the values to the accumulator
      acc["Distance Travelled"] += distanceTravelled;
      acc["Fuel Consumption"] += fuelConsumption;
      acc["Engine run time"] += engineRunTime;
      acc["Engine idling time"] += (idlingTimePercent / 100) * engineRunTime;
      acc["GHG Emissions"] += fuelConsumption * 2.7;

      return acc;
    },
    {
      "Distance Travelled": 0,
      "Fuel Consumption": 0,
      "Engine run time": 0,
      "Engine idling time": 0,
      "GHG Emissions": 0,
    },
  );

  // Ensure values are rounded to 0 decimal places
  const summary = {
    "Distance Travelled": baseSummary["Distance Travelled"].toFixed(0),
    "Fuel Consumption": baseSummary["Fuel Consumption"].toFixed(0),
    "Engine run time": baseSummary["Engine run time"].toFixed(0),
    "Engine idling time": baseSummary["Engine idling time"].toFixed(0),
    "GHG Emissions": baseSummary["GHG Emissions"].toFixed(0),
  };

  // Single state for managing all section checkboxes
  const [selectedSections, setSelectedSections] = useState({
    generalMetrics: true,
    fleetAssetsTable: true,
    fleetSummary: true,
    utilizationAnalysis: true,
  });

  const [currentStep, setCurrentStep] = useState(1);

  // Navigation functions
  const goToNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setCurrentStep(3);
    } else if (currentStep === 3) {
      setCurrentStep(4);
    } else if (currentStep === 4) {
      setCurrentStep(5);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleGeneratePDF = async () => {
    if (selectedTab === 0 || selectedTab === 1) {
      toggleModal();
      setIsGenerating(true);
      try {
        if (selectedTab === 0) {
          await generatePdf(selectedSections);
        }
        if (selectedTab === 1) {
          await FleetSustainabilityReport(summary);
        }
      } finally {
        setTimeout(() => {
          setIsGenerating(false);
        }, 1000);
      }
    } else if (selectedTab === 1 && currentStep === 5 && formFields) {
      toggleModal();
      setIsGenerating(true);
      try {
        await generateNRCanPDF(formFields);
      } finally {
        setTimeout(() => {
          setIsGenerating(false);
        }, 1000);
      }
    }
  };

  const FleetManagerReport = () => {
    // Data for checkboxes (key and label)
    const sections = [
      { key: "generalMetrics", label: "Include General Metrics" },
      { key: "fleetAssetsTable", label: "Include Fleet Assets Table" },
      { key: "fleetSummary", label: "Include Fleet Summary" },
      { key: "utilizationAnalysis", label: "Include Utilization Analysis" },
    ];

    // Function to handle checkbox change
    const handleCheckboxChange = (sectionKey) => {
      setSelectedSections((prevState) => ({
        ...prevState,
        [sectionKey]: !prevState[sectionKey],
      }));
    };

    return (
      <div className={styles.reportContent}>
        <h2 className={styles.reportTitle}>Fleet Manager Report</h2>
        {sections.map(({ key, label }) => (
          <section className={styles.checkboxSection} key={key}>
            <label>
              <input type="checkbox" checked={selectedSections[key]} onChange={() => handleCheckboxChange(key)} />
              {label}
            </label>
          </section>
        ))}
      </div>
    );
  };

  return (
    <>
      <button className={styles.generateReportButton} onClick={toggleModal}>
        Generate Report
      </button>

      <Modal isOpen={modalIsOpen} onRequestClose={toggleModal} contentLabel="Generate Report" className={styles.modal} overlayClassName={styles.overlay}>
        <header className={styles.modalHeader}> Generate Report </header>
        <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
          <TabList className={styles.tablist}>
            <Tab className={`${styles.tab} ${selectedTab === 0 ? styles.selected : ""}`}>
              <img src={arivuLogo} alt="ARIVU" style={{ width: "50px", marginRight: "5px" }} />
              Fleet Manager Report
            </Tab>
            <Tab className={`${styles.tab} ${selectedTab === 1 ? styles.selected : ""}`}>
              <FaLeaf size={"25px"} style={{ marginRight: "5px", color: "green" }} />
              Fleet Sustainability Report
            </Tab>
            <Tab className={`${styles.tab} ${selectedTab === 2 ? styles.selected : ""}`}>
              <FaCanadianMapleLeaf size={"25px"} style={{ marginRight: "5px", color: "red" }} />
              NRCan Fleet Energy Assessment Report
            </Tab>
          </TabList>

          <TabPanel className={styles.tabPanel}>
            <FleetManagerReport />
          </TabPanel>
          <TabPanel className={styles.tabPanel}></TabPanel>
          <TabPanel className={styles.tabPanel}>
            <NRCanReport tableData={tableData} numberReefers={4} currentStep={currentStep} setFormFields={setFormFields} />
          </TabPanel>
        </Tabs>
        <div className={styles.footer}>
          <button className={styles.closeButton} onClick={toggleModal}>
            Close
          </button>
          {selectedTab === 0 || selectedTab === 1 ? (
            <button className={styles.generateReportActionButton} onClick={handleGeneratePDF}>
              Generate PDF
            </button>
          ) : (
            <div className={styles.buttonContainer}>
              <div>
                {currentStep != 1 && (
                  <button className={styles.navButton} onClick={goToPreviousStep} disabled={currentStep === 1}>
                    Back
                  </button>
                )}
              </div>
              <div>
                {" "}
                {currentStep != 5 ? (
                  <button className={styles.navButton} onClick={goToNextStep}>
                    Next
                  </button>
                ) : (
                  <button className={styles.generateReportActionButton} onClick={handleGeneratePDF}>
                    Generate PDF
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default GenerateReportButton;
