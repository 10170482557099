import React from "react";

function netZeroTableHeaderComponent(props) {
    const handleButtonClick = () => {
        props.onButtonClick(props.field);
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span>{props.displayName}</span>
            <button
                onClick={handleButtonClick}
                style={{
                    marginLeft: "10px",
                    padding: "5px 10px",
                    fontSize: "12px",
                    cursor: "pointer",
                }}
            >
                +
            </button>
        </div>
    );
}

export default netZeroTableHeaderComponent;