import React, {useEffect, useRef, useState} from 'react';
import {
    Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title, Tooltip, Legend
} from 'chart.js';
import {Doughnut, Bar} from 'react-chartjs-2';
import {Icon} from '@iconify/react';
import '../style/components-style/Footprint.css';
import {translations} from '../app_components/Translation';
import {toPng} from "html-to-image";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function getRandomRGB() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return {r, g, b};
}

function getDistinctRGB(index, total) {
    const hue = (index / total) * 360; // Space out colors evenly around the hue circle
    const saturation = 70 + Math.random() * 30; // Random saturation between 70% and 100%
    const lightness = 50 + Math.random() * 10; // Random lightness between 50% and 60%

    // Convert HSL to RGB
    const hslToRgb = (h, s, l) => {
        s /= 100;
        l /= 100;
        const k = n => (n + h / 30) % 12;
        const a = s * Math.min(l, 1 - l);
        const f = n => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
        return [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];
    };
    const [r, g, b] = hslToRgb(hue, saturation, lightness);
    return {r, g, b};
}

const scope1Categories = ['Stationary Combustion', 'Mobile Sources', 'Refrigeration / AC Equipment Use', 'Fire Suppression', 'Purchased Gases'];
const scope2Categories = ['Electricity (Location Based)', 'Electricity (Market Based)', 'Steam'];
const scope3Categories = ['Business Travel', 'Employee Commuting', 'Upstream Transportation and Distribution',
    'Downstream Transportation and Distribution', 'Purchased Goods and Services', 'Capital Goods', 'Fuel and Energy-related Activities',
    'Processing of Sold Products', 'Use of Sold Products', 'End-of-life Treatment of Sold Products', 'Waste', 'Franchises',
    'Investments', 'Upstream Leased Assets', 'Downstream Leased Assets'];
const scope4Categories = ['Scope4'];

function ScopeFootprint({
                            scopeData,
                            scopeDetailsData,
                            selectedLanguage,
                            setFootprintDoughnut,
                            setFootprintChart,
                            selectedRow,
                            selectedScopeData
                        }) {
    const [isFootprintOverviewOpen, setIsFootprintOverviewOpen] = useState(true);
    const [isEnvironmentalFootprintOpen, setIsEnvironmentalFootprintOpen] = useState(true);
    const [selectedScope, setSelectedScope] = useState('All');
    const doughnutChartContainerRef = useRef(null);
    const footprintBarChartRef = useRef(null);
    const [selectedGraph, setSelectedGraph] = useState('emissionGraph');
    const [overviewIsHovered, setOverviewIsHovered] = useState(false);
    const [environmentalIsHovered, setEnvironmentalIsHovered] = useState(false);

    const toggleFootprintOverview = () => setIsFootprintOverviewOpen(!isFootprintOverviewOpen);
    const toggleEnvironmentalFootprint = () => setIsEnvironmentalFootprintOpen(!isEnvironmentalFootprintOpen);

    const handleLCAStageChange = (event) => {
        setSelectedScope(event.target.value);
    };

    const selectedText = translations[selectedLanguage].footprint;

    const filteredProcessData = selectedScope === 'All'
        ? scopeData
        : selectedScope === 'Scope1'
            ? scopeData.filter(item => !item.is_avoided && scope1Categories.includes(item.category))
            : selectedScope === 'Scope2'
                ? scopeData.filter(item => !item.is_avoided && scope2Categories.includes(item.category))
                : selectedScope === 'Scope3'
                    ? scopeData.filter(item => !item.is_avoided && scope3Categories.includes(item.category))
                    : scopeData.filter(item => !item.is_avoided && scope4Categories.includes(item.category))

    const emissionBarLabels = filteredProcessData.map(item => !item.is_avoided ? item.category : item.category + "(Avoided)");
    const emissionBarDataPoints = filteredProcessData.map(item => item.gwp);
    const detailsBarLabels = scopeDetailsData.map(item => item.description);
    const detailsBarDataPoints = scopeDetailsData.map(item => item.totalco2eq);

    const emissionBackgroundColors = [];
    const emissionBorderColors = [];
    const detailsBackgroundColors = [];
    const detailsBorderColors = [];

    emissionBarLabels.forEach((label, index) => {
        const {r, g, b} = getDistinctRGB(index, emissionBarLabels.length);
        const opacity = 0.3; // Background color opacity
        emissionBackgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        emissionBorderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Border color is fully opaque
    });

    detailsBarLabels.forEach((label, index) => {
        const {r, g, b} = getDistinctRGB(index, detailsBarLabels.length);
        const opacity = 0.3; // Background color opacity
        detailsBackgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        detailsBorderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Border color is fully opaque
    });

    const emission_barData = selectedRow ? {
        labels: emissionBarLabels,
        datasets: [{
            label: 'GWP',
            data: emissionBarDataPoints,
            backgroundColor: emissionBackgroundColors,
            borderColor: emissionBorderColors,
            borderWidth: 1.5,
            pointRadius: 3,
            pointHoverRadius: 3,
            pointBackgroundColor: emissionBackgroundColors,
            tension: 0.05
        }]
    } : {
        labels: emissionBarLabels,
        datasets: [{
            label: 'GWP',
            data: [],
            backgroundColor: emissionBackgroundColors,
            borderColor: emissionBorderColors,
            borderWidth: 1.5,
            pointRadius: 3,
            pointHoverRadius: 3,
            pointBackgroundColor: emissionBackgroundColors,
            tension: 0.05
        }]
    };

    const details_barData = selectedScopeData ? {
        labels: detailsBarLabels,
        datasets: [{
            label: 'CO2 Emission',
            data: detailsBarDataPoints,
            backgroundColor: detailsBackgroundColors,
            borderColor: detailsBorderColors,
            borderWidth: 1.5,
            pointRadius: 3,
            pointHoverRadius: 3,
            pointBackgroundColor: detailsBackgroundColors,
            tension: 0.05
        }]
    } : {
        labels: detailsBarLabels,
        datasets: [{
            label: 'CO2 Emission',
            data: [],
            backgroundColor: detailsBackgroundColors,
            borderColor: detailsBorderColors,
            borderWidth: 1.5,
            pointRadius: 3,
            pointHoverRadius: 3,
            pointBackgroundColor: detailsBackgroundColors,
            tension: 0.05
        }]
    }

    const barOptions = {
        layout: {
            padding: {
                right: 10 // Add padding to the right to make space for the border
            }
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: true,
                    drawBorder: true,
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: true,
                    drawBorder: true,
                },
                title: {
                    display: true,
                    text: 'GWP [kg CO₂e]',
                    font: {
                        size: 13,
                        weight: 'bold',
                    },
                    padding: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 14
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,  // Ensure the aspect ratio is maintained
        aspectRatio: 3, // Increase this value to reduce the height (e.g., 3 or 4)
        responsive: true
    };


    const totalGWPs = {
        scope1: 0,
        scope2: 0,
        scope3: 0,
        scope4: 0,
        avoided: 0
    };

    scopeData.forEach(item => {
        if (!item.is_avoided && scope1Categories.includes(item.category)) {
            totalGWPs.scope1 += item.gwp;
        }
        if (!item.is_avoided && scope2Categories.includes(item.category)) {
            totalGWPs.scope2 += item.gwp;
        }
        if (!item.is_avoided && scope3Categories.includes(item.category)) {
            totalGWPs.scope3 += item.gwp;
        }
        if (!item.is_avoided && scope4Categories.includes(item.category)) {
            totalGWPs.scope4 += item.gwp;
        }
        if (item.is_avoided) {
            totalGWPs.avoided += item.gwp;
        }
    });

    const totalGWP = selectedRow ? totalGWPs.scope1 + totalGWPs.scope2 + totalGWPs.scope3 + totalGWPs.scope4 : 0.0;
    const avoidedGWP = selectedRow ? totalGWPs.avoided : 0.0;

    const doughnutBackgroundColors = [];
    const doughnutBorderColors = [];

    const doughnutData = selectedRow ? {
        labels: [selectedText.scopeDropdown.scope1, selectedText.scopeDropdown.scope2, selectedText.scopeDropdown.scope3, selectedText.scopeDropdown.scope4],
        datasets: [
            {
                data: [totalGWPs.scope1, totalGWPs.scope2, totalGWPs.scope3, totalGWPs.scope4],
                backgroundColor: doughnutBackgroundColors,
                borderColor: doughnutBorderColors,
                borderWidth: 1,
            },
        ],
    } : {
        labels: [selectedText.scopeDropdown.scope1, selectedText.scopeDropdown.scope2, selectedText.scopeDropdown.scope3, selectedText.scopeDropdown.scope4],
        datasets: [
            {
                data: [0.0, 0.0, 0.0, 0.0],
                backgroundColor: doughnutBackgroundColors,
                borderColor: doughnutBorderColors,
                borderWidth: 1,
            },
        ],
    };

    doughnutData.labels.forEach((label, index) => {
        const {r, g, b} = getDistinctRGB(index, doughnutData.labels.length);
        const opacity = 0.3; // Adjust the opacity for background color
        doughnutBackgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        doughnutBorderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Full opacity for the border
    });

    const doughnutOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,
        responsive: true
    };

    const downloadChartWithLegend = () => {
        if (doughnutChartContainerRef.current) {
            toPng(doughnutChartContainerRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'footprint_overview.png';
                    link.click();
                })
                .catch((error) => {
                    console.error('Error generating image:', error);
                });
        }
    };

    const downloadFootprintBarChart = () => {
        if (footprintBarChartRef.current) {
            toPng(footprintBarChartRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'environmental_footprint.png';
                    link.click();
                })
                .catch((error) => {
                    console.error('Error generating image:', error);
                });
        }
    };

    const handleGraphChange = (e) => {
        setSelectedGraph(e.target.value);  // Update the selected graph based on the dropdown selection
    };

    const captureChartImage = async () => {
        if (doughnutChartContainerRef.current) {
            try {
                const doughnutDataUrl = await toPng(doughnutChartContainerRef.current, {quality: 1});
                setFootprintDoughnut(doughnutDataUrl);  // Update state with doughnut chart image
            } catch (error) {
                console.error('Error capturing doughnut chart image:', error);
            }
        }
        if (footprintBarChartRef.current) {
            try {
                const barDataUrl = await toPng(footprintBarChartRef.current, {quality: 1});
                setFootprintChart(barDataUrl);  // Update state with bar chart image
            } catch (error) {
                console.error('Error capturing bar chart image:', error);
            }
        }
    };

    // Use useEffect to trigger chart capture when data or selectedLCAStage changes
    useEffect(() => {
        const timer = setTimeout(() => {
            captureChartImage();
        }, 3000); // 1000 milliseconds = 1 second

        // Cleanup the timer if the component unmounts or dependencies change
        return () => clearTimeout(timer);
    }, [scopeData, selectedScope]);

    return (
        <div className="footprint-chart-container">
            <div className="footprint-container">
                <div className="container-header-footprint-overview">
                    <h2
                        onClick={toggleFootprintOverview}
                        onMouseEnter={() => setOverviewIsHovered(true)}
                        onMouseLeave={() => setOverviewIsHovered(false)}
                        style={{
                            cursor: 'pointer',
                            color: overviewIsHovered ? 'grey' : 'black', // Change 'blue' to your desired hover color
                        }}
                    >{selectedText.footprintOverview}</h2>
                </div>
                {isFootprintOverviewOpen && (
                    <div ref={doughnutChartContainerRef} className="doughnut-chart">
                        <div className="download-icon" onClick={downloadChartWithLegend}>
                            <Icon icon="fe:download" style={{color: 'black', cursor: 'pointer'}}/>
                        </div>
                        <Doughnut data={doughnutData} options={doughnutOptions} className="footprint-doughnut"/>

                        <div className="legend-total-container">
                            <div className="gwp-total-container">
                                <div className="gwp-total-footprint">
                                    <span className="gwp-total-label-footprint">Net GWP:</span>
                                    <span className="gwp-total-value-footprint">{totalGWP.toFixed(2)}</span>
                                </div>
                                <div className="gwp-total-footprint">
                                    <span className="gwp-total-label-footprint">Avoided GWP:</span>
                                    <span className="gwp-total-value-footprint">{avoidedGWP.toFixed(2)}</span>
                                </div>
                            </div>
                            <div className="vertical-divider"></div>
                            <div className="legend">
                                {doughnutData.labels.map((label, index) => {
                                    const value = doughnutData.datasets[0].data[index].toFixed(2);
                                    const percentage = isNaN((value / totalGWP) * 100) ? '0' : ((value / totalGWP) * 100).toFixed(2);
                                    return (
                                        <div key={label} className="legend-item">
                                            <span
                                                className="legend-color"
                                                style={{backgroundColor: doughnutData.datasets[0].backgroundColor[index]}}
                                            ></span>
                                            <span className="legend-text">
                                                {label}: {value} [{percentage}%]
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="units-info">All units are calculated in kg CO₂e</div>
                    </div>
                )}
            </div>
            <div className="bar-chart">
                <div className="container-header">
                    <h2
                        onClick={toggleEnvironmentalFootprint}
                        onMouseEnter={() => setEnvironmentalIsHovered(true)}
                        onMouseLeave={() => setEnvironmentalIsHovered(false)}
                        style={{
                            cursor: 'pointer',
                            color: environmentalIsHovered ? 'grey' : 'black', // Change 'blue' to your desired hover color
                        }}
                    >{selectedText.environmentalFootprint}</h2>
                </div>
                {isEnvironmentalFootprintOpen && (
                    <div ref={footprintBarChartRef} className="footprint-barchart">
                        <div className="bar-chart-content">
                            <div className="download-icon" onClick={downloadFootprintBarChart}>
                                <Icon icon="fe:download" style={{color: 'black', cursor: 'pointer'}}/>
                            </div>
                            <select value={selectedGraph} onChange={handleGraphChange} className="scope-select-box">
                                <option value="emissionGraph">{selectedText.emissionGraph}</option>
                                <option value="detailsGraph">{selectedText.detailsGraph}</option>
                            </select>
                            {selectedGraph === 'emissionGraph' && (
                                <select value={selectedScope} onChange={handleLCAStageChange}
                                        className="scope-select-box">
                                    <option value="All">{selectedText.dropdown.all}</option>
                                    <option value="Scope1">{selectedText.scopeDropdown.scope1}</option>
                                    <option value="Scope2">{selectedText.scopeDropdown.scope2}</option>
                                    <option value="Scope3">{selectedText.scopeDropdown.scope3}</option>
                                    <option value="Scope4">{selectedText.scopeDropdown.scope4}</option>
                                    <option value="Avoided">{selectedText.scopeDropdown.avoided}</option>
                                </select>
                            )}
                        </div>
                        {selectedGraph === 'emissionGraph' ? (
                            <Bar data={emission_barData} options={barOptions} className='footprint-barchart'/>
                        ) : (
                            <Bar data={details_barData} options={barOptions} className='footprint-barchart'/>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ScopeFootprint;