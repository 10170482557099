import { useRef, useState, useEffect } from "react";
import { MdDownload, MdUpload } from "react-icons/md";
import { CSVLink } from "react-csv";
import { formatDate } from "../../utils/fleetUtils/formatDate";
import TripFleetFilter from "./TripFilterFleet";
import styles from "./UtilizationComponents.module.css";

const TripEmissions = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [tripsData, setTripsData] = useState([]);
  const [filteredAndSortedTableData, setFilteredAndSortedTableData] = useState([]);
  const [filters, setFilters] = useState({});
  const refTableContainer = useRef(null);

  const tableDataHeaders = ["Asset Number", "Start Address", "End Address", "Fuel Consumed (L)", "Trip Length (km)", "Fuel Economy (L/100km)", "GHG Emissions (kg CO2e)", "Start Date", "Fulfilment Date"];

  // Categories and metrics for filtering
  const categories = [
    { key: "assetNumber", label: "Asset Number" },
    { key: "startAddress", label: "Start Address" },
    { key: "endAddress", label: "End Address" },
  ];

  const metrics = [
    { key: "fuelConsumed", label: "Fuel Consumed (L)" },
    { key: "tripLength", label: "Trip Length (km)" },
    { key: "fuelEconomy", label: "Fuel Economy (L/100km)" },
    { key: "ghgEmissions", label: "GHG Emissions (kg CO2e)" },
  ];

  // Function to fetch trips data from the API using the new helper function
  const fetchTripsData = async () => {
    const url = "https://gaddi-py-api.vrtta.green/trips/monthlytrips?year=2024&month=1";

    const headers = {
      Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcyODYxNTQ3NCwianRpIjoiNGEyYzYyOGMtYmExZS00ZTRhLTgyYTEtNjJiMzM5Y2Q3MzJlIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6IjM3ODYwMmQ5LTBhMmMtNDhiYy05ZTVmLWYwNWY3NDcxMDI4ZiIsIm5iZiI6MTcyODYxNTQ3NCwiY3NyZiI6IjM4YWIzNjlmLTFmZDItNDVkYi1hMGVhLWE0NGJlMzQwODMyNCJ9.PGLuIHSw54RnVjv1ZIXwk2ObddRsjbjzGQ_EZjvNyhg",
      "api-key": "9c44d472-420f-4c3b-8ccb-54c5654cb53e",
    };

    try {
      const response = await fetch(url, { method: "GET", headers: headers });

      if (!response.ok) {
        throw new Error("Failed to fetch trips data");
      }

      const data = await response.json();

      // Get only the first 30 trips
      const first30Trips = data.trips.slice(0, 30);

      setTripsData(first30Trips);
      setFilteredAndSortedTableData(first30Trips);
    } catch (error) {
      console.error("Error fetching trips data:", error);
    }
  };

  // Filter trips based on selected filters
  const applyFilters = (filters) => {
    let filteredData = [...tripsData];

    categories.forEach((category) => {
      if (filters[category.key] && filters[category.key].length > 0) {
        filteredData = filteredData.filter((trip) => filters[category.key].includes(trip[category.key]));
      }
    });

    filteredData = filteredData.filter((route) => {
      return metrics.every((metric) => {
        const key = metric.key;
        if (filters[key]) {
          const { option, value } = filters[key];
          if (!option) return true;

          switch (option) {
            case "top10":
              return route[key] >= value;
            case "bottom10":
              return route[key] <= value;
            case "gte":
              return route[key] >= value;
            case "lte":
              return route[key] <= value;
            default:
              return true;
          }
        }
        return true;
      });
    });

    setFilteredAndSortedTableData(filteredData);
  };

  // Prepare CSV data including totals and averages
  const calculateTotalsAndAverages = () => {
    const totals = {
      fuelConsumed: 0,
      tripLength: 0,
      fuelEconomy: 0,
      ghgEmissions: 0,
    };

    filteredAndSortedTableData.forEach((item) => {
      totals.fuelConsumed += Number(item.fuelConsumed);
      totals.tripLength += Number(item.tripLength);
      totals.fuelEconomy += Number(item.fuelEconomy);
      totals.ghgEmissions += Number(item.ghgEmissions);
    });

    const averages = {
      fuelConsumed: totals.fuelConsumed / filteredAndSortedTableData.length || 0,
      tripLength: totals.tripLength / filteredAndSortedTableData.length || 0,
      fuelEconomy: totals.fuelEconomy / filteredAndSortedTableData.length || 0,
      ghgEmissions: totals.ghgEmissions / filteredAndSortedTableData.length || 0,
    };

    return { totals, averages };
  };

  const { totals, averages } = calculateTotalsAndAverages();

  useEffect(() => {
    calculateTotalsAndAverages();
  }, [filteredAndSortedTableData]);
  // CSV Data
  const csvData = [
    {
      "Asset Number": "Total",
      "Fuel Consumed (L)": totals.fuelConsumed.toFixed(2),
      "Trip Length (km)": totals.tripLength.toFixed(2),
      "Fuel Economy (L/100km)": totals.fuelEconomy.toFixed(2),
      "GHG Emissions (kg CO2e)": totals.ghgEmissions.toFixed(2),
    },
    {
      "Asset Number": "Average",
      "Fuel Consumed (L)": averages.fuelConsumed.toFixed(2),
      "Trip Length (km)": averages.tripLength.toFixed(2),
      "Fuel Economy (L/100km)": averages.fuelEconomy.toFixed(2),
      "GHG Emissions (kg CO2e)": averages.ghgEmissions.toFixed(2),
    },
    ...filteredAndSortedTableData.map((item) => ({
      "Asset Number": item.assetNumber,
      "Start Address": item.startAddress,
      "End Address": item.endAddress,
      "Fuel Consumed (L)": Number(item.fuelConsumed).toFixed(2),
      "Trip Length (km)": Number(item.tripLength).toFixed(2),
      "Fuel Economy (L/100km)": Number(item.fuelEconomy).toFixed(2),
      "GHG Emissions (kg CO2e)": Number(item.ghgEmissions).toFixed(2),
      "Fulfilment Date": formatDate(new Date(item.fulfilmentDate)),
    })),
  ];

  const handleFileUpload = (e) => {
    console.log(e.target.files);
  };

  useEffect(() => {
    fetchTripsData();
  }, []);

  return (
    <div className="process-env-container">
      <div className="process-table">
        <header className={styles["table-header"]}>
          <div className={styles.sectionTitle} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
            Trip Emissions
          </div>
          <div className={styles.rightSection}>
            {isAccordionOpen && (
              <div
                style={{
                  display: "inline-flex",
                  gap: "10px",
                  alignItems: "center",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {" "}
                <TripFleetFilter trips={tripsData} filters={filters} onFilterChange={applyFilters} categories={categories} metrics={metrics} />
                <label htmlFor="csvInput" className={styles["uploadButton"]}>
                  <MdUpload size={"25px"} />
                </label>
                <input id="csvInput" type="file" accept=".csv" onChange={handleFileUpload} style={{ display: "none" }} />
                <CSVLink
                  className={styles["exportButton"]}
                  filename={`tripEmissions${formatDate(new Date())}.csv`}
                  data={csvData}
                  headers={tableDataHeaders.map((header) => ({
                    label: header,
                    key: header,
                  }))}
                >
                  <MdDownload size={"25px"} />
                </CSVLink>
              </div>
            )}
          </div>
        </header>
        <div className={`${styles.content1} ${styles.content} ${isAccordionOpen ? styles.open : ""}`}>
          <div ref={refTableContainer} className={`${styles["tripEmissionsTableContainer"]}`}>
            <table id="trip-table" className={`${styles["tripEmissionsTable"]} `}>
              <thead className="thead-dark">
                <tr>
                  <td colSpan="2"></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>{totals.fuelConsumed.toFixed(2)}</td>
                  <td>{totals.tripLength.toFixed(2)}</td>
                  <td>{totals.fuelEconomy.toFixed(2)}</td>
                  <td>{totals.ghgEmissions.toFixed(2)}</td>
                </tr>

                <tr>
                  <td colSpan="2"></td>
                  <td>
                    <strong>Average</strong>
                  </td>
                  <td>{averages.fuelConsumed.toFixed(2)}</td>
                  <td>{averages.tripLength.toFixed(2)}</td>
                  <td>{averages.fuelEconomy.toFixed(2)}</td>
                  <td>{averages.ghgEmissions.toFixed(2)}</td>
                </tr>
                <tr>
                  {tableDataHeaders.map((header, index) => (
                    <th key={index} scope={index === 0 ? "col" : "row"}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredAndSortedTableData.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>{item.assetNumber}</td>
                    <td style={{ minWidth: "150px" }}>
                      <a style={{ color: "black", textDecoration: "none" }} href={`https://www.google.com/maps/search/?api=1&query=${item.startLat},${item.startLong}`} target="_blank" rel="noopener noreferrer">
                        {item.startAddress}
                      </a>
                    </td>
                    <td style={{ minWidth: "150px" }}>
                      <a style={{ color: "black", textDecoration: "none" }} href={`https://www.google.com/maps/search/?api=1&query=${item.endLat},${item.endLong}`} target="_blank" rel="noopener noreferrer">
                        {item.endAddress}
                      </a>
                    </td>
                    <td>{Number(item.fuelConsumed).toFixed(2)}</td>
                    <td>{Number(item.tripLength).toFixed(2)}</td>
                    <td>{Number(item.fuelEconomy).toFixed(2)}</td>
                    <td>{Number(item.ghgEmissions).toFixed(2)}</td>
                    <td>{formatDate(new Date(item.startDate))}</td>
                    <td>{formatDate(new Date(item.fulfilmentDate))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripEmissions;
