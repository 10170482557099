import React, { useMemo } from "react";
import styles from "./UtilizationComponents.module.css";
import { Bar } from "react-chartjs-2";
import { memo } from "react";

const BarChartComponent = memo(({ data, title, xLabel, yLabel, backgroundColor, hoverBackgroundColor }) => {
  const chartData = useMemo(() => {
    return {
      labels: data.map((item) => item.range),
      datasets: [
        {
          label: yLabel,
          data: data.map((item) => item.count),
          backgroundColor,
          hoverBackgroundColor,
          borderWidth: 1,
        },
      ],
    };
  }, [data, yLabel, backgroundColor, hoverBackgroundColor]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        padding: {
          top: 10,
          bottom: 10,
        },
        font: {
          size: 14,
          weight: "normal",
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel,
          font: {
            weight: "normal",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel,
          font: {
            weight: "normal",
          },
        },
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ position: "relative", height: "100%", width: "100%", minHeight: "200px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
});

const createBarChartData = (data, key, rangeStep, numRanges = 8) => {
  let ranges = {};

  for (let i = 0; i < numRanges - 1; i++) {
    const rangeStart = i * rangeStep;
    const rangeEnd = rangeStart + rangeStep;
    const rangeKey = `${rangeStart}-${rangeEnd}`;
    ranges[rangeKey] = 0;
  }

  const lastRangeKey = `${(numRanges - 1) * rangeStep}+`;
  ranges[lastRangeKey] = 0;

  data.forEach((item) => {
    const value = parseFloat(item[key]);
    const range = Math.floor(value / rangeStep) * rangeStep;
    const rangeKey = `${range}-${range + rangeStep}`;

    if (ranges[rangeKey] !== undefined) {
      ranges[rangeKey]++;
    } else if (value >= (numRanges - 1) * rangeStep) {
      ranges[lastRangeKey]++;
    }
  });

  return Object.keys(ranges)
    .sort((a, b) => (a.includes("+") ? 1 : parseFloat(a.split("-")[0]) - parseFloat(b.split("-")[0])))
    .map((rangeKey) => ({
      range: rangeKey,
      count: ranges[rangeKey],
    }));
};

function FleetMetrics({ tableData, idleHours, averages }) {
  // Default to empty array if data is not provided
  const tableDataParsed = tableData || [];

  // Create chart data
  const fuelEfficiencyData = useMemo(() => createBarChartData(tableDataParsed, "Fuel economy (L/100km)", 10), [tableDataParsed]);
  const ghgEmissionsData = useMemo(() => createBarChartData(tableDataParsed, "GHG per km (kg CO2-eq/km)", 0.5), [tableDataParsed]);
  const idlingHoursData = useMemo(() => createBarChartData(tableDataParsed, "Idling time (%)", 10), [tableDataParsed]);

  return (
    <div className={styles["dashboardSummaryContainer"]}>
      {/* Bar chart for Average Economy */}
      <div id="efficiencyChart" className={styles["barChartContainer"]}>
        <div className={styles["barChartTitle"]}>Fuel Efficiency</div>
        <div className={styles["barChart"]}>
          <BarChartComponent
            data={fuelEfficiencyData}
            title={["Avg Efficiency", `${+(+averages["Average Economy"]).toFixed(2)} L/100km`]} // Updated to array for multi-line title
            xLabel="Fuel Economy (L/100km)"
            yLabel="Number of Vehicles"
            backgroundColor="#6b9f6c"
            hoverBackgroundColor="rgba(107, 159, 108, 0.6)"
          />
        </div>
      </div>

      {/* Bar chart for Average GHG */}
      <div id="emissionsChart" className={styles["barChartContainer"]}>
        <div className={styles["barChartTitle"]}>GHG Emissions</div>
        <div className={styles["barChart"]}>
          <BarChartComponent data={ghgEmissionsData} title={["Avg Emissions", `${+(+averages["Average GHG"]).toFixed(2)} CO\u2082 e/km`]} xLabel="GHG per km (kg CO2-eq/km)" yLabel="Number of Vehicles" backgroundColor="#6b9f6c" hoverBackgroundColor="rgba(107, 159, 108, 0.6)" />
        </div>
      </div>
      {/* Bar chart for Average Idling */}
      <div id="idlingChart" className={styles["barChartContainer"]}>
        <div className={styles["barChartTitle"]}>Idling Hours</div>
        <div className={styles["barChart"]}>
          <BarChartComponent data={idlingHoursData} title={["Avg Idle Hours", `${idleHours} hrs`]} xLabel="Idling time (%)" yLabel="Number of Vehicles" backgroundColor="#6b9f6c" hoverBackgroundColor="rgba(107, 159, 108, 0.6)" />
        </div>
      </div>
    </div>
  );
}

export default FleetMetrics;
