import React, { useState, useRef } from "react";
import styles from "./UtilizationComponents.module.css";
import { Scatter } from "react-chartjs-2";

// import { Chart as ChartJS, registerables } from "chart.js";
// import annotationPlugin from "chartjs-plugin-annotation";

import { FaDownload } from "react-icons/fa6";

function PerformanceByYear({ chartDataArray, uniqueMakesArray, getBackgroundColor, chartRefs }) {
  const [showDataLabels, setShowDataLabels] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const handleCheckboxChange = (event) => {
    setShowDataLabels(event.target.checked);
  };

  const downloadChart = (index) => {
    const chart = chartRefs.current[index];
    const originalCanvas = chart.canvas;

    const tempCanvas = document.createElement("canvas");
    const ctx = tempCanvas.getContext("2d");

    tempCanvas.width = originalCanvas.width;
    tempCanvas.height = originalCanvas.height;

    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

    ctx.drawImage(originalCanvas, 0, 0);

    const link = document.createElement("a");
    link.download = `chart_${index + 1}.png`;
    link.href = tempCanvas.toDataURL("image/png", 1);
    link.click();
  };

  return (
    <div className="process-env-container">
      <div className="process-table">
        <header className={styles["table-header"]}>
          <div className={styles.sectionTitle} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
            Performance by Year
          </div>
          <div className={styles.rightSection}>
            <div className={`form-check form-switch ${styles["show_data_label_names"]}`}>
              {isAccordionOpen && (
                <div className={`form-check form-switch ${styles["show_data_label_names"]}`} style={{ marginTop: "-10px" }} onClick={(e) => e.stopPropagation()}>
                  <input type="checkbox" id="data_label_check_box" onChange={(event) => handleCheckboxChange(event)} />
                  <label htmlFor="data_label_check_box">Show Asset Name</label>
                </div>
              )}
            </div>
          </div>
        </header>
        <div className={`${styles.content1} ${styles.content} ${isAccordionOpen ? styles.open : ""}`}>
          {chartDataArray.slice(2).map((data, index) => (
            <div id={`pft-content${index + 2}`} className={styles["graph-pair-container"]} key={index + 2}>
              {/* First chart of the pair */}
              {(index == 0 || index == 2) && (
                <div className={`d-flex justify-content-center ${styles["chart-options-index"]} ${styles["chart-options"]}`}>
                  <div className={styles["graphs_block"]}>
                    <button type="button" style={{ cursor: "pointer" }} onClick={() => downloadChart(index)} className={`${styles["download-button"]}`}>
                      <FaDownload size="20px" />
                    </button>
                    <Scatter
                      ref={(el) => (chartRefs.current[index + 2] = el)}
                      data={chartDataArray[index + 2].datasets}
                      options={{
                        ...chartDataArray[index + 2].options,
                        animation: {
                          duration: 1000,
                          easing: "easeOutQuart",
                        },
                        tooltips: {
                          enabled: true,
                          mode: "index",
                          intersect: false,
                        },
                        plugins: {
                          ...chartDataArray[index + 2].options.plugins,
                          datalabels: {
                            ...chartDataArray[index + 1].options.plugins.datalabels,
                            display: showDataLabels,
                          },
                        },
                      }}
                      className={`${styles["scatter-chart"]} ${styles["modern-chart"]}`}
                    />
                  </div>
                </div>
              )}

              {/* Second chart of the pair, if it exists */}
              {(index == 0 || index == 2) && (
                <div className={`d-flex justify-content-center ${styles["chart-options-index"]} ${styles["chart-options"]}`}>
                  <div className={styles["graphs_block"]}>
                    <button type="button" style={{ cursor: "pointer" }} onClick={() => downloadChart(index)} className={`${styles["download-button"]}`}>
                      <FaDownload size="20px" />
                    </button>
                    <Scatter
                      ref={(el) => (chartRefs.current[index + 3] = el)}
                      data={chartDataArray[index + 3].datasets}
                      options={{
                        ...chartDataArray[index + 3].options,
                        animation: {
                          duration: 1000,
                          easing: "easeOutQuart",
                        },
                        tooltips: {
                          enabled: true,
                          mode: "index",
                          intersect: false,
                        },
                        plugins: {
                          ...chartDataArray[index + 3].options.plugins,
                          datalabels: {
                            ...chartDataArray[index + 1].options.plugins.datalabels,
                            display: showDataLabels,
                          },
                        },
                      }}
                      className={`${styles["scatter-chart"]} ${styles["modern-chart"]}`}
                    />
                  </div>
                </div>
              )}
              {/* Legend Section */}
              {(index == 0 || index == 2) && (
                <div className={styles["chart-legend-box"]}>
                  <>
                    {uniqueMakesArray.map((make, idx) => (
                      <div key={idx} className={`${styles["point-container"]} d-flex flex-row`}>
                        <div className={styles.point} style={{ backgroundColor: getBackgroundColor(make), borderRadius: "4px", opacity: 0.7 }}></div>
                        <div className={styles.text}>{make}</div>
                      </div>
                    ))}
                    <div className={`${styles["point-container"]} d-flex flex-row`}>
                      <div className={styles.point} style={{ backgroundColor: "#6c6c6c", borderRadius: "4px", opacity: 0.7 }}></div>
                      <div className={styles.text}>OTHER</div>
                    </div>
                  </>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PerformanceByYear;
