import { jsPDF } from "jspdf";

import html2canvas from "html2canvas";
import { formatDate } from "./formatDate";

// Importing images
const backgroundP1 = "/Fleet Report/backgroundP1.png";
const background1 = "/Fleet Report/background1.png";
const background2 = "/Fleet Report/background2.png";
const triangle = "/Fleet Report/triangle.png";
const card1 = "/Fleet Report/card1.png";
const card2 = "/Fleet Report/card2.png";
const card3 = "/Fleet Report/card3.png";
const card4 = "/Fleet Report/card4.png";
const fleet1 = "/Fleet Report/fleet1.jpg";
const fleet2 = "/Fleet Report/fleet2.jpg";
const fleet3 = "/Fleet Report/fleet3.jpg";
const gaddiLogo = "/Fleet Report/gaddilogo.png";
const metrics1 = "/Fleet Report/metrics1.png";
const metrics2 = "/Fleet Report/metrics2.png";
const metrics3 = "/Fleet Report/metrics3.png";
const truckNature = "/Fleet Report/truckNature.png";
const vrttaLogo = "/Fleet Report/vrttalogo.png";

// Initialize jsPDF document
const doc = new jsPDF({
  orientation: "landscape",
  unit: "pt",
  format: [1277, 720],
  putOnlyUsedFonts: true,
  floatPrecision: 16,
});

doc.deletePage(1);

// Load and draw background image
function loadImage(src) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = src;
  });
}

async function drawBackground(image) {
  const img = await loadImage(image);
  doc.addImage(img, "PNG", 0, 0, 1277, 720);
}
// Helper function to create an image with rounded corners
function createRoundedImage(imgSrc, width, height, radius) {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;

    // Draw rounded rectangle
    ctx.beginPath();
    ctx.moveTo(radius, 0);
    ctx.lineTo(width - radius, 0);
    ctx.quadraticCurveTo(width, 0, width, radius);
    ctx.lineTo(width, height - radius);
    ctx.quadraticCurveTo(width, height, width - radius, height);
    ctx.lineTo(radius, height);
    ctx.quadraticCurveTo(0, height, 0, height - radius);
    ctx.lineTo(0, radius);
    ctx.quadraticCurveTo(0, 0, radius, 0);
    ctx.closePath();
    ctx.clip(); // Clip to the rounded rectangle

    // Create the image
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL("image/png"));
    };
    img.src = imgSrc;
  });
}

// Helper function to add content by element ID
async function addContentById(id, xOffset, yOffset, width, height) {
  const element = document.getElementById(id);
  if (!element) {
    console.error(`Element with ID "${id}" not found.`);
    return;
  }

  // Temporarily hide buttons or interactive elements
  const buttons = element.querySelectorAll("button, .btn");
  buttons.forEach((button) => (button.style.display = "none"));

  // Capture the element as an image
  const canvas = await html2canvas(element, { useCORS: true });
  const imgData = canvas.toDataURL("image/png");

  // Define corner radius
  const cornerRadius = 15; // Adjust as needed

  // Create rounded image data URL
  const roundedImgData = await createRoundedImage(imgData, width, height, cornerRadius);

  // Draw the rounded rectangle first (this will be the mask)
  doc.setFillColor(255, 255, 255); // Set fill color (white)
  doc.roundedRect(xOffset, yOffset, width, height, cornerRadius, cornerRadius, "F");

  // Add the rounded image over the rounded rectangle
  doc.addImage(roundedImgData, "PNG", xOffset, yOffset, width, height);

  // Move the yOffset for the next element
  yOffset += height + 10;

  // Restore the display of buttons
  buttons.forEach((button) => (button.style.display = ""));
}

// ====================================================
// PAGE 1: Cover Page
// ====================================================
async function addPage1() {
  // Add new page with custom dimensions
  doc.addPage();

  // Draw background image
  const background = await loadImage(backgroundP1);
  doc.addImage(background, "PNG", 0, 0, 909, 720);

  // Add truck nature image
  const truckImg = await loadImage(truckNature);
  doc.addImage(truckImg, "PNG", 494, 0, 783, 720);

  // Add GaDDi logo
  const logo = await loadImage(gaddiLogo);
  doc.addImage(logo, "PNG", 77, 46, 200, 53.81);

  const triangleImg = await loadImage(triangle);
  doc.addImage(triangleImg, "PNG", 560.5, 417.5, 348.85, 302.5);

  // Add text content
  doc.setFont("helvetica", "normal");
  doc.setTextColor(0, 0, 0);

  // Company Name
  doc.setFontSize(48);
  doc.setFont("helvetica", "bold");
  doc.text("Company Name", 60, 180);

  // Fleet Sustainability Report
  doc.setFontSize(28);
  doc.setFont("helvetica", "normal");
  doc.text("Fleet Sustainability Report", 60, 300);

  // Q2 2024
  doc.setFont("helvetica", "bold");
  doc.text("2023", 450, 300);
  doc.setFont("helvetica", "normal");

  // Description text
  doc.setFontSize(18);
  const description = "Comprehensive insights into fleet performance, including fuel\nefficiency, environmental impact, and operational improvements for\nsmarter, sustainable fleet management.";
  doc.text(description, 60, 350);

  // Reported and verified by text
  doc.text("Reported and", 242, 610);
  doc.text("verified by", 252, 630);

  // Add Vrtta logo
  const vrtta = await loadImage(vrttaLogo);
  doc.addImage(vrtta, "PNG", 222, 646, 150, 51.14);
}

// ====================================================
// PAGE 2: Content Overview
// ====================================================
async function addPage2() {
  doc.addPage();
  doc.text("Page 2 Content", 50, 50);
}

// ====================================================
// PAGE 3: Introduction to Key Metrics
// ====================================================
async function addPage3() {
  doc.addPage();

  // Draw background image (light beige with waves)
  const background = await loadImage(background1);
  doc.addImage(background, "PNG", 0, 0, 1277, 720);

  // Add text content
  doc.setTextColor(0, 0, 0);

  // Add "Introduction to Key Metrics" header
  doc.setFontSize(28);
  doc.setFont("helvetica", "bold");
  doc.text("Introduction to Key Metrics", 60, 60);

  // Add "Q2 2024" on the right
  doc.text("Q2 2024", 1080, 60);

  // Add the three images
  const metrics1Image = await loadImage(metrics1);
  const metrics2Image = await loadImage(metrics2);
  const metrics3Image = await loadImage(metrics3);

  // Calculate image dimensions (maintaining aspect ratio)
  const imageWidth = 300;
  const imageHeight = 200;

  // Add first image (truck interior)
  doc.addImage(metrics1Image, "PNG", 90, 125, imageWidth, imageHeight);

  // Add second image (truck from behind)
  doc.addImage(metrics2Image, "PNG", 480, 125, imageWidth, imageHeight);

  // Add third image (maintenance)
  doc.addImage(metrics3Image, "PNG", 880, 125, imageWidth, imageHeight);

  // Add "Importance of Key Metrics" heading
  doc.setFontSize(24);
  doc.text("Importance of Key Metrics", 90, 360);
  // Add importance of metrics description
  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  const importanceText = "Understanding key metrics in fleet\nmanagement is essential for\noptimizing performance, reducing\ncosts, and enhancing operational\nefficiency across all fleet assets and\nactivities.";
  doc.text(importanceText, 90, 400);

  // Add "Performance Metrics" heading
  doc.setFontSize(24);
  doc.setFont("helvetica", "bold");
  doc.text("Performance Metrics", 520, 360);
  // Add performance metrics description
  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  const metricsText = "Key metrics include distance\ntravelled, fuel consumption, engine\nrun time, and GHG emissions, which\ncollectively provide insights into fleet\nefficiency and environmental impact.";
  doc.text(metricsText, 480, 400);

  // Add "Data-Driven Decision Making" heading
  doc.setFontSize(24);
  doc.setFont("helvetica", "bold");
  doc.text("Data-Driven Decision Making", 870, 360);
  // Add decision making description
  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  const decisionText = "Utilizing these metrics enables data-\ndriven decision making, allowing\nfleet managers to identify trends, set\nbenchmarks, and implement\nstrategies for continuous\nimprovement in fleet operations.";
  doc.text(decisionText, 880, 400);
}

// ====================================================
// PAGE 4: Distance, Environment, and Fuel
// ====================================================
async function addPage4(distanceTravelled, fuelConsumption, ghgEmissions) {
  doc.addPage();
  // Draw background image (light beige with waves)
  const background = await loadImage(background1);
  doc.addImage(background, "PNG", 0, 0, 1277, 720);

  // Add text content
  doc.setTextColor(0, 0, 0);

  // Add header text and date
  doc.setFontSize(32);
  doc.setFont("helvetica", "bold");
  doc.text("Distance, Environment and Fuel", 60, 60);
  doc.text("2023", 1080, 60);

  // Card 1 - Total Distance Overview (Pink/Purple gradient)
  const card1Image = await loadImage(card1);
  doc.addImage(card1Image, "PNG", 70, 110, 325, 550);

  // Card 2 - Environmental Impact Assessment (Blue/Orange gradient)
  const card2Image = await loadImage(card2);
  doc.addImage(card2Image, "PNG", 435, 110, 420, 275);

  // Card 3 - Fuel Consumption Insights (Pink gradient)
  doc.addImage(card1Image, "PNG", 895, 110, 335, 550);

  // Add numbers and text for each card
  // Card 1
  doc.setFontSize(48);
  doc.setFont("helvetica", "bold");
  doc.text("01", 90, 180);

  doc.setFontSize(24);
  doc.text("Total Distance Overview", 90, 260);

  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  doc.text("The fleet has collectively", 90, 380);
  doc.setFont("helvetica", "bold");
  doc.text(`travelled ${distanceTravelled} km,`, 90, 405);
  doc.setFont("helvetica", "normal");
  doc.text("highlighting the extensive", 90, 430);
  doc.text("operational reach and", 90, 455);
  doc.text("efficiency of the assets", 90, 480);
  doc.text("in service.", 90, 505);

  // Card 2
  doc.setFontSize(48);
  doc.setFont("helvetica", "bold");
  doc.text("02", 450, 170);

  doc.setFontSize(24);
  doc.text("Environmental Impact Assessment", 450, 220);

  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  doc.text("The fleet's performance results in", 450, 260);
  doc.setFont("helvetica", "bold");
  doc.text(`${ghgEmissions} tonnes of GHG emissions,`, 450, 285);
  doc.setFont("helvetica", "normal");
  doc.text("emphasizing the need for strategies to ", 450, 310);
  doc.text("reduce environmental impact with an", 450, 335);
  doc.text("optimal operational effectiveness.", 450, 360);

  // Card 3
  doc.setFontSize(48);
  doc.setFont("helvetica", "bold");
  doc.text("03", 910, 180);

  doc.setFontSize(24);
  doc.text("Fuel Consumption Insights", 910, 260);

  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  doc.text("With a total fuel consumption of", 910, 380);
  doc.setFont("helvetica", "bold");
  doc.text(`${fuelConsumption} liters,`, 910, 405);
  doc.setFont("helvetica", "normal");
  doc.text("analyzing fuel usage patterns", 910, 430);
  doc.text("can lead to improved", 910, 455);
  doc.text("efficiency and cost savings", 910, 480);
  doc.text("in fleet operations.", 910, 505);

  // Add emissions chart
  // The chart will be added at coordinates below the second card
  await addContentById("emissionsChart", 435, 415, 420, 245);
}

// ====================================================
// PAGE 5: Efficiency and Idle Time
// ====================================================
async function addPage5(engineRunTime, engineIdlingTime, fuelEconomy) {
  doc.addPage();
  // Draw background image (light beige with waves)
  const background = await loadImage(background1);
  doc.addImage(background, "PNG", 0, 0, 1277, 720);

  // Add text content
  doc.setTextColor(0, 0, 0);

  // Add header text and date
  doc.setFontSize(32);
  doc.setFont("helvetica", "bold");
  doc.text("Efficiency and Idle Time", 60, 60);
  doc.text("2023", 1080, 60);

  // Card 1 - Total Distance Overview (Pink/Purple gradient)
  const card1Image = await loadImage(card1);
  doc.addImage(card1Image, "PNG", 70, 110, 325, 550);

  // Card 3 - Fuel Consumption Insights (Pink gradient)
  doc.addImage(card1Image, "PNG", 895, 110, 335, 550);

  // Add numbers and text for each card
  // Card 4
  doc.setFontSize(48);
  doc.setFont("helvetica", "bold");
  doc.text("04", 90, 180);

  doc.setFontSize(24);
  doc.text("Engine Run & Idle Time", 90, 260);
  doc.text("Significance", 90, 290);

  doc.setFontSize(20);
  doc.setFont("helvetica", "normal");
  doc.text("The fleet's total engine", 90, 350);
  doc.setFont("helvetica", "bold");
  doc.text(`run time of ${engineRunTime} hours,`, 90, 375);
  doc.setFont("helvetica", "normal");
  doc.text("indicates the operational", 90, 400);
  doc.text("efficiency and utilization of", 90, 425);
  doc.text("assets, providing a benchmark", 90, 450);
  doc.text("for performance evaluation", 90, 475);
  doc.text("and maintenance planning.", 90, 500);
  doc.setFont("helvetica", "bold");
  doc.text(`With ${engineIdlingTime} hours of idling`, 90, 535);
  doc.setFont("helvetica", "normal");
  doc.text("time, reducing unnecessary", 90, 560);
  doc.text("idling can lead to significant", 90, 585);
  doc.text("cost savings and emissions.", 90, 610);

  // Card 5
  doc.setFontSize(48);
  doc.setFont("helvetica", "bold");
  doc.text("05", 910, 180);

  doc.setFontSize(24);
  doc.text("Energy Efficiency", 910, 260);

  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.text(`A fuel economy of ${fuelEconomy} L/100km`, 910, 380);
  doc.setFont("helvetica", "normal");
  doc.text("highlights the fleet's efficiency. ", 910, 405);
  doc.text("Optimizing fuel usage reduces", 910, 430);
  doc.text("costs and emissions,", 910, 455);
  doc.text("promoting sustainability and ", 910, 480);
  doc.text("better performance.", 910, 505);

  await addContentById("idlingChart", 420, 110, 450, 250);
  await addContentById("efficiencyChart", 420, 400, 450, 250);
}
// ====================================================
// PAGE 6: Global Asset Overview
// ====================================================
async function addPage6() {
  doc.addPage();
  const background = await loadImage(background2);
  doc.addImage(background, "PNG", 0, 0, 1277, 720);

  doc.setFontSize(28);
  doc.setFont("helvetica", "bold");
  doc.text("Global Asset Overview", 500, 85);
  doc.text("2023", 1110, 85);

  const fleet1Img = await loadImage(fleet1);
  doc.addImage(fleet1Img, "PNG", 0, 0, 446, 720);

  const card3Image = await loadImage(card3);
  doc.addImage(card3Image, "PNG", 500, 110, 723, 170);
  doc.addImage(card3Image, "PNG", 500, 305, 723, 170);
  doc.addImage(card3Image, "PNG", 500, 500, 723, 170);

  doc.setFontSize(24);
  doc.text("Asset Utilization Metrics", 525, 150);
  doc.text("Performance Indicators", 525, 345);
  doc.text("Environmental Impact Data", 525, 540);

  doc.setFontSize(18);
  doc.setFont("helvetica", "normal");
  // Card 1 - Asset Utilization Metrics
  doc.text("The Asset Table provides a comprehensive overview of fleet assets, including the", 525, 185);
  doc.text("number of tractors and their statistics during the time period, essential for", 525, 210);
  doc.text("assessing operational efficiency and resource allocation.", 525, 235);

  // Card 2 - Performance Indicators
  doc.text("Key performance indicators such as fuel economy and energy usage are", 525, 375);
  doc.text("highlighted, enabling fleet managers to identify trends and areas for improvement", 525, 400);
  doc.text(" in asset performance.", 525, 425);

  // Card 3 - Environmental Impact Data
  doc.text("The table includes critical data on GHG emissions and potential reductions,", 525, 575);
  doc.text("facilitating informed decision-making for sustainability initiatives and compliance", 525, 600);
  doc.text("with environmental standards.", 525, 625);
}

// ====================================================
// PAGE 7: Fleet Assets
// ====================================================
async function addPage7() {
  doc.addPage();
  doc.setFontSize(32);
  doc.setFont("helvetica", "bold");
  doc.text("Fleet Assets", 60, 60);
  doc.text("2023", 1080, 60);

  await addContentById("fleet-table", 0, 100, 1277, 520);
}

async function addPage8() {
  doc.addPage();
  await drawBackground(background1);
  doc.text("Page 8 Content", 50, 50);
}

async function addPage9() {
  doc.addPage();
  await drawBackground(background1);
  doc.text("Page 9 Content", 50, 50);
}

// ====================================================
// PAGE 10: Fuel Consumption and Emissions Reduction
// ====================================================
async function addPage10() {
  doc.addPage();
  await drawBackground(background2);

  const card3Image = await loadImage(card3);
  doc.addImage(card3Image, "PNG", 50, 300, 530, 380);

  doc.setFontSize(32);
  doc.setFont("helvetica", "bold");
  doc.text("2023", 1080, 60);

  doc.setFontSize(40);
  doc.text("Fuel Consumption and ", 60, 150);
  doc.text("Emissions Reduction", 60, 200);

  doc.setFontSize(48);
  doc.text("06", 90, 350);

  doc.setFontSize(24);
  doc.text("Potential Fuel Reductions", 90, 400);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(20);
  const reductions = "If all vehicles in the low-performing zone were optimized to meet the mean fuel economy of the fleet, you would achieve potential reductions of: \n  •  Fuel Consumption: 5191 L \n  •  CO2 Emissions: 14016 kg CO2e";
  const x1 = 90;
  const y1 = 450;
  const maxWidth1 = 450;
  const lines1 = doc.splitTextToSize(reductions, maxWidth1);
  doc.text(lines1, x1, y1);

  doc.setFontSize(16);
  const recommendation = "It is recommended to install aerodynamic kits, along with implementing regular maintenance and driver training programs focused on fuel-efficient practices, to achieve significant reductions in fuel consumption and CO2 emissions.";
  const x2 = 90;
  const y2 = 580;
  const maxWidth2 = 450;
  const lines2 = doc.splitTextToSize(recommendation, maxWidth2);
  doc.text(lines2, x2, y2);

  await addContentById("fuel-utilization", 635, 110, 550, 380);

  doc.setFontSize(20);
  const text = "The fleet has an average fuel economy of 32.22 L/100 km, with 11 vehicles operating in the optimal zone, 1 vehicle in the low-performing zone, and 1 vehicle in the top-performing zone.";
  const x3 = 650;
  const y3 = 550;
  const maxWidth3 = 530;
  const lines3 = doc.splitTextToSize(text, maxWidth3);
  doc.text(lines3, x3, y3);
}

// ====================================================
// PAGE 11: Idling Utilization and Emissions Reduction
// ====================================================
async function addPage11() {
  doc.addPage();
  await drawBackground(background2);

  await addContentById("idling-utilization", 50, 110, 550, 380);

  const card3Image = await loadImage(card3);
  doc.addImage(card3Image, "PNG", 670, 300, 530, 380);

  // Header information
  doc.setFontSize(32);
  doc.setFont("helvetica", "bold");
  doc.text("2023", 1080, 60);

  doc.setFontSize(40);
  doc.text("Idling Time and", 685, 150);
  doc.text("Emissions Reduction", 685, 200);

  // Section title and content
  doc.setFontSize(48);
  doc.text("07", 710, 350);

  doc.setFontSize(24);
  doc.text("Potential Idling Reductions", 710, 400);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(20);

  const reductions = "If the vehicle in the low and high priority zones were optimized to eliminate idling, you would achieve potential reductions of:\n  •  Fuel Consumption: 2150 L\n  •  CO2 Emissions: 5,805 kg CO2e";
  const x1 = 710;
  const y1 = 450;
  const maxWidth1 = 450;
  const lines1 = doc.splitTextToSize(reductions, maxWidth1);
  doc.text(lines1, x1, y1);

  doc.setFontSize(16);
  const recommendation = "It is recommended to implement strategies to minimize idling time, such as utilizing engine stop-start technology, anti-idling equipment, and driver awareness programs, to achieve significant reductions in fuel consumption and CO2 emissions.";
  const x2 = 710;
  const y2 = 580;
  const maxWidth2 = 450;
  const lines2 = doc.splitTextToSize(recommendation, maxWidth2);
  doc.text(lines2, x2, y2);

  doc.setFontSize(20);
  const text = "The fleet has an average idling rate of 19.1%, with 11 vehicles operating in the optimal zone, no vehicles in the low priority zone, and 1 vehicle in the high priority zone.";
  const x3 = 75;
  const y3 = 550;
  const maxWidth3 = 530;
  const lines3 = doc.splitTextToSize(text, maxWidth3);
  doc.text(lines3, x3, y3);
}

// Generate the report
async function FleetSustainabilityReport(summary) {
  const distanceTravelled = summary["Distance Travelled"].toLocaleString("en-US", { maximumFractionDigits: 0 });
  const fuelConsumption = summary["Fuel Consumption"].toLocaleString("en-US", { maximumFractionDigits: 0 });
  const engineRunTime = summary["Engine run time"].toLocaleString("en-US", { maximumFractionDigits: 0 });
  const engineIdlingTime = summary["Engine idling time"].toLocaleString("en-US", { maximumFractionDigits: 0 });
  const ghgEmissions = summary["GHG Emissions"].toLocaleString("en-US", { maximumFractionDigits: 0 });
  const fuelEconomy = summary["Average Economy"];
  await addPage1();
  // await addPage2();
  await addPage3();
  await addPage4(distanceTravelled, fuelConsumption, ghgEmissions);
  await addPage5(engineRunTime, engineIdlingTime, fuelEconomy);
  await addPage6();
  await addPage7();
  // await addPage8();
  // await addPage9();
  await addPage10();
  await addPage11();

  // Save the document after all pages have been added
  doc.save(`Sustainability Report-${formatDate(new Date())}.pdf`);
}

export { FleetSustainabilityReport };
