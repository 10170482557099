import React, {useState, useEffect, useMemo} from 'react';
import '../style/components-style/ProcessTable.css';
import config from "../config";
import Grid from "../environment-page-components/Grid";
import {Icon} from '@iconify/react';
import {translations} from '../app_components/Translation';
import ScopeTableButton from "./ScopeTableButton";

const scope1Categories = ['Stationary Combustion', 'Mobile Sources', 'Refrigeration / AC Equipment Use', 'Fire Suppression', 'Purchased Gases'];
const scope2Categories = ['Electricity (Location Based)', 'Electricity (Market Based)', 'Steam'];
const scope3Categories = ['Business Travel', 'Employee Commuting', 'Upstream Transportation and Distribution',
    'Downstream Transportation and Distribution', 'Purchased Goods and Services', 'Capital Goods', 'Fuel and Energy-related Activities',
    'Processing of Sold Products', 'Use of Sold Products', 'End-of-life Treatment of Sold Products', 'Waste', 'Franchises',
    'Investments', 'Upstream Leased Assets', 'Downstream Leased Assets'];
const scope4Categories = ['Scope4'];
const allCategories = [...scope1Categories, ...scope2Categories, ...scope3Categories];

function ScopeTable({
                        setSelectedScopeData,
                        setScopeData,
                        selectedRow,
                        setScopeTableGridApi,
                        setScopeTableTotal,
                        pageType,
                        totalGWP,
                        selectedLanguage
                    }) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [scopeRowData, setScopeRowData] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [selectedScopesRows, setSelectedScopesRows] = useState([]);
    const selectedText = translations[selectedLanguage].scopeTable;
    const [selectedTab, setSelectedTab] = useState(selectedText.all);
    const [scopeCategory, setScopeCategory] = useState(allCategories);
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState(false);

    const onGridReady = params => {
        setGridApi(params.api);
        setScopeTableGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const sortData = (data) => {
        return data.sort((a, b) => a.category.localeCompare(b.category));
    }

    const refreshEmissionsData = () => {
        if (!selectedRow || !selectedRow['id']) {
            setScopeRowData([]);
            setLoading(false);
            return;
        }
        const mainTableID = selectedRow['id'];
        setLoading(true);

        fetch(`${config.apiUrl}/api/get-emissions-by-id`, {
            method: 'POST', headers: {
                'Content-Type': 'application/json',
            }, body: JSON.stringify({
                productOrOrganizationId: mainTableID
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // console.log(data)
                data = sortData(data);
                setScopeRowData(data);
                setScopeData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!gridApi) return;

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map(node => node.data);
            setSelectedScopeData(selectedData);
            setSelectedScopesRows(selectedData);
        };

        gridApi.addEventListener('selectionChanged', onSelectionChanged);
        return () => {
            gridApi.removeEventListener('selectionChanged', onSelectionChanged);
        };
    }, [gridApi, setSelectedScopeData]);

    useEffect(() => {
        refreshEmissionsData();
    }, [selectedRow]);

    useEffect(() => {
        if (scopeRowData.length > 0) {
            fetchEmissionDetails(scopeRowData);
        }
    }, [scopeRowData]);

    useEffect(() => {
        if (!gridApi) return; // Ensure gridApi is available

        // Get the selected nodes in the grid
        const selectedNodes = gridApi.getSelectedNodes();

        if (selectedNodes.length > 0) {  // Check if there are any selected rows
            const firstSelectedNode = selectedNodes[0];  // Get the first selected no
            // Update the 'gwp' field with the new totalGWP value
            firstSelectedNode.setDataValue('gwp', totalGWP);  // Update the 'gwp' field of the selected row

            // Refresh the cells to show the updated value in the grid
            gridApi.refreshCells({rowNodes: [firstSelectedNode], force: true});
        }
    }, [totalGWP, gridApi]);

    function formatFloatValue(value, decimalPlaces) {
        return parseFloat(value).toFixed(decimalPlaces);
    }

    useEffect(() => {
        if (!gridApi) return;

        const tab = filterTabs.find(tab => tab.label === selectedTab);
        if (!tab) {
            console.error(`No filter found for tab: ${selectedTab}`);
            return;
        }

        setScopeCategory(tab.categories);

        const filteredData = scopeRowData.filter(tab.filter);
        gridApi.setRowData(filteredData);
    }, [gridApi, selectedTab, scopeRowData]);

    const filterTabs = [{
        label: selectedText.all,
        filter: (item) => true,
        categories: allCategories
    }, {
        label: selectedText.scope1,
        filter: (item) => !item.is_avoided && scope1Categories.includes(item.category),
        categories: scope1Categories
    }, {
        label: selectedText.scope2,
        filter: (item) => !item.is_avoided && scope2Categories.includes(item.category),
        categories: scope2Categories
    }, {
        label: selectedText.scope3,
        filter: (item) => !item.is_avoided && scope3Categories.includes(item.category),
        categories: scope3Categories
    }, {
        label: selectedText.avoided,
        filter: (item) => item.is_avoided,
        categories: allCategories
    }];

    const calculateTotalGWP = useMemo(() => {
        const rowsToSum = scopeRowData;

        return rowsToSum.reduce((total, row) => {
            const gwpValue = parseFloat(row.gwp);
            return !isNaN(gwpValue) ? total + gwpValue : total;
        }, 0).toFixed(2);
    }, [scopeRowData, selectedScopesRows]);

    useEffect(() => {
        setScopeTableTotal(calculateTotalGWP);
    }, [calculateTotalGWP, setScopeTableTotal]);

    // const descriptionOptions = (category) => {
    //     if (!category) return [];
    //
    //     switch (category) {
    //         case 'Gaseous Fuels':
    //             return ['Propane[L]'];
    //         case 'Natural Gas':
    //             return ['Natural Gas[G]'];
    //         case 'Transport Fuels':
    //             return ['Compressed Natural Gas - CNG [gal]', 'Diesel Transport [gal]', 'Ethanol E10 Transport [gal]', 'Gasoline Transport [gal]', 'Petrol Transport [L]'];
    //         case 'Refrigerants and Other Gases':
    //             return ['R134a [kg]', 'R404A [kg]', 'R410A [kg]', 'R427A [lbs]', 'R438A [lbs]'];
    //         case 'Pre-calculated Emissions':
    //             return ['Scope 1 Emissions [t CO2e]'];
    //         case 'Electricity':
    //             return ['Electricity [kWh]'];
    //         case 'Steam':
    //             return ['Steam [kWh]'];
    //         case 'Air Travel':
    //             return ['Pending'];
    //         case 'Ground Travel':
    //             return ['Pending'];
    //         case 'Waste':
    //             return ['Pending'];
    //         case 'Scope 3 Spend-based':
    //             return ['Pending'];
    //         default:
    //             return ['Pending'];
    //     }
    // };

    const fetchEmissionDetails = async (rowData) => {
        try {
            // Create an array of promises for each row's emissionID
            const fetchPromises = rowData.map(row =>
                fetch(`${config.apiUrl}/api/get-emission-details-by-id`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({emissionID: row.id}),  // Send the current row ID
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Error fetching emission details for ID ${row.id}: ${response.statusText}`);
                        }
                        return response.json();
                    })
                    .then(data => ({id: row.id, details: data}))  // Return the fetched data with the ID
                    .catch(error => {
                        console.error('Error fetching emission details:', error);
                        return {id: row.id, details: null};  // Return null for failed requests
                    })
            );

            // Wait for all the requests to complete
            const allEmissionDetails = await Promise.all(fetchPromises);

            // Create a Set of IDs that have emission details
            const idsWithEmissionDetails = new Set(
                allEmissionDetails.filter(result => result.details && result.details.length > 0).map(result => result.id)
            );

            // Update rowData to include 'hasEmissionDetails' flag
            const updatedRowData = rowData.map(row => ({
                ...row,
                hasEmissionDetails: idsWithEmissionDetails.has(row.id),
            }));

            // Update the grid with new row data
            updateGridData(updatedRowData);

        } catch (error) {
            console.error('Failed to fetch emission details:', error);
        }
    };

    const updateGridData = (updatedRowData) => {
        gridApi.setRowData(updatedRowData);  // Assuming gridApi is available
    };

    useEffect(() => {
        const activeButton = document.querySelector('.filter-bar button.active');
        const indicator = document.querySelector('.filter-bar .active-indicator');
        const filterBar = document.querySelector('.filter-bar');

        if (activeButton && indicator && filterBar) {
            const buttonRect = activeButton.getBoundingClientRect();
            const barRect = filterBar.getBoundingClientRect();

            // Get the scroll offset of the filter bar
            const scrollOffset = filterBar.scrollLeft;

            // Set the width of the indicator to match the active button
            indicator.style.width = `${buttonRect.width}px`;

            // Adjust the left position based on scroll offset
            indicator.style.left = `${buttonRect.left - barRect.left + scrollOffset}px`;
        }
    }, [selectedTab]);

    useEffect(() => {
        const filterBar = document.querySelector('.filter-bar');

        const handleScroll = () => {
            const activeButton = document.querySelector('.filter-bar button.active');
            const indicator = document.querySelector('.filter-bar .active-indicator');

            if (activeButton && indicator && filterBar) {
                const buttonRect = activeButton.getBoundingClientRect();
                const barRect = filterBar.getBoundingClientRect();

                // Adjust based on scroll offset
                const scrollOffset = filterBar.scrollLeft;

                indicator.style.width = `${buttonRect.width}px`;
                indicator.style.left = `${buttonRect.left - barRect.left + scrollOffset}px`;
            }
        };

        // Listen for scroll events on the filter bar
        filterBar.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            filterBar.removeEventListener('scroll', handleScroll);
        };
    }, [selectedTab]);


    const scopeGridProps = {
        columnDefs: [{field: 'id', hide: true}, {field: 'product_service_id', hide: true}, {
            field: 'organization_id',
            hide: true
        }, {field: 'corporate_id', hide: true}, {
            headerName: selectedText.headers.category,
            field: 'category',
            cellEditor: 'agSelectCellEditor',
            editable: true,
            cellEditorParams: {
                values: scopeCategory,
            },
            minWidth: 120,
            flex: 2,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            cellStyle: {textAlign: 'left'}
        }, {
            headerName: selectedText.headers.description,
            field: 'description',
            cellEditor: 'agTextEditor',
            editable: true,
            minWidth: 120,
            flex: 1,
            cellStyle: {textAlign: 'left'}
        }, {
            headerName: selectedText.headers.gwp,
            field: 'gwp',
            editable: (params) => !params.data.hasEmissionDetails,
            valueFormatter: (params) => formatFloatValue(params.value, 2),
            minWidth: 180,
            flex: 2,
            cellStyle: {textAlign: 'right'},
            headerClass: "ag-right-aligned-header"
        }, {field: 'is_avoided', hide: true}], rowData: scopeRowData, sideBar: {
            toolPanels: [{
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true, suppressValues: true, suppressPivots: true, suppressPivotMode: true,
                },
            }, {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },], defaultToolPanel: 'filters'
        }, onGridReady
    };

    const toggleContainer = () => setIsOpen(!isOpen);

    return (<div className="process-table">
        <div className="container-header">
            <h2
                onClick={toggleContainer}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    cursor: 'pointer',
                    color: isHovered ? 'grey' : 'black', // Change 'blue' to your desired hover color
                }}
            >
                {selectedText.scope}
            </h2>
        </div>
        {isOpen && (<>
            <div className="filter-bar">
                {filterTabs.map((tab, index) => (
                    <button
                        key={tab.label}
                        onClick={() => {
                            setSelectedTab(tab.label);
                            document.documentElement.style.setProperty('--active-index', index); // Update active index
                        }}
                        className={selectedTab === tab.label ? 'active' : ''}
                    >
                        {tab.label}
                    </button>
                ))}
                <div className="active-indicator"></div>
            </div>
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="grid-container">
                    <div className="grid-wrapper">
                        <Grid {...scopeGridProps} />
                    </div>
                    <div className="gwp-total">
                        <text className="gwp-total-text">Total:</text>
                        <text className="gwp-total-number"> {calculateTotalGWP} </text>
                    </div>
                    <div className="scope-button-container">
                        <ScopeTableButton selectedRow={selectedRow} selectedScopes={selectedScopesRows}
                                          tableGridApi={gridApi} selectedLanguage={selectedLanguage}
                                          product={(pageType === 'valueChain') ? selectedRow : null}
                                          organization={(pageType === 'organization') ? selectedRow : null}
                                          corporate={(pageType === 'corporate') ? selectedRow : null}
                                          selectedTab={selectedTab}
                                          refreshData={refreshEmissionsData}/>
                    </div>
                </div>
            )}
        </>)}
    </div>);
}

export default ScopeTable;