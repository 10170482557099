import { useState, useEffect } from "react";
import { FaFilter } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import styles from "./FleetFilterDropdown.module.css";
import { formatDate } from "../../utils/fleetUtils/formatDate";

const TripFleetFilter = ({ trips, filters, onFilterChange, categories, metrics }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState({ ...filters });
  const [metricMeans, setMetricMeans] = useState({});

  useEffect(() => {
    setLocalFilters({ ...filters });
  }, [filters]);

  useEffect(() => {
    const means = {};
    metrics.forEach((metric) => {
      const values = trips.map((route) => parseFloat(route[metric.key]) || 0);
      const mean = values.reduce((acc, val) => acc + val, 0) / values.length;
      means[metric.key] = mean.toFixed(0);
    });
    setMetricMeans(means);
  }, [trips, metrics]);

  const getUniqueValues = (key) => {
    return [...new Set(trips.map((route) => (key === "expectedDelivery" ? formatDate(route[key]) : route[key])))];
  };

  const handleMultiSelectChange = (category, selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setLocalFilters({
      ...localFilters,
      [category]: selectedValues,
    });
  };

  const calculateTopBottomThreshold = (values, percentile) => {
    if (!values || values.length === 0) return 0;

    const sortedValues = [...values].sort((a, b) => a - b);
    const index = Math.floor((percentile / 100) * sortedValues.length);
    return sortedValues[index];
  };

  const handleFilterChange = (category, option, value = "") => {
    const isMetric = metrics.some((metric) => metric.key === category);
    const values = trips.map((trip) => parseFloat(trip[category]) || 0);

    if (option === "top10") {
      const threshold = calculateTopBottomThreshold(values, 90);
      setLocalFilters({
        ...localFilters,
        [category]: { option, value: threshold },
      });
    } else if (option === "bottom10") {
      const threshold = calculateTopBottomThreshold(values, 10);
      setLocalFilters({
        ...localFilters,
        [category]: { option, value: threshold },
      });
    } else {
      setLocalFilters({
        ...localFilters,
        [category]: isMetric ? { option, value } : value,
      });
    }
  };

  const handleApplyFilters = () => {
    onFilterChange(localFilters);
    setDropdownIsOpen(false);
  };

  const handleResetFilters = () => {
    const resetFilters = {
      startAddress: [],
      endAddress: [],
      ...Object.fromEntries(
        [...categories, ...metrics].map((filter) => {
          if (filter.key !== "startAddress" && filter.key !== "endAddress") {
            return [filter.key, { option: "", value: "" }];
          }
          return [filter.key, []];
        }),
      ),
    };

    setLocalFilters(resetFilters);
    onFilterChange(resetFilters);
  };

  const renderFilterSelect = (filter) => {
    const isMetric = metrics.some((metric) => metric.key === filter.key);
    const options = isMetric
      ? [
          { value: "", label: "All" },
          { value: "top10", label: "Top 10%" },
          { value: "bottom10", label: "Bottom 10%" },
          { value: "gte", label: "Greater than" },
          { value: "lte", label: "Less than" },
        ]
      : [{ value: "", label: "All" }, ...getUniqueValues(filter.key).map((value) => ({ value, label: value }))];

    if (filter.key === "startAddress" || filter.key === "endAddress") {
      return;
    }

    return (
      <div key={filter.key} className={styles.filterItem}>
        <div className={styles.filterLabel} htmlFor={filter.key}>
          {filter.label}
        </div>
        <div className={styles.filterControl}>
          <select id={filter.key} value={isMetric ? localFilters[filter.key]?.option || "" : localFilters[filter.key] || ""} onChange={(e) => handleFilterChange(filter.key, e.target.value, isMetric ? localFilters[filter.key]?.value || "" : e.target.value)} className={styles.selectInput}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {(localFilters[filter.key]?.option === "gte" || localFilters[filter.key]?.option === "lte") && <input type="text" value={localFilters[filter.key]?.value || metricMeans[filter.key] || ""} onChange={(e) => handleFilterChange(filter.key, localFilters[filter.key]?.option, e.target.value)} className={styles.metricInput} placeholder={metricMeans[filter.key] || "Value"} />}
        </div>
      </div>
    );
  };

  const startAddressOptions = getUniqueValues("startAddress").map((value) => ({ value, label: value }));
  const endAddressOptions = getUniqueValues("endAddress").map((value) => ({ value, label: value }));

  return (
    <div className={styles.filterContainer}>
      <button className={styles.filterButton} onClick={() => setDropdownIsOpen(!dropdownIsOpen)}>
        <FaFilter />
      </button>
      {dropdownIsOpen && (
        <div className={styles.dropdownContent}>
          <div className={styles.header}>
            <h2>Filter Trips</h2>
            <AiOutlineClose className={styles.closeIcon} size="25px" onClick={() => setDropdownIsOpen(false)} />
          </div>

          <div className={styles.filtersContainer}>
            <div className={styles.filterItem}>
              <div className={styles.filterLabel}>Start Address</div>
              <Select
                isMulti
                options={startAddressOptions}
                value={localFilters["startAddress"]?.map((value) => ({ value, label: value }))}
                onChange={(selected) => handleMultiSelectChange("startAddress", selected)}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "none",
                    borderBottom: state.isFocused ? "2px solid #3f3f3f" : "2px solid #bfbfbf",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    borderRadius: "0px",
                    boxShadow: "none",
                    transition: "border-bottom 0.3s",
                    fontSize: "14px",
                    ":hover": {
                      borderBottom: "2px solid #3f3f3f",
                    },
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "#f1f1f1",
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: "#000",
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    color: "#888",
                    ":hover": {
                      backgroundColor: "#e57373",
                      color: "white",
                    },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#bfbfbf",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#bfbfbf",
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                }}
              />
            </div>
            <div className={styles.filterItem}>
              <div className={styles.filterLabel}>End Address</div>
              <Select
                isMulti
                options={endAddressOptions}
                value={localFilters["endAddress"]?.map((value) => ({ value, label: value }))}
                onChange={(selected) => handleMultiSelectChange("endAddress", selected)}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: "none",
                    borderBottom: state.isFocused ? "2px solid #3f3f3f" : "2px solid #bfbfbf",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    borderRadius: "0px",
                    boxShadow: "none",
                    transition: "border-bottom 0.3s",
                    fontSize: "14px",
                    ":hover": {
                      borderBottom: "2px solid #3f3f3f",
                    },
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "#f1f1f1",
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    color: "#000",
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    color: "#888",
                    ":hover": {
                      backgroundColor: "#e57373",
                      color: "white",
                    },
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#bfbfbf",
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#bfbfbf",
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                }}
              />
            </div>

            {[...categories, ...metrics].map(renderFilterSelect)}
          </div>

          <div className={styles.modalActions}>
            <button className={styles.resetFiltersButton} onClick={handleResetFilters}>
              Reset
            </button>
            <div className={styles.actionButtons}>
              <button className={styles.applyFiltersButton} onClick={handleApplyFilters}>
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TripFleetFilter;
