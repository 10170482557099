import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import Grid from "./Grid";
import config from "../config";
import "../style/components-style/Table.css";
import {Icon} from "@iconify/react";
import TableButton from "./TableButton";
import AdditionalInfo from "./AdditionalInfo";
import TrackView from "./TrackView";
import {useAuth} from "../authentication/AuthProvider";
import {translations} from "../app_components/Translation";
import Collaborate from "./Collaborate";
import eventBus from "../eventBus";
import StructuralView from "../organizational-page-components/StructuralView";
import StructuralViewWrapper from "../organizational-page-components/StructuralView";

function Table({
                   setSelectedRowData,
                   setMainTableGridApi,
                   setTrackViewState,
                   selectedLanguage,
                   setAllTableRows,
                   childTableRows,
                   scopeTableTotal,
                   pageType,
                   setPageType,
                   netZeroPage
               }) {
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [leedCategories, setLeedCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});
    const [isOpen, setIsOpen] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [activeTab, setActiveTab] = useState("additionalInfo");
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const [editableAdditionalInfo, setEditableAdditionalInfo] = useState(null);
    const [collaborateInfo, setCollaborateInfo] = useState(null);
    const [trackView, setTrackView] = useState(false);
    const [structureView, setStructureView] = useState(false);
    const [searchText, setSearchText] = useState("");
    const gridApiRef = useRef(null);
    const {user} = useAuth();
    const userId = user ? user.username : null;
    const [isHovered, setIsHovered] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showColumnsModal, setShowColumnsModal] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [storedSelectedRows, setStoredSelectedRows] = useState([]);
    const [isEditable, setIsEditable] = useState(true);

    useEffect(() => {
        if (netZeroPage) {
            setIsEditable(false);
        } else {
            setIsEditable(true);
        }
    }, [netZeroPage]);

    // Function to toggle pageType
    const togglePageType = (pageType) => {
        setPageType(pageType);
    };

    // Function to format float values
    function formatFloatValue(value, decimalPlaces = 2) {
        return parseFloat(value).toFixed(decimalPlaces);
    }

    // Sorting function to sort rows alphabetically and then numerically based on the data type
    function sortRows(data) {
        return data.sort((a, b) => {
            // Determine the sorting logic based on the available fields (for suppliers, organizations, or utilizers)
            if (pageType === "supplier") {
                // Sorting for suppliers by 'product' (alphabetical) and 'Year' (numerical)
                const nameA = a.product ? a.product.toLowerCase() : "";
                const nameB = b.product ? b.product.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            } else if (pageType === "utilizer") {
                // Sorting for utilizers by 'design_installation' (alphabetical) and 'Year' (numerical)
                const nameA = a.design_installation ? a.design_installation.toLowerCase() : "";
                const nameB = b.design_installation ? b.design_installation.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            } else if (pageType === "fleet") {
                // Sorting for fleet by 'fleet' (alphabetical) and 'Year' (numerical)
                const nameA = a.fleet ? a.fleet.toLowerCase() : "";
                const nameB = b.fleet ? b.fleet.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            } else if (pageType === "organization") {
                // Sorting for organizations by 'organization' (alphabetical) and 'Year' (numerical)
                const nameA = a.organization ? a.organization.toLowerCase() : "";
                const nameB = b.organization ? b.organization.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            } else if (pageType === "valueChain") {
                // Sorting for organizations by 'organization' (alphabetical) and 'Year' (numerical)
                const nameA = a.product_service ? a.product_service.toLowerCase() : "";
                const nameB = b.product_service ? b.product_service.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            } else if (pageType === "corporate") {
                const nameA = a.corporate ? a.corporate.toLowerCase() : "";
                const nameB = b.corporate ? b.corporate.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            }
        });
    }

    // Function to handle track view change
    const handleTrackViewChange = () => {
        const newTrackViewState = !trackView;
        setTrackView(newTrackViewState);
        setTrackViewState(newTrackViewState);
    };

    const handleStructureViewChange = () => {
        const newStructureViewState = !structureView;
        setStructureView(newStructureViewState);
        // setStructureViewState(newStructureViewState);
    };

    // Initialize allRows based on gridApi
    const allRows = gridApi && gridApi.getRenderedNodes ? (gridApi.getRenderedNodes() || []).map((node) => node.data) : [];

    // Get the translations for the selected language
    const selectedText = translations[selectedLanguage].table;

    // Fetch data from the backend
    const fetchData = () => {
        if (!userId) return;

        const tableName = pageType === "supplier" ? "suppliers_products" : pageType === "organization" ? "organizations" : pageType === "utilizer" ? "design_installations" : pageType === "fleet" ? "fleets" : pageType === "valueChain" ? "products_services" : "corporates";

        let condition;

        if (pageType === "supplier") {
            condition = `((user_id = '${userId}' AND (collaborator_id IS NULL OR collaborator_id = '' OR collaborator_id = 'N/A')) OR (collaborator_id = '${userId}'))`;
        } else {
            condition = `user_id = '${userId}'`;
        }

        fetch(`${config.apiUrl}/fetch_data`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                table_name: tableName,
                attribute: "*",
                condition: condition,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                const sortedData = sortRows(data || []); // Sort the fetched data
                setAllTableRows(sortedData);
                setRowData(sortedData);
                if (gridApi) {
                    gridApi.setRowData(data); // Update the grid with new row data
                }
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    // Function to refresh data and reapply row selection
    const refreshData = () => {
        if (!gridApi) return;

        // Step 1: Store currently selected rows' IDs or other unique field
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedRowIds = selectedNodes.map((node) => node.data.id); // Adjust if you're using another unique field
        setStoredSelectedRows(selectedRowIds); // Store selected rows

        setLoading(true); // Show loading spinner while data is being refreshed

        // Step 2: Fetch new data and update the grid
        setTimeout(() => {
            fetchData(); // Refresh data after a delay (for backend processing)
        }, 5000); // Adjust the delay as needed

        setLoading(false); // Stop loading after data refresh
    };

    // Fetch LEED categories from the backend
    const fetchLeedCategories = () => {
        fetch(`${config.apiUrl}/fetch_data`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({table_name: "leed_categories", attribute: "*", condition: "1"}),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                const categories = data.map((item) => item.category);
                const subCatMap = {};
                data.forEach((item) => {
                    subCatMap[item.category] = JSON.parse(item.sub_category);
                });
                setLeedCategories(categories);
                setSubCategories(subCatMap);
            })
            .catch((error) => console.error("Error fetching LEED categories:", error));
    };

    useEffect(() => {
        fetchData();
        fetchLeedCategories();
    }, [pageType, userId]);

    // Ensure grid isn't ready until LEED categories are fetched
    useEffect(() => {
        if (leedCategories.length > 0) {
            setColumnDefs(getColumnDefs());
        }
    }, [leedCategories]);

    const updateGWPTotal = () => {
        if (gridApi) {
            const selectedNode = gridApi.getSelectedNodes()[0];
            if (selectedNode) {
                selectedNode.data.gwp_total = scopeTableTotal;
                gridApi.applyTransaction({update: [selectedNode.data]});
            }
        }
    };

    useEffect(() => {
        if ((pageType === "fleet" || pageType === "organization" || pageType === "valueChain" || pageType === "fleet" || pageType === "corporate") && !netZeroPage) {
            updateGWPTotal();
        }
    }, [childTableRows]);

    useEffect(() => {
        if (!gridApi || !rowData.length || !storedSelectedRows.length) return;

        // Step 3: Reapply selection after grid data is updated
        gridApi.forEachNode((node) => {
            if (storedSelectedRows.includes(node.data.id)) {
                node.setSelected(true); // Reselect rows based on stored IDs
            }
        });
    }, [gridApi, rowData]); // Ensure this runs after gridApi and rowData are updated

    // Event listener for "dataSaved" event
    useEffect(() => {
        const handleDataSaved = () => {
            refreshData(); // Refresh the data when "dataSaved" is triggered
        };

        eventBus.on("dataSaved", handleDataSaved);

        return () => {
            eventBus.off("dataSaved", handleDataSaved); // Cleanup event listener
        };
    }, [gridApi]);

    // Grid setup
    const onGridReady = useCallback(
        (params) => {
            setGridApi(params.api);
            setMainTableGridApi(params.api);
        },
        [setMainTableGridApi],
    );

    const getSubCategoriesForCategory = (category) => {
        return subCategories[category] || [];
    };

    useEffect(() => {
        if (!gridApi || !gridApi.getRenderedNodes) return;

        let controller; // Variable to store the AbortController

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes() || []; // Safeguard against undefined
            const selectedData = selectedNodes.map((node) => node.data); // Safe to map now
            setSelectedRowData(selectedData);
            setSelectedRows(selectedData);

            if (selectedData.length > 0) {
                setAdditionalInfo(selectedData[0]);
                setEditableAdditionalInfo(selectedData[0]);
                setCollaborateInfo(selectedData[0]);
            } else {
                setAdditionalInfo(null);
                setEditableAdditionalInfo(null);
                setCollaborateInfo(null);

                // Abort the ongoing fetch request if no rows are selected
                if (controller) {
                    controller.abort();
                }
            }
        };

        gridApi.addEventListener("selectionChanged", onSelectionChanged);
        return () => {
            gridApi.removeEventListener("selectionChanged", onSelectionChanged);
            if (controller) {
                controller.abort(); // Abort the fetch request when the component unmounts
            }
        };
    }, [gridApi, setSelectedRowData]);

    const toggleContainer = () => setIsOpen(!isOpen);

    const handleShowDetailsChange = () => {
        setShowDetails(!showDetails);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setEditableAdditionalInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSave = () => {
        const currentDate = new Date();
        editableAdditionalInfo.date_modified = currentDate.toISOString().slice(0, 19).replace("T", " ");

        fetch(`${config.apiUrl}/update_data`, {
            method: "PUT",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                table_name: pageType === "supplier" ? "suppliers_products" : pageType === "organization" ? "organizations" : pageType === "utilizer" ? "design_installations" : pageType === "fleet" ? "fleets" : pageType === "valueChain" ? "products_services" : "corporates",
                data: editableAdditionalInfo,
                condition: `id = '${editableAdditionalInfo.id}'`,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    alert("Additional information updated successfully");
                    setEditableAdditionalInfo((prevState) => ({
                        ...prevState,
                        date_modified: currentDate.toISOString().slice(0, 19).replace("T", " "),
                    }));

                    // Re-fetch data and sort it after saving
                    fetchData();
                }
            })
            .catch((error) => console.error("Error updating data:", error));
    };

    const toggleSearchBar = () => {
        setShowSearch(!showSearch);
        setSearchText(""); // Clear the search text when closing the search bar
        if (gridApiRef.current) {
            gridApiRef.current.setQuickFilter(""); // Clear the filter when closing the search bar
        }
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
        if (gridApiRef.current) {
            gridApiRef.current.setQuickFilter(event.target.value);
        }
    };

    const toggleColumnVisibility = (field) => {
        if (gridApi) {
            const currentState = gridApi.getColumnState();
            const targetColumn = currentState.find((col) => col.colId === field);
            if (targetColumn) {
                gridApi.setColumnVisible(field, targetColumn.hide);
                setColumnDefs((prevColumnDefs) =>
                    prevColumnDefs.map((col) => {
                        if (col.field === field) {
                            // Toggle the hide property in the columnDefs state
                            return {...col, hide: !targetColumn.hide};
                        }
                        return col;
                    }),
                );
            }
        }
    };

    const exportCsv = () => {
        if (gridApi) {
            const now = new Date();

            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
            const day = String(now.getDate()).padStart(2, "0");

            const formattedDateTime = `${year}-${month}-${day}`;

            if (pageType === "supplier") {
                gridApi.exportDataAsCsv({
                    fileName: `SupplierTable - ${formattedDateTime}.csv`,
                });
            } else if (pageType === "utilizer") {
                gridApi.exportDataAsCsv({
                    fileName: `UtilizerTable - ${formattedDateTime}.csv`,
                });
            } else if (pageType === "fleet") {
                gridApi.exportDataAsCsv({
                    fileName: `FleetTable - ${formattedDateTime}.csv`,
                });
            } else if (pageType === "organization") {
                gridApi.exportDataAsCsv({
                    fileName: `OrganizationTable - ${formattedDateTime}.csv`,
                });
            } else if (pageType === "valueChain") {
                gridApi.exportDataAsCsv({
                    fileName: `ValueChainTable - ${formattedDateTime}.csv`,
                });
            } else if (pageType === "corporate") {
                gridApi.exportDataAsCsv({
                    fileName: `CorporateTable - ${formattedDateTime}.csv`,
                });
            }
        }
    };

    // Define column definitions for suppliers, utilizers, and organizations
    const getColumnDefs = () => {
        // Function to determine if a cell is editable
        const isCellEditable = (params) => {
            const {data} = params;

            // If circularityPage is true, make all fields non-editable
            if (!isEditable) {
                return false;
            }

            // Check if the current user is the collaborator and set fields to non-editable except quantity
            if (data.collaborator_id === userId || data.user_id === 'N/A' || data.user_id === '') {
                return params.colDef.field === "quantity";
            }

            // Default logic: fields are editable if validated !== 1
            return data.validated !== 1;
        };

        const supplierColumnDefs = [
            {field: "id", hide: true},
            {
                headerName: selectedText.product,
                field: "product",
                cellEditor: "agTextCellEditor",
                checkboxSelection: true,
                headerCheckboxSelection: true,
                minWidth: 375,
                editable: isCellEditable,
                hide: false,
            },
            {
                headerName: selectedText.supplier,
                field: "supplier",
                editable: isCellEditable,
                minWidth: 100,
                cellEditor: "agTextCellEditor",
                hide: false,
            },
            {
                headerName: selectedText.leedCategory,
                field: "leed_category",
                cellEditor: "agSelectCellEditor",
                editable: isCellEditable,
                minWidth: 200,
                cellEditorParams: {values: leedCategories},
                cellRenderer: (params) => params.value,
                hide: false,
            },
            {
                headerName: selectedText.subCategory,
                field: "sub_category",
                editable: isCellEditable,
                cellEditor: "agSelectCellEditor",
                minWidth: 200,
                cellEditorParams: (params) => ({values: getSubCategoriesForCategory(params.data.leed_category)}),
                hide: false,
            },
            {
                headerName: selectedText.year,
                field: "Year",
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: [2024]},
                editable: isCellEditable,
                width: 100,
                maxWidth: 100,
                minWidth: 50,
                cellStyle: {textAlign: "center"},
                headerClass: "ag-center-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.quantity,
                field: "quantity",
                editable: isCellEditable,
                cellEditor: "agNumberCellEditor",
                width: 115,
                maxWidth: 115,
                minWidth: 50,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.gwpTotal,
                field: "gwp_total",
                editable: false,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 250,
                maxWidth: 250,
                minWidth: 100,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.cost,
                field: "cost",
                editable: isCellEditable,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 115,
                maxWidth: 115,
                minWidth: 100,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.costPerGwp,
                field: "cost_per_gwp",
                cellEditor: "agTextCellEditor",
                editable: false,
                valueGetter: (params) => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : "N/A";
                },
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {field: "functional_unit", hide: true},
            {field: "date_modified", hide: true},
        ];

        const utilizerColumnDefs = [
            {field: "id", hide: true},
            {
                headerName: selectedText.designInstallation,
                field: "design_installation",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                minWidth: 400,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                hide: false,
            },
            {
                headerName: selectedText.location,
                field: "location",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                minWidth: 200,
                hide: false,
            },
            {
                headerName: "Description",
                field: "description",
                cellEditor: "agTextCellEditor",
                hide: true,
                minWidth: 200,
            },
            {
                headerName: selectedText.year,
                field: "Year",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: [2024]},
                width: 100,
                cellStyle: {textAlign: "center"},
                headerClass: "ag-center-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.quantity,
                field: "quantity",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agNumberCellEditor",
                width: 100,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.gwpTotal,
                field: "gwp_total",
                editable: false,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.cost,
                field: "cost",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.costPerGwp,
                field: "cost_per_gwp",
                cellEditor: "agTextCellEditor",
                editable: false,
                valueGetter: (params) => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : "N/A";
                },
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {field: "date_modified", hide: true},
        ];

        const valueChainColumnDefs = [
            {field: "id", hide: true},
            {
                headerName: selectedText.product_service,
                field: "product_service",
                cellEditor: "agTextCellEditor",
                checkboxSelection: true,
                headerCheckboxSelection: true,
                minWidth: 250,
                editable: isCellEditable,
                hide: false,
            },
            {
                headerName: selectedText.role,
                field: "role",
                editable: isCellEditable,
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: ["Supplier", "Subsidiary", "Affliate", "PartnerShip/JV", "Franchises", "Independent", "Node", "Site", "Station", "Facility", "Center", "Depot", "Equity Share", "Operational Control", "Financial Control"]},
                hide: false,
            },
            {
                headerName: selectedText.percentage,
                field: "percentage",
                editable: isCellEditable,
                cellEditor: "agNumberCellEditor",
                hide: false,
            },
            {
                headerName: selectedText.name,
                field: "name",
                cellEditor: "agTextCellEditor",
                hide: false,
            },
            {
                headerName: selectedText.year,
                field: "Year",
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: [2023, 2024, 2025]},
                editable: isCellEditable,
                width: 100,
                cellStyle: {textAlign: "center"},
                headerClass: "ag-center-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.quantity,
                field: "ps_quantity",
                editable: isCellEditable,
                cellEditor: "agNumberCellEditor",
                width: 100,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.gwpTotal,
                field: "gwp_total",
                editable: false,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 200,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.cost,
                field: "cost",
                editable: isCellEditable,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.costPerGwp,
                field: "cost_per_gwp",
                cellEditor: "agTextCellEditor",
                editable: false,
                valueGetter: (params) => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : "N/A";
                },
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {field: "functional_unit", hide: true},
            {field: "date_modified", hide: true},
        ];

        const fleetColumnDefs = [
            {field: "id", hide: true},
            {
                headerName: selectedText.fleet,
                field: "fleet",
                editable: true,
                cellEditor: "agTextCellEditor",
                minWidth: 200,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                hide: false,
            },
            {
                headerName: selectedText.vehicleType,
                field: "vehicle_type",
                editable: isEditable,
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {
                    values: ["Highway Trucks", "City Delivery Trucks", "Heavy-Duty Trucks", "Electric Trucks", "CNG Trucks", "Passenger Buses", "Electric Buses", "Garbage Trucks", "Cement Mixer Trucks", "Mining Trucks"],
                },
                minWidth: 150,
                hide: false,
            },

            {
                headerName: selectedText.trailerType,
                field: "trailer_type",
                editable: isEditable,
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {
                    values: ["Refrigerated Trailers", "Flatbed Trailers", "Dry Van Trailers"],
                },
                minWidth: 150,
                hide: false,
            },
            {
                headerName: selectedText.location,
                field: "location",
                editable: true,
                cellEditor: "agTextCellEditor",
                minWidth: 100,
                hide: false,
            },
            {
                headerName: selectedText.year,
                field: "Year",
                editable: true,
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: [2023, 2024, 2025]},
                width: 50,
                cellStyle: {textAlign: "center"},
                headerClass: "ag-center-aligned-header",
                hide: false,
            },

            {
                headerName: selectedText.fuelConsumed,
                field: "fuel_consumed",
                editable: true,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 0),
                minWidth: 100,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.distanceTravelled,
                field: "distance_travelled",
                editable: true,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 0),
                minWidth: 100,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.gwpTotal,
                field: "gwp_total",
                editable: false,
                cellEditor: "agTextCellEditor",
                valueGetter: (params) => {
                    const fuelConsumed = params.data.fuel_consumed;
                    const co2Rate = 2.62;
                    return fuelConsumed > 0 ? (fuelConsumed * co2Rate).toFixed(0) : "N/A";
                },
                minWidth: 200,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.distancePerGwp,
                field: "distance_per_gwp",
                cellEditor: "agTextCellEditor",
                editable: false,
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: true,
            },
            {field: "date_modified", hide: true},
        ];

        const organizationColumnDefs = [
            {field: "id", hide: true},
            {
                headerName: selectedText.organization,
                field: "organization",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                minWidth: 400,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                hide: false,
            },
            {
                headerName: selectedText.orgType,
                field: "org_type",
                editable: isCellEditable,
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: ["Equity Share", "Operational Control", "Financial Control"]},
                hide: false,
            },
            {
                headerName: selectedText.percentage,
                field: "percentage",
                editable: isCellEditable,
                cellEditor: "agNumberCellEditor",
                hide: false,
            },
            {
                headerName: selectedText.location,
                field: "location",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                minWidth: 200,
                hide: false,
            },
            {
                headerName: selectedText.year,
                field: "Year",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: [2023, 2024, 2025]},
                width: 100,
                cellStyle: {textAlign: "center"},
                headerClass: "ag-center-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.gwpTotal,
                field: "gwp_total",
                editable: false,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.cost,
                field: "cost",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.costPerGwp,
                field: "cost_per_gwp",
                cellEditor: "agTextCellEditor",
                editable: false,
                valueGetter: (params) => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : "N/A";
                },
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {field: "date_modified", hide: true},
        ];

        const corporateColumnDefs = [
            {field: "id", hide: true},
            {
                headerName: selectedText.corporate,
                field: "corporate",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                minWidth: 400,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                hide: false,
            },
            {
                headerName: selectedText.location,
                field: "location",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                minWidth: 200,
                hide: false,
            },
            {
                headerName: selectedText.year,
                field: "Year",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {values: [2023, 2024, 2025]},
                width: 100,
                cellStyle: {textAlign: "center"},
                headerClass: "ag-center-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.gwpTotal,
                field: "gwp_total",
                editable: false,
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.cost,
                field: "cost",
                editable: (params) => {
                    if (!isEditable) {
                        return false;
                    }
                    return true;
                },
                cellEditor: "agTextCellEditor",
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {
                headerName: selectedText.costPerGwp,
                field: "cost_per_gwp",
                cellEditor: "agTextCellEditor",
                editable: false,
                valueGetter: (params) => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : "N/A";
                },
                width: 125,
                cellStyle: {textAlign: "right"},
                headerClass: "ag-right-aligned-header",
                hide: false,
            },
            {field: "date_modified", hide: true},
        ];

        return pageType === "supplier" ? supplierColumnDefs : pageType === "fleet" ? fleetColumnDefs : pageType === "organization" ? organizationColumnDefs : pageType === "utilizer" ? utilizerColumnDefs : pageType === "valueChain" ? valueChainColumnDefs : corporateColumnDefs;
    };

    useEffect(() => {
        setColumnDefs(getColumnDefs());
    }, []);

    const getRowStyle = (params) => {
        if (params.data && params.data.collaborator_id && (params.data.collaborator_id !== userId && params.data.collaborator_id !== 'N/A' && params.data.collaborator_id !== '' )) {
            console.log("Applying grey background for row with collaborator_id:", params.data.collaborator_id);
            return {background: "#c9cac98f"};
        } else {
            return null;
        }
    };

    const productsGridProps = {
        columnDefs: columnDefs,
        rowData: rowData,
        rowSelection: "multiple",
        onGridReady: onGridReady,
        domLayout: "autoHeight",
        getRowStyle: getRowStyle,
    };

    const columnSelectionModal = () => {
        if (!gridApi) return null;

        return (
            <div className="columns-modal">
                <button className="close-modal-button" onClick={() => setShowColumnsModal(false)}>
                    <Icon icon="mdi:close" style={{fontSize: "20px", color: "black"}}/>
                </button>
                {columnDefs.map((column) => {
                    if (column.field !== "id") {
                        const colState = gridApi.getColumnState().find((col) => col.colId === column.field);

                        return (
                            <div key={column.field}>
                                <input type="checkbox" checked={!colState?.hide}
                                       onChange={() => toggleColumnVisibility(column.field)}/>
                                <label>{column.headerName || column.field}</label>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    return (
        <div className="main-table">
            <div className="main-table-contain">
                <div className="container-header-main">
                    <h2
                        onClick={toggleContainer}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                            cursor: "pointer",
                            color: isHovered ? "grey" : "black", // Change 'blue' to your desired hover color
                        }}
                    >
                        {pageType === "supplier" ? selectedText.tableTitle : pageType === "fleet" ? selectedText.fleetTitle : pageType === "organization" ? selectedText.organizationTitle : pageType === "utilizer" ? selectedText.designInstallationsTitle : pageType === "valueChain" ? selectedText.valueChainTableTitle : selectedText.corporateTitle}
                    </h2>
                    {netZeroPage && (
                        <div className={`net-zero-toggle-switch ${pageType}`}>
                            <div className={`net-zero-toggle-option ${pageType === "valueChain" ? "selected" : ""}`}
                                 onClick={() => togglePageType('valueChain')}>
                                Value Chain
                            </div>
                            <div className={`net-zero-toggle-option ${pageType === "organization" ? "selected" : ""}`}
                                 onClick={() => togglePageType('organization')}>
                                Organization
                            </div>
                            <div className={`net-zero-toggle-option ${pageType === "corporate" ? "selected" : ""}`}
                                 onClick={() => togglePageType('corporate')}>
                                Corporate
                            </div>
                            <div className="net-zero-toggle-slider"></div>
                        </div>
                    )}
                </div>
                {isOpen && (
                    <div className="content-container">
                        <div className="left-panel">
                            <div className="grid-container">
                                <div className="table-top-button-container">
                                    {showSearch ? (
                                        <div className="search-bar-container">
                                            <input type="text" placeholder="Search..." value={searchText}
                                                   onChange={handleSearch}
                                                   style={{padding: "5px", width: "200px", marginRight: "10px"}}/>
                                            <button onClick={() => handleSearch({target: {value: ""}})}
                                                    style={{padding: "5px"}}>
                                                Clear
                                            </button>
                                            <button
                                                onClick={toggleSearchBar}
                                                style={{
                                                    background: "none",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                }}
                                            >
                                                <Icon icon="mdi:close" style={{fontSize: "20px", color: "black"}}/>
                                            </button>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {/* Right Buttons Container */}
                                    <div className="right-buttons-container">
                                        {!showSearch && (
                                            <button className="search-button" onClick={toggleSearchBar}
                                                    title="Search for keywords in table">
                                                <Icon icon="mdi:magnify"/>
                                            </button>
                                        )}
                                        <button className="show-column-button"
                                                onClick={() => setShowColumnsModal(!showColumnsModal)}
                                                title="Show/Hide Columns">
                                            <Icon icon="material-symbols:view-column-sharp"/>
                                        </button>
                                        {showColumnsModal && columnSelectionModal()}

                                        {/* Export CSV Button */}
                                        <button className="csv-button" onClick={exportCsv}
                                                title="Export current table as CSV">
                                            <Icon icon="mdi:file-export"/>
                                        </button>
                                    </div>
                                </div>
                                <div id="my-grid-container">
                                    {loading ? (
                                        <div className="spinner">Loading...</div> // Show spinner while loading
                                    ) : (
                                        <Grid {...productsGridProps} />
                                    )}
                                </div>
                                <div className="checkbox-container-bottom">
                                    <input type="checkbox" id="toggle-details" name="toggle-details"
                                           checked={showDetails} onChange={handleShowDetailsChange}/>
                                    <label htmlFor="toggle-details">{selectedText.toggleDetails}</label>
                                    <input type="checkbox" id="toggle-view" name="toggle-view" checked={trackView}
                                           onChange={handleTrackViewChange}/>
                                    <label htmlFor="toggle-view">{selectedText.trackView}</label>
                                    {pageType !== "fleet" && pageType !== "supplier" && pageType !== "utilizer" && (
                                        <div>
                                            <input type="checkbox" id="toggle-diagram" name="toggle-diagram"
                                                   checked={structureView} onChange={handleStructureViewChange}/>
                                            <label htmlFor="toggle-diagram">{selectedText.structureView}</label>
                                        </div>
                                    )}
                                </div>
                                {!(netZeroPage) &&
                                    <TableButton selectedRows={selectedRows} mainTableGridApi={gridApi}
                                                 allRows={allRows} fetchData={fetchData}
                                                 selectedLanguage={selectedLanguage} gridApi={gridApi}
                                                 pageType={pageType}/>}
                            </div>
                        </div>
                        {showDetails && selectedRows.length > 0 && (pageType === "supplier" || pageType === "valueChain") && (
                            <div className="details-container">
                                <div className="tab-header">
                  <span className={activeTab === "additionalInfo" ? "active" : ""}
                        onClick={() => handleTabClick("additionalInfo")}>
                    Additional Information
                  </span>
                                    {/* Only show the Collaborate tab if not on Circularity page */}
                                    {!(netZeroPage) && (
                                        <span className={activeTab === "collaborate" ? "active" : ""}
                                              onClick={() => handleTabClick("collaborate")}>
                      Collaborate
                    </span>
                                    )}
                                </div>
                                <div className="tab-content">
                                    {activeTab === "additionalInfo" && additionalInfo &&
                                        <AdditionalInfo additionalInfo={additionalInfo}
                                                        editableAdditionalInfo={editableAdditionalInfo}
                                                        handleInputChange={handleInputChange} handleSave={handleSave}
                                                        pageType={pageType}
                                                        netZeroPage={netZeroPage}/>}
                                    {/* Only show the Collaborate content if not on Circularity page */}
                                    {activeTab === "collaborate" && !(netZeroPage) && collaborateInfo &&
                                        <Collaborate collaborateInfo={collaborateInfo} mainTableGridApi={gridApi}
                                                     fetchData={fetchData}/>}
                                </div>
                            </div>
                        )}

                        {showDetails && selectedRows.length > 0 && !(pageType === "supplier" || pageType === "valueChain") && (
                            <div className="details-container">
                                <div className="tab-header">
                  <span className={activeTab === "additionalInfo" ? "active" : ""}
                        onClick={() => handleTabClick("additionalInfo")}>
                    Additional Information
                  </span>
                                </div>
                                <div className="tab-content">{activeTab === "additionalInfo" && additionalInfo &&
                                    <AdditionalInfo additionalInfo={additionalInfo}
                                                    editableAdditionalInfo={editableAdditionalInfo}
                                                    handleInputChange={handleInputChange} handleSave={handleSave}
                                                    pageType={pageType}
                                                    netZeroPage={netZeroPage}/>}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {}
            {structureView && <StructuralViewWrapper pageType={pageType} selectedRow={selectedRows[0]}/>}
            {trackView && <TrackView selectedRows={selectedRows}/>}
        </div>
    );
}

export default memo(Table);
