import React, { useEffect, useState } from "react";
import styles from "./GenerateReportButton.module.css";
// import { set } from "date-fns";

const InputField = ({ label, name, value, onChange, placeholder }) => (
  <tr>
    <td className={styles.label}>{label}</td>
    <td>
      <input type="text" name={name} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.name, e.target.value)} className={styles.nrCanFormInput} />
    </td>
  </tr>
);

const NRCanReport = ({ tableData, numberReefers, currentStep, setFormFields }) => {
  // State for each section
  const [fleetProfile, setFleetProfile] = useState({
    fleetName: "",
    headquartersAddress: "",
    operationsAddress: "",
    leadContact: "",
    contactEmail: "",
    contactPhone: "",
    ageOfCompany: "",
    operation: "",
    ownership: "",
  });

  const [fleetDescription, setFleetDescription] = useState({
    trucks: [{ gvWeight: "8", vehicleType: "Tractor", fuelType: "Diesel", dutyCycle: "", numTrucks: tableData.length, avgAge: "", telematics: "" }],
    trailers: [{ gvWeight: numberReefers ? 8 : 0, trailerType: "Refrigerated Trailer", numUnits: numberReefers, avgAge: "", telematics: "" }],
  });

  const [technologyInvestments, setTechnologyInvestments] = useState({
    previousInvestments: [{ technology: "", numUnits: "", yearOfInvestment: "", fuelSavings: "", annualSavings: "", investment: "", notes: "" }],
    technologiesConsidered: [{ type: "", technology: "", numDevices: "", resourcesConsulted: "" }],
  });

  const [dataAnalysis, setDataAnalysis] = useState({
    retrofitSummary: { vehicleType: "", numVehicles: "", technology: "", newOrContinuing: "", dataSources: "", totalInvestment: "", fundingSources: "", reasonFundingNeeded: "" },
    fuelAndGHGReduction: { forecastAnnualKm: "", forecastAnnualFuel: "", forecastKLP: "", forecastGHGReductions: "" },
    implementationPlan: { technology: "", numUnits: "", delivery: "", training: "", numUnitsDeliveredYear: "", numUnitsDeliveredPrior: "" },
    retrofitProjects: Array.from({ length: 12 }, (_, i) => ({
      month: i + 1,
      noTrucksTrailers: "",
      distanceTravelled: "",
      fuelConsumed: "",
      KPL: "",
      estimatedReduction: "",
    })),
    forecastedReductions: Array.from({ length: 12 }, (_, i) => ({
      month: i + 1,
      noUnits: "",
      totalDistance: "",
      totalFuel: "",
      mpg: "",
    })),
  });

  useEffect(() => {
    if (currentStep === 5) {
      setFormFields({ fleetProfile, fleetDescription, technologyInvestments, dataAnalysis });
    }
  }, [currentStep]);

  // Validation functions
  const isFleetProfileValid = () => {
    const { fleetName, headquartersAddress, contactEmail } = fleetProfile;
    return fleetName && headquartersAddress && contactEmail;
  };

  const isFleetDescriptionValid = () => {
    return fleetDescription.trucks.every((truck) => truck.gvWeight && truck.vehicleType) && fleetDescription.trailers.every((trailer) => trailer.gvWeight && trailer.trailerType);
  };

  const isTechnologyInvestmentsValid = () => {
    return technologyInvestments.previousInvestments.every((investment) => investment.technology && investment.numUnits) && technologyInvestments.technologiesConsidered.every((tech) => tech.type && tech.technology);
  };

  const isDataAnalysisValid = () => {
    const { retrofitSummary, fuelAndGHGReduction, implementationPlan } = dataAnalysis;
    return retrofitSummary.vehicleType && retrofitSummary.numVehicles && fuelAndGHGReduction.forecastAnnualKm && implementationPlan.technology && implementationPlan.numUnits;
  };

  const handleChange = (section, name, value) => {
    switch (section) {
      case "fleetProfile":
        setFleetProfile((prev) => ({ ...prev, [name]: value }));
        break;

      case "fleetDescription":
        // Handling nested fields for fleetDescription
        const [field, index, key] = name.match(/(\w+)\[(\d+)\]\.(\w+)/).slice(1);
        setFleetDescription((prev) => {
          const updatedTrucks = [...prev.trucks];
          const updatedTrailers = [...prev.trailers];

          if (field === "trucks") {
            updatedTrucks[index][key] = value;
          } else if (field === "trailers") {
            updatedTrailers[index][key] = value;
          }

          return {
            ...prev,
            trucks: updatedTrucks,
            trailers: updatedTrailers,
          };
        });
        break;

      case "technologyInvestments":
        const [investmentType, investmentIndex, investmentKey] = name.match(/(\w+)\[(\d+)\]\.(\w+)/).slice(1);
        setTechnologyInvestments((prev) => {
          const updatedPreviousInvestments = [...prev.previousInvestments];
          const updatedTechnologiesConsidered = [...prev.technologiesConsidered];

          if (investmentType === "previousInvestments") {
            updatedPreviousInvestments[investmentIndex][investmentKey] = value;
          } else if (investmentType === "technologiesConsidered") {
            updatedTechnologiesConsidered[investmentIndex][investmentKey] = value;
          }

          return {
            ...prev,
            previousInvestments: updatedPreviousInvestments,
            technologiesConsidered: updatedTechnologiesConsidered,
          };
        });
        break;

      case "dataAnalysis":
        const [dataField, dataKey] = name.match(/(\w+)\.(\w+)/).slice(1);
        setDataAnalysis((prev) => ({
          ...prev,
          [dataField]: {
            ...prev[dataField],
            [dataKey]: value,
          },
        }));
        break;

      default:
        break;
    }
  };

  const retroForecastChanges = (section, index, name, value) => {
    setDataAnalysis((prevState) => {
      const updatedState = { ...prevState };

      if (section === "retrofitProjects") {
        updatedState.retrofitProjects = updatedState.retrofitProjects.map((project, i) => (i === index ? { ...project, [name]: value } : project));
      } else if (section === "forecastedReductions") {
        updatedState.forecastedReductions = updatedState.forecastedReductions.map((project, i) => (i === index ? { ...project, [name]: value } : project));
      }

      return updatedState;
    });
  };

  return (
    <div className={styles.reportContent}>
      {currentStep === 1 && (
        <div id="section1">
          <div className={styles.reportTitle}>Section 1 – General Fleet Information</div>

          <section>
            <div className={styles.partTitle}>A) Fleet Profile</div>
            <table className={styles.table}>
              <tbody>
                <InputField label="Fleet Name" name="fleetName" value={fleetProfile.fleetName} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. ABC Trucking" />
                <InputField label="Headquarters Address" name="headquartersAddress" value={fleetProfile.headquartersAddress} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. 123 Freight Way, Anytown, ON K1Z 1A1" />
                <InputField label="Operations Address (if different from Headquarters)" name="operationsAddress" value={fleetProfile.operationsAddress} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. 456 Logistic Road, Anytown, ON K1Z 1B1" />
                <InputField label="Lead Contact Name/Title" name="leadContact" value={fleetProfile.leadContact} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. John Doe, VP Maintenance" />
                <InputField label="Contact Email" name="contactEmail" value={fleetProfile.contactEmail} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. john@abctrucking.ca" />
                <InputField label="Contact Phone" name="contactPhone" value={fleetProfile.contactPhone} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. (613) 888-1234" />
                <InputField label="Age of Company (in years)" name="ageOfCompany" value={fleetProfile.ageOfCompany} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. 27 years" />
                <InputField label="Operation (TL, Mixed, Expedited)" name="operation" value={fleetProfile.operation} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. For hire/dry van" />
                <InputField label="Ownership (Private/Dedicated/For-hire)" name="ownership" value={fleetProfile.ownership} onChange={(name, value) => handleChange("fleetProfile", name, value)} placeholder="e.g. Privately held" />
              </tbody>
            </table>
          </section>

          <section>
            <div className={styles.partTitle}>B) Description of Fleet</div>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Criteria</th>
                  <th>Fleet 1</th>
                </tr>
              </thead>
              <tbody>
                {fleetDescription.trucks.map((truck, index) => (
                  <React.Fragment key={index}>
                    <InputField label="Gross Vehicle Weight (GVW) Class" name={`trucks[${index}].gvWeight`} value={truck.gvWeight} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 8" />
                    <InputField label="Vehicle Type" name={`trucks[${index}].vehicleType`} value={truck.vehicleType} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. Day cab tractor" />
                    <InputField label="Fuel Type" name={`trucks[${index}].fuelType`} value={truck.fuelType} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. Diesel" />
                    <InputField label="Duty Cycle" name={`trucks[${index}].dutyCycle`} value={truck.dutyCycle} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. Regional-haul" />
                    <InputField label="Number of Trucks/Tractors in that Fleet" name={`trucks[${index}].numTrucks`} value={truck.numTrucks} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 35" />
                    <InputField label="Average Age (Years)" name={`trucks[${index}].avgAge`} value={truck.avgAge} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 2.3" />
                    <InputField label="Percent of Units Equipped with Telematics" name={`trucks[${index}].telematics`} value={truck.telematics} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 100%" />
                  </React.Fragment>
                ))}
                <div className={styles.partTitle}>Trailers</div>
                {fleetDescription.trailers.map((trailer, index) => (
                  <React.Fragment key={index}>
                    <InputField label="Gross Vehicle Weight (GVW) Class" name={`trailers[${index}].gvWeight`} value={trailer.gvWeight} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 8" />
                    <InputField label="Trailer Type" name={`trailers[${index}].trailerType`} value={trailer.trailerType} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. Refrigerated" />
                    <InputField label="Number of Units" name={`trailers[${index}].numUnits`} value={trailer.numUnits} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 150" />
                    <InputField label="Average Age (Years)" name={`trailers[${index}].avgAge`} value={trailer.avgAge} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 8.7" />
                    <InputField label="Percent of Units Equipped with Telematics" name={`trailers[${index}].telematics`} value={trailer.telematics} onChange={(name, value) => handleChange("fleetDescription", name, value)} placeholder="e.g. 0%" />
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      )}
      {currentStep === 2 && (
        <div id="section2">
          <div className={styles.reportTitle}>Section 2 – Technology Investment Details</div>

          <section>
            <div className={styles.partTitle}>A) Previous Fuel Saving/GHG Reduction Investments</div>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Previous Investments Criteria (if applicable)</th>
                  <th>Investment 1</th>
                </tr>
              </thead>
              <tbody>
                {technologyInvestments.previousInvestments.map((investment, index) => (
                  <React.Fragment key={index}>
                    <InputField label="Technology" name={`previousInvestments[${index}].technology`} value={investment.technology} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. Trailer skirts" />
                    <InputField label="Number of Units on which the Technology is Installed" name={`previousInvestments[${index}].numUnits`} value={investment.numUnits} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. 5 trailers" />
                    <InputField label="Year of Investment" name={`previousInvestments[${index}].yearOfInvestment`} value={investment.yearOfInvestment} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. 2019" />
                    <InputField label="Estimated Fuel Savings (%)" name={`previousInvestments[${index}].fuelSavings`} value={investment.fuelSavings} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. 4%" />
                    <InputField label="Estimated Annual Savings ($)" name={`previousInvestments[${index}].annualSavings`} value={investment.annualSavings} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. 900 litres/$3,600" />
                    <InputField label="Investment ($)" name={`previousInvestments[${index}].investment`} value={investment.investment} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. $10,000" />
                    <InputField label="Notes (optional)" name={`previousInvestments[${index}].notes`} value={investment.notes} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. Retrofitted to existing trailers" />
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </section>

          <section>
            <div className={styles.partTitle}>B) Technologies Being Considered</div>
            <table className={styles.table}>
              <thead></thead>
              <tbody>
                {technologyInvestments.technologiesConsidered.map((tech, index) => (
                  <React.Fragment key={index}>
                    <InputField label="Type" name={`technologiesConsidered[${index}].type`} value={tech.type} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. Device type" />
                    <InputField label="Technology" name={`technologiesConsidered[${index}].technology`} value={tech.technology} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. Electric APU" />
                    <InputField label="Number of Devices" name={`technologiesConsidered[${index}].numDevices`} value={tech.numDevices} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. 10" />
                    <InputField label="Resources Consulted" name={`technologiesConsidered[${index}].resourcesConsulted`} value={tech.resourcesConsulted} onChange={(name, value) => handleChange("technologyInvestments", name, value)} placeholder="e.g. OEM dealer" />
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      )}
      {currentStep === 3 && (
        <div id="section3">
          <div className={styles.reportTitle}>Section 3 – Data Analysis</div>

          <section>
            <div className={styles.partTitle}>A) Retrofit Summary</div>
            <table className={styles.table}>
              <tbody>
                <InputField label="Vehicle Type" name="retrofitSummary.vehicleType" value={dataAnalysis.retrofitSummary.vehicleType} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Day cab tractors" />
                <InputField label="Number of Vehicles" name="retrofitSummary.numVehicles" value={dataAnalysis.retrofitSummary.numVehicles} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 10" />
                <InputField label="Technology" name="retrofitSummary.technology" value={dataAnalysis.retrofitSummary.technology} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Complete tractor aero package" />
                <InputField label="New or Continuing Investment" name="retrofitSummary.newOrContinuing" value={dataAnalysis.retrofitSummary.newOrContinuing} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. New" />
                <InputField label="Data Source(s)" name="retrofitSummary.dataSources" value={dataAnalysis.retrofitSummary.dataSources} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Telematics" />
                <InputField label="Total Project Investment" name="retrofitSummary.totalInvestment" value={dataAnalysis.retrofitSummary.totalInvestment} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. $38,244" />
                <InputField label="Funding Sources (with amount by source)" name="retrofitSummary.fundingSources" value={dataAnalysis.retrofitSummary.fundingSources} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Fleet: $19,122, NRCan: $19,122" />
                <InputField label="Reason GFP Funding is Needed" name="retrofitSummary.reasonFundingNeeded" value={dataAnalysis.retrofitSummary.reasonFundingNeeded} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Without NRCan funding, the fleet cannot fund the incremental cost of the complete tractor aero kit for all new tractors" />
              </tbody>{" "}
            </table>
          </section>

          <section>
            <div className={styles.partTitle}>B) Fuel and GHG Reducing Potential</div>
            <table className={styles.table}>
              <tbody>
                <InputField label="Forecasted Annual Kilometres Travelled" name="fuelAndGHGReduction.forecastAnnualKm" value={dataAnalysis.fuelAndGHGReduction.forecastAnnualKm} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 100,000 km" />
                <InputField label="Forecasted Annual Fuel Used" name="fuelAndGHGReduction.forecastAnnualFuel" value={dataAnalysis.fuelAndGHGReduction.forecastAnnualFuel} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 40,000 litres" />
                <InputField label="Forecasted KLP" name="fuelAndGHGReduction.forecastKLP" value={dataAnalysis.fuelAndGHGReduction.forecastKLP} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 6 km/litre" />
                <InputField label="Forecasted GHG Reductions" name="fuelAndGHGReduction.forecastGHGReductions" value={dataAnalysis.fuelAndGHGReduction.forecastGHGReductions} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 20 tonnes CO2" />
              </tbody>
            </table>
          </section>

          <section>
            <div className={styles.partTitle}>C) Implementation Plan</div>
            <table className={styles.table}>
              <tbody>
                <InputField label="Technology" name="implementationPlan.technology" value={dataAnalysis.implementationPlan.technology} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Complete tractor aero package" />
                <InputField label="Total Number of Units" name="implementationPlan.numUnits" value={dataAnalysis.implementationPlan.numUnits} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 10" />
                <InputField label="Delivery or Installation" name="implementationPlan.delivery" value={dataAnalysis.implementationPlan.delivery} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Completed units to be delivered by vendor" />
                <InputField label="Training (if applicable)" name="implementationPlan.training" value={dataAnalysis.implementationPlan.training} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. Maintenance personnel will be trained" />
                <InputField label="Number of Units Delivered/Installed in Calendar Year (For ongoing projects if applicable)" name="implementationPlan.numUnitsDeliveredYear" value={dataAnalysis.implementationPlan.numUnitsDeliveredYear} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 3" />
                <InputField label="Total Number of Units Delivered/Installed Prior to Start of This Project (For ongoing projects if applicable)" name="implementationPlan.numUnitsDeliveredPrior" value={dataAnalysis.implementationPlan.numUnitsDeliveredPrior} onChange={(name, value) => handleChange("dataAnalysis", name, value)} placeholder="e.g. 3" />
              </tbody>
            </table>
          </section>

          <section>
            <div className={styles.partTitle}>D1) For retrofit projects on trucks, tractors, or trailers</div>
            <table className={styles.projectTable}>
              <thead>
                <tr>
                  <th>Month</th>
                  {dataAnalysis.retrofitProjects.map((project, index) => (
                    <th key={index}>{project.month}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Number of trucks/ trailers</td>
                  {dataAnalysis.retrofitProjects.map((project, monthIndex) => (
                    <td key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="Number of trucks/ trailers" name={`noTrucksTrailers`} value={project.noTrucksTrailers} onChange={(e) => retroForecastChanges("retrofitProjects", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Distance travelled (km)</td>
                  {dataAnalysis.retrofitProjects.map((project, monthIndex) => (
                    <td key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="Distance travelled (km)" name={`distanceTravelled`} value={project.distanceTravelled} onChange={(e) => retroForecastChanges("retrofitProjects", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Fuel consumed (litres)</td>
                  {dataAnalysis.retrofitProjects.map((project, monthIndex) => (
                    <td className={styles.nrCanTableInputCell} key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="Fuel consumed (litres)" name={`fuelConsumed`} value={project.fuelConsumed} onChange={(e) => retroForecastChanges("retrofitProjects", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>KPL</td>
                  {dataAnalysis.retrofitProjects.map((project, monthIndex) => (
                    <td key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="KPL" name={`KPL`} value={project.KPL} onChange={(e) => retroForecastChanges("retrofitProjects", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Estimated fuel reduction (litres)</td>
                  {dataAnalysis.retrofitProjects.map((project, monthIndex) => (
                    <td key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="Estimated fuel reduction (litres)" name={`estimatedReduction`} value={project.estimatedReduction} onChange={(e) => retroForecastChanges("retrofitProjects", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </section>

          <section>
            <div className={styles.partTitle}>D2) Forecasted Fuel Saving Reductions</div>
            <table className={styles.projectTable}>
              <thead>
                <tr>
                  <th>Month</th>
                  {dataAnalysis.forecastedReductions.map((project, index) => (
                    <th key={index}>{project.month}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Number of units</td>
                  {dataAnalysis.forecastedReductions.map((project, monthIndex) => (
                    <td key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="Number of units" name={`noUnits`} value={project.noUnits} onChange={(e) => retroForecastChanges("forecastedReductions", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Distance travelled (km)</td>
                  {dataAnalysis.forecastedReductions.map((project, monthIndex) => (
                    <td key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="Distance travelled (km)" name={`totalDistance`} value={project.totalDistance} onChange={(e) => retroForecastChanges("forecastedReductions", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Total traditional fuel (litres)</td>
                  {dataAnalysis.forecastedReductions.map((project, monthIndex) => (
                    <td className={styles.nrCanTableInputCell} key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="Total traditional fuel (litres)" name={`totalFuel`} value={project.totalFuel} onChange={(e) => retroForecastChanges("forecastedReductions", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>L/100 km or MPG</td>
                  {dataAnalysis.forecastedReductions.map((project, monthIndex) => (
                    <td key={monthIndex}>
                      <input className={styles.nrCanFormInput} label="L/100 km or MPG" name={`mpg`} value={project.mpg} onChange={(e) => retroForecastChanges("forecastedReductions", monthIndex, e.target.name, e.target.value)} />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      )}
      {currentStep === 4 && (
        <div id="section4">
          <div className={styles.reportTitle}>Section 4 – Fuel Saving and GHG Reduction Activities</div>

          <section>
            <div className={styles.partTitle}>Driver Training and Monitoring</div>
            <p>Enhanced Assessments will cover large, complex fuel-reducing implementation projects for fleet(s) that require a higher level of details, commitments, and a strategic plan for implementation. Projects are expected to be tailored to increase the efficiency of fleets. Such projects include, but are not limited to:</p>
            <ul>
              <li>Engine Repowers;</li>
              <li>Purchase of new alternative fuel vehicles; and</li>
              <li>Logistical best practices (e.g., large-scale telematics implementation, creation of a driver incentive program).</li>
            </ul>
            <p>As a result, these assessments must include all the details outlined in Section 3 – Data analysis, as well as the following details to be considered eligible for grant funding:</p>
            <div className={styles.sectionTitle}>Engine Repowers:</div>
            <ul>
              <li>Safety and compliance considerations;</li>
              <li>Installation strategy;</li>
              <li>Refuelling infrastructure plan;</li>
              <li>Maintenance;</li>
              <li>Routing and operations (will routes be revised to fit new trucks?);</li>
              <li>Changes to personnel;</li>
              <li>Is a specific OEM being considered?</li>
            </ul>
            <div className={styles.sectionTitle}>Purchase of New Alternative Fuel Vehicles:</div>
            <ul>
              <li>Refuelling infrastructure plan;</li>
              <li>Timeline of delivery of new trucks;</li>
              <li>Routing and operations (will routes be revised to fit new trucks?);</li>
              <li>Driver Training;</li>
              <li>Changes to personnel;</li>
              <li>Is a specific OEM being considered?</li>
            </ul>
            <div className={styles.sectionTitle}>Logistical Best Practices:</div>
            <ul>
              <li>Detailed implementation strategy;</li>
              <li>Routing and operations (will routes be revised to fit new trucks?);</li>
              <li>Driver Training;</li>
              <li>Changes to personnel;</li>
              <li>Is a specific OEM being considered?</li>
            </ul>
          </section>
        </div>
      )}
      {currentStep === 5 && (
        <div>
          <div className={styles.reportTitle}>Overview</div>
          <p>Review all the information provided and ensure it is correct.</p>
          {/* Display summary of the filled data */}
        </div>
      )}{" "}
    </div>
  );
};

export default NRCanReport;
