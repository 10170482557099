import React, { useState, useEffect } from "react";
import Grid from "../../environment-page-components/Grid";
import styles from "./AssetsTable.module.css";
import config from "../../config";
import { Icon } from "@iconify/react";

const AssetsTable = ({ selectedRowData, selectedLanguage, refreshData, allAssets, loadingData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalGhgEmissions, setTotalGhgEmissions] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedAssetNumbers, setSelectedAssetNumbers] = useState([]);
  const [initialSelectedAssets, setInitialSelectedAssets] = useState([]);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const toggleContainer = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (selectedRowData.length > 0) {
      const initialAssets = Array.isArray(selectedRowData[0].asset_numbers) ? selectedRowData[0].asset_numbers : JSON.parse(selectedRowData[0].asset_numbers);
      setSelectedRow(selectedRowData[0]);
      setSelectedAssetNumbers(initialAssets);
      setInitialSelectedAssets([...initialAssets]);
    }
  }, [selectedRowData]);

  useEffect(() => {
    const filteredData = allAssets.filter((asset) => selectedAssetNumbers.includes(asset.assetNo));
    setTableData(filteredData);
  }, [selectedAssetNumbers, allAssets]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleAssetSelection = (assetNo) => {
    setSelectedAssetNumbers((prevSelected) => {
      const newSelected = prevSelected.includes(assetNo) ? prevSelected.filter((item) => item !== assetNo) : [...prevSelected, assetNo];
      return newSelected;
    });
  };

  useEffect(() => {
    if (JSON.stringify(selectedAssetNumbers) !== JSON.stringify(initialSelectedAssets)) {
      setIsSaveButtonEnabled(true);
    } else {
      setIsSaveButtonEnabled(false);
    }
  }, [selectedAssetNumbers, initialSelectedAssets]);

  useEffect(() => {
    const totalEmissions = tableData.reduce((total, asset) => {
      if (selectedAssetNumbers.includes(asset.assetNo)) {
        const emissions = parseFloat(asset.ghgEmissions) || 0;
        return total + emissions;
      }
      return total;
    }, 0);
    setTotalGhgEmissions(totalEmissions);
  }, [selectedAssetNumbers, tableData]);

  const saveTable = async () => {
    const selectedAssetsData = tableData.filter((row) => selectedAssetNumbers.includes(row.assetNo));

    const totalFuelConsumed = selectedAssetsData.reduce((total, asset) => {
      const fuel = parseFloat(asset.fuelConsumed) || 0;
      return total + fuel;
    }, 0);

    const totalDistanceTravelled = selectedAssetsData.reduce((total, asset) => {
      const distance = parseFloat(asset.distanceTravelled) || 0;
      return total + distance;
    }, 0);

    if (selectedAssetsData.length === 0) {
      console.error("No assets selected");
      return;
    }

    const requestData = {
      fleet: selectedRow.fleet,
      vehicle_type: selectedRow.vehicle_type,
      trailer_type: selectedRow.trailer_type,
      location: selectedRow.location,
      Year: selectedRow.Year,
      fuel_consumed: totalFuelConsumed,
      distance_travelled: totalDistanceTravelled,
      gwp_total: totalFuelConsumed * 2.62,
      distance_per_gwp: totalDistanceTravelled / (totalFuelConsumed * 2.62) || 0,
      asset_numbers: JSON.stringify(selectedAssetNumbers),
      user_id: selectedRow.user_id,
    };

    try {
      const response = await fetch(`${config.apiUrl}/update_data`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          table_name: "fleets",
          data: requestData,
          condition: `id = '${selectedRow.id}'`,
        }),
      });

      if (!response.ok) {
        console.error("Failed to update fleet metrics");
      }
      window.location.reload();
    } catch (error) {
      console.error("Error saving fleet metrics:", error);
    }
  };

  const assetsGridProps = {
    columnDefs: [
      { headerName: "Asset No", field: "assetNo", editable: false, minWidth: 100, checkboxSelection: true, headerCheckboxSelection: true },
      { headerName: "Fuel Economy (L/100km)", field: "fuelEconomy", editable: false, minWidth: 150 },
      {
        headerName: "Fuel Consumed (L)",
        field: "fuelConsumed",
        editable: false,
        minWidth: 150,
        valueFormatter: (params) => Math.round(params.value).toString(),
      },
      {
        headerName: "Distance Travelled (km)",
        field: "distanceTravelled",
        editable: false,
        minWidth: 160,
        valueFormatter: (params) => Math.round(params.value).toString(),
      },
      { headerName: "Idling %", field: "idlingPercent", editable: false, minWidth: 120 },
      { headerName: "GHG Emissions (kg CO2e)", field: "ghgEmissions", editable: false, minWidth: 200, cellStyle: { textAlign: "right" } },
    ],
    rowData: tableData,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "filters",
    },
    onGridReady: onGridReady,
  };

  return (
    <div className="process-table">
      <div className="container-header">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            cursor: "pointer",
            color: isHovered ? "grey" : "black",
          }}
        >
          Assets
        </h2>
      </div>
      {loadingData ? (
        <div className={styles.loadingSpinner}>Loading...</div>
      ) : isOpen ? (
        <>
          <div className={styles.gridContainerEnv}>
            <div className="grid-container">
              <Grid {...assetsGridProps} customMinHeight={264} customMaxheight={264} />
              <div className="gwp-total-env">
                <span className="gwp-total-env-text">Total:</span>
                <span className="gwp-total-env-number">
                  {totalGhgEmissions.toFixed(0)} kg Co<sub>2</sub>e
                </span>
              </div>
            </div>
            <div className={styles.assetSelectionContainer}>
              <h3 className={styles.assetSelectionTitle}>Asset Selection</h3>
              <div className={styles.availableAssets}>
                {allAssets.map((asset) => (
                  <div key={asset.assetNo} className={`${styles.assetItem} ${selectedAssetNumbers.includes(asset.assetNo) ? styles.assetItemSelected : ""}`} onClick={() => handleAssetSelection(asset.assetNo)}>
                    {asset.assetNo}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="button-container-env">
            <div className="button-container">
              <div className="button-container-left"></div>
              <div className="button-container-right">
                <button onClick={saveTable} title="Save current data in the table to database" disabled={!isSaveButtonEnabled}>
                  <Icon icon="mdi:floppy-disk" style={{ fontSize: "16px" }} />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AssetsTable;
