import React, { useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import styles from "./UtilizationComponents.module.css";

function PriorityTable({ priorityTable }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  return (
    <div className="process-env-container">
      <div className="process-table">
        <header className={styles["table-header"]}>
          <div className={styles.sectionTitle} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
            Priority Table
          </div>
        </header>
        <div className={styles["assert-table"]}>
          <div className={`${styles.content1} ${styles.content} ${isAccordionOpen ? styles.open : ""}`}>
            <div className={` ${styles["priority_table_container"]}`}>
              {priorityTable.length > 1 && (
                <table id="priority-table" className={`${styles["priority-table"]} `}>
                  <thead>
                    <tr>
                      {priorityTable[0].map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {priorityTable.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td
                            key={cellIndex}
                            style={{
                              color: cell === "High" ? "red" : cell === "Medium" ? "orange" : cell === "Medium-Low" ? "rgba(93, 173, 226, 1)" : cell === "Low" ? "rgba(88, 214, 141, 1)" : "",
                              fontWeight: cell === "High" ? "bold" : cell === "Medium" ? "bold" : cell === "Medium-Low" ? "bold" : cell === "Low" ? "bold" : "normal",
                            }}
                          >
                            {cell === "Yes" ? <FaExclamationCircle size={"20px"} style={{ color: "orange" }} /> : cell === "Medium-Low" ? "Medium" : cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriorityTable;
