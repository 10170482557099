const chartOptions = (xMean, yMean, xStd, yStd, xMax, yMax, xMin, yMin, xAxies, yAxies) => {
  let stepSize = 10;
  if (yAxies === "Engine run time (hr)") {
    stepSize = 600;
  }

  let initialYValues = {};

  return {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: 15,
          },
        },
        title: {
          display: true,
          text: xAxies,
          font: {
            size: 16,
            weight: "bold",
          },
        },
        grid: {
          display: false,
        },
        max: xMax,
        min: xMin,
      },
      y: {
        ticks: {
          font: {
            size: 15,
          },
          stepSize: stepSize,
          callback: function (value) {
            return Math.round(value);
          },
        },
        title: {
          display: true,
          text: yAxies,
          font: {
            size: 16,
            weight: "bold",
          },
        },
        grid: {
          display: false,
        },
        max: yMax,
        min: yMin,
      },
    },
    plugins: {
      title: {
        display: true,
        text: `${yAxies} vs ${xAxies}`,
        font: {
          size: 14,
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 13,
          },
        },
      },
      datalabels: {
        color: "black",
        font: {
          size: 12,
          weight: "bold",
        },
        align: "end",
        anchor: "end",
        formatter: (value, context) => {
          return context.dataset.data[context.dataIndex].label;
        },
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        intersect: true,
        callbacks: {
          title: function (tooltipItems) {
            return `Asset ${tooltipItems[0].label}`;
          },
          label: function (tooltipItem) {
            const { x, y } = tooltipItem.raw;
            const initialY = initialYValues[tooltipItem.dataIndex];

            let yAxisLabel = yAxies === "Idling time (%)" ? `${y.toFixed(1)} %` : `${y.toFixed(1)} L/100km`;

            if (initialY === undefined) {
              return [`${xAxies}: ${x.toFixed(0)} km`, `${yAxies}: ${yAxisLabel}`];
            }

            const potentialReduction = ((initialY - y) * x) / 100;
            return [`${xAxies}: ${x.toFixed(0)} km`, `${yAxies}: ${yAxisLabel}`, `Potential Reductions: ${potentialReduction.toFixed(1)} L`];
          },
        },
        backgroundColor: "rgba(160, 160, 160, 0.9)",
        color: "black",
        titleFont: {
          size: 18,
          weight: "bold",
        },
        bodyFont: {
          size: 15,
          weight: "bold",
        },
      },

      annotation: {
        annotations: {
          xMeanLine: {
            type: "line",
            scaleID: "x",
            value: xMean,
            borderColor: "grey",
            borderWidth: 1,
            label: {
              enabled: true,
              content: "xMean",
              font: {
                size: 14,
              },
            },
          },
          yMeanLine: {
            type: "line",
            scaleID: "y",
            value: yMean,
            borderColor: "grey",
            borderWidth: 1,
            label: {
              enabled: true,
              content: "yMean",
              position: "center",
              font: {
                size: 14,
              },
            },
          },
          xStdPosLine: {
            type: "line",
            scaleID: "x",
            value: +xMean + +xStd,
            borderColor: "black",
            borderDash: [5, 5],
            borderWidth: 1,
            label: {
              enabled: true,
              content: "xMean + xStd",
              font: {
                size: 13,
              },
            },
          },
          xStdNegLine: {
            type: "line",
            scaleID: "x",
            value: xMean - xStd,
            borderColor: "black",
            borderDash: [5, 5],
            borderWidth: 1,
            label: {
              enabled: true,
              content: "xMean - xStd",
              font: {
                size: 13,
              },
            },
          },
          yStdPosLine: {
            type: "line",
            scaleID: "y",
            value: +yMean + +yStd,
            borderColor: "black",
            borderDash: [5, 5],
            borderWidth: 1,
            label: {
              enabled: true,
              content: "yMean + yStd",
              position: "center",
              font: {
                size: 13,
              },
            },
          },
          yStdNegLine: {
            type: "line",
            scaleID: "y",
            value: yMean - yStd,
            borderColor: "black",
            borderDash: [5, 5],
            borderWidth: 1,
            label: {
              enabled: true,
              content: "yMean - yStd",
              position: "center",
              font: {
                size: 13,
              },
            },
          },
          box1: {
            type: "box",
            xMin: xMean,
            xMax: xMax,
            yMin: yMean,
            yMax: yMax,
            backgroundColor: "rgba(236, 112, 99, 0.4)",
          },
          box2: {
            type: "box",
            xMin: xMin,
            xMax: xMean,
            yMin: yMean,
            yMax: yMax,
            backgroundColor: "rgba(247, 220, 111, 0.4)",
          },
          box3: {
            type: "box",
            xMin: xMin,
            xMax: xMean,
            yMin: yMin,
            yMax: yMean,
            backgroundColor: "rgba(88, 214, 141, 0.3)",
          },
          box4: {
            type: "box",
            xMin: xMean,
            xMax: xMax,
            yMin: yMin,
            yMax: yMean,
            backgroundColor: "rgba(93, 173, 226, 0.3)",
          },
        },
      },
    },
    devicePixelRatio: 4,
  };
};

const shapeMapping = {
  "#1874CD": "rect",
  "#6A1B9A": "rect",
  "#FF69B4": "rect",
  "#EEC900": "rect",
  "#76EE00": "rect",
  "#FF4500": "rect",
};

const pointStyle = (context) => {
  const color = context.dataset.backgroundColor[context.dataIndex];
  const shape = shapeMapping[color];
  return shape ? shape : "rect";
};

const thirdChartOptions = (yMean, xMax, yMax, xMin, yMin, xAxies, yAxies) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${yAxies} vs ${xAxies}`,
        font: {
          size: 14,
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: "black",
        font: {
          size: 12,
          weight: "bold",
        },
        align: "end",
        anchor: "end",
        formatter: (value, context) => {
          return context.dataset.data[context.dataIndex].label;
        },
      },
      annotation: {
        annotations: {
          yMeanLine: {
            type: "line",
            scaleID: "y",
            value: yMean,
            borderColor: "#06402B",
            borderWidth: 1,
            label: {
              enabled: true,
              content: "yMean",
              position: "center",
              font: {
                size: 13,
              },
            },
          },
          box1: {
            type: "box",
            xMin: 0,
            xMax: xMax,
            yMin: 0,
            yMax: yMax,
            backgroundColor: "rgba(230, 230, 230, 0.05)",
          },
        },
      },
    },
    elements: {
      point: {
        radius: 7,
        pointStyle: pointStyle,
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            return Math.round(value);
          },
          font: {
            size: 15,
          },
        },
        title: {
          display: true,
          text: xAxies,
          font: {
            size: 16,
            weight: "bold",
          },
        },
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
        max: xMax,
        min: xMin,
      },
      y: {
        ticks: {
          font: {
            size: 15,
          },
        },
        title: {
          display: true,
          text: yAxies,
          font: {
            size: 16,
            weight: "bold",
          },
        },
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
        max: yMax,
        min: yMin,
      },
    },
    layout: {
      backgroundColor: "#007bff",
    },
    devicePixelRatio: 4,
  };
};

export { chartOptions, thirdChartOptions };
