// Footer.js
import React from 'react';
import '../style/components-style/Footer.css';
import { translations } from '../app_components/Translation';

function Footer({ selectedLanguage, setSelectedLanguage }) {
  //const currentYear = new Date().getFullYear();
  const selectedText = translations[selectedLanguage];

  return (
    <div className="footer" id="app-footer">
      <div className="language-toggle-container">
        <button
          className={`language-toggle ${selectedLanguage === 'FR' ? 'selected' : 'not-selected'}`}
          onClick={() => setSelectedLanguage('FR')}
        >
          FR
        </button>
        <div style={{ padding: '0 5px' }}></div>
        <button
          className={`language-toggle ${selectedLanguage === 'EN' ? 'selected' : 'not-selected'}`}
          onClick={() => setSelectedLanguage('EN')}
        >
          EN
        </button>
      </div>
      <div className="contact-info">
        {selectedText.contactInfo}
      </div>
      <div className="copyright-info">
        <p style={{ marginRight: '5px' }}>{selectedText.arivu} | </p>
        <p style={{ marginRight: '5px' }}>{selectedText.copyrightInfo}</p>
        <a href="https://vrtta.green/" target="_blank" rel="noopener noreferrer">
          {selectedText.vrtta}
        </a>
      </div>
    </div>
  );
}

export default Footer;
